import { forwardRef } from 'react';

import { Checkbox, type Props as CheckboxProps } from '../../atoms/Checkbox';
import { BaseFormField } from '../BaseFormField/BaseFormField';

export type Props = CheckboxProps & {
	// temporality, need to redesign terms and conditions
	label?: (string | JSX.Element)[] | string;
};

// Type hack due to mismatch with @mui/material and @types/react https://github.com/mui/material-ui/issues/41906
export const CheckboxFormField: React.ForwardRefExoticComponent<
	Omit<Props, 'ref'> & React.RefAttributes<HTMLInputElement>
> = forwardRef<HTMLInputElement, Props>(({ label, ...checkboxProps }, ref) => {
	const { value, disabled, required } = checkboxProps;

	return (
		<BaseFormField
			label={label}
			value={value}
			isDisabled={disabled}
			isRequired={required}
			control={<Checkbox {...checkboxProps} ref={ref} />}
		/>
	);
});

CheckboxFormField.displayName = 'CheckboxFormField';
