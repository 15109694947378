import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

import type { AlertVariant } from './Alert';

export const useCustomStyles = makeStyles<AlertVariant>()((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: theme.border.fat,
		padding: `${theme.spacing.xsmall} ${theme.spacing.small}`,
	},
	success: {
		backgroundColor: theme.alert.positive.background.color,
		color: theme.alert.positive.foreground.color,
	},
	warning: {
		backgroundColor: theme.alert.caution.background.color,
		color: theme.alert.caution.foreground.color,
	},
	info: {
		backgroundColor: theme.alert.info.background.color,
		color: theme.alert.info.foreground.color,
	},
	error: {
		backgroundColor: theme.alert.error.background.color,
		color: theme.alert.error.foreground.color,
	},
	textWrapper: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: theme.spacing.tiny,
	},
	title: {
		color: 'inherit',
	},
	text: {
		color: 'inherit',
	},
	button: {
		marginTop: theme.spacing.tiny,
	},
	icon: {
		flexShrink: 0,
		marginRight: theme.spacing.small,
	},
	alertTitleTextWrapper: {
		display: 'grid',
		gap: theme.spacing.tiny,
		marginRight: theme.spacing.small,
	},
	alertImageContainer: {
		display: 'flex',
		marginLeft: 'auto',
	},
}));
