import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { createVaknlSearchRequest } from '@vakantiesnl/services/src/search';
import { initVaknlSearchReqParams } from '@vakantiesnl/services/src/search/initSearchRequest';
import { mapCompactAccommodations } from '@vakantiesnl/services/src/search/vaknl-mapper';
import { useFilterStore } from '@vakantiesnl/services/src/stores/filtersStore';
import { useGlobalFiltersStore } from '@vakantiesnl/services/src/stores/globalFiltersStore';
import { errorHandler, parseErrorToReport } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { type Search } from '@vakantiesnl/types/src';

function getVaknlSearchQueryKey(body: Search.VaknlSearchBody): string[] {
	return ['vaknl-search', JSON.stringify(body)];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGetVaknlSearchByArgs(body: Search.VaknlSearchBody, isEnabled = true) {
	const query = useQuery({
		queryKey: getVaknlSearchQueryKey(body),
		queryFn: () => fetchVaknlSearch(body),
		placeholderData: keepPreviousData,
		enabled: isEnabled,
	});

	return {
		accommodationsData: query.data,
		isLoadingAccommodations: query.isLoading,
	};
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGetVaknlSearch() {
	const filters = useFilterStore((s) => s.filters);
	const globalFilters = useGlobalFiltersStore((s) => s.filters);

	const body = initVaknlSearchReqParams(filters, globalFilters);

	const query = useQuery({
		queryKey: getVaknlSearchQueryKey(body),
		queryFn: () => fetchVaknlSearch(body),
	});

	return {
		searchData: query.data,
		isSearchLoading: query.isFetching,
		hasSearchError: query.error,
	};
}

async function fetchVaknlSearch(body: Search.VaknlSearchBody): Promise<Search.VaknlSearchResponse> {
	const query = createVaknlSearchRequest(body);

	try {
		const response = await fetchVakNL({ input: query });
		const result: Search.RawVaknlSearchResponse = await response.json();

		return {
			accommodations: mapCompactAccommodations(result.accommodations),
			totalResults: result.results_total ?? 0,
			resultsPerPage: result.results_per_page ?? 0,
			party: result.party,
		};
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, 'Error fetching vaknl search results'));

		throw new Error('Cannot fetch vaknl search results');
	}
}
