import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	container: {
		position: 'fixed',
		bottom: theme.spacing.large,
		width: '100%',
		zIndex: theme.zIndex.floating,
	},
	content: {
		padding: `${theme.spacing.small} ${theme.spacing.large}`,
		backgroundColor: theme.palette.neutral[0],
		borderRadius: theme.borderRadius.rounder,
		/** Prevent the height overflow on mobile */
		maxHeight: 'calc(100vh - 110px)',
		overflowY: 'scroll',
		boxShadow: theme.elevation.top.lifted,

		[getFromBreakpoint('md')]: {
			padding: `${theme.spacing.medium} ${theme.spacing.xlarge}`,
		},
	},
	bottomContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: theme.spacing.medium,
		flexDirection: 'column',
		gap: theme.spacing.medium,

		[getFromBreakpoint('md')]: {
			gap: theme.spacing.xlarge,
			flexDirection: 'row',
		},

		'& label>span': {
			fontWeight: theme.weight.semibold,
		},
	},
	heading: {
		color: theme.primary.color.default,
		marginBottom: theme.spacing.medium,
	},
	checkboxDescription: {
		marginTop: theme.spacing.tiny,

		[getFromBreakpoint('md')]: {
			marginLeft: theme.spacing.large,
		},
	},
	buttonContainer: {
		width: '100%',

		[getFromBreakpoint('md')]: {
			width: 'auto',
		},
	},
	ctaButton: {
		width: '100%',
		justifyContent: 'center',
		whiteSpace: 'nowrap',
	},
	moreInfo: {
		display: 'flex',
		flexDirection: 'column',

		['&[aria-hidden="true"]']: {
			display: 'none',
		},
	},
	cookieSubtitle: {
		display: 'flex',
		gap: theme.spacing.small,
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	moreInfoContainer: {
		padding: theme.spacing.small,
		borderRadius: theme.borderRadius.rounded,
		border: `${theme.border.thin} solid ${theme.palette.neutral[20]}`,
	},
	toggle: {
		flexShrink: 0,
	},
}));
