import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles()((theme) => {
	const SMALL_INPUT_PADDING = `${theme.spacing.xxsmall} ${theme.spacing.xsmall}`;
	const MEDIUM_INPUT_PADDING = `${theme.spacing.xsmall} ${theme.spacing.small}`;

	return {
		container: {
			position: 'relative',
		},
		root: {
			alignItems: 'center',
			backgroundColor: theme.input.background.color.default,
			border: theme.input.border.default,
			borderRadius: theme.input.borderRadius,
			display: 'grid',
			gridAutoFlow: 'column',
			gridAutoColumns: '1fr',
			gridAutoRows: '1fr',
			gridTemplateColumns: 'min-content min-content 1fr min-content',
			gridTemplateRows: '1fr',
			gap: '0',
			gridTemplateAreas: '"startAdornment prefixAdornment main endAdornment"',
			justifyContent: 'start',
			justifyItems: 'start',
			padding: SMALL_INPUT_PADDING,

			[getFromBreakpoint('md')]: {
				padding: MEDIUM_INPUT_PADDING,
			},

			'&.Mui-disabled': {
				backgroundColor: theme.input.background.color.disabled,
				border: theme.input.border.disabled,
				color: theme.input.foreground.empty.color,
				pointerEvents: 'none',

				'.MuiInputAdornment-root': {
					color: theme.input.foreground.empty.color,
				},
			},

			'&.Mui-error': {
				border: theme.input.border.error,
			},

			'&[aria-expanded="true"], &:hover:not(.Mui-disabled)': {
				border: theme.input.border.hover,
			},

			'&[aria-expanded="true"], &:focus-within': {
				border: theme.input.border.focus,
				boxShadow: theme.input.focus,
			},
		},
		smallRoot: {
			padding: SMALL_INPUT_PADDING,
		},
		mediumRoot: {
			padding: MEDIUM_INPUT_PADDING,
		},
		input: {
			border: 0,
			color: theme.input.foreground.filled.color,
			gridArea: 'main',
			justifySelf: 'stretch',
			padding: 0,
			width: '100%',
			font: theme.typography.bodyMd,

			'&:focus-visible': {
				outline: '0',
			},

			'::placeholder': {
				color: theme.input.foreground.empty.color,
			},
		},
		smallInput: {
			font: theme.typography.bodySm,
		},
		mediumInput: {
			font: theme.typography.bodyMd,
		},
	};
});
