import { type ParsedUrlQuery } from 'querystring';

import { type SortingOrder } from '@vakantiesnl/types/src/search';

import { DEFAULT_SORT_ORDER_OPTION, SORT_ORDER_OPTIONS, STAR_OPTIONS } from './config';
import { ALL_TRANSPORT_TYPES, type FilterState, type TransportType } from './interfaces';
import {
	accoTypes as accoTypesManifest,
	themes as themesManifest,
	type CampaignConfig,
	chains,
	tourOperators,
	attributes as attributesManifest,
} from '../../route-params';
import { isNotEmpty } from '../../util/arrayUtils';
import { getValidDateRangeFromParams } from '../../util/dateRangeUtils';
import {
	getDateWithoutTime,
	getDateYMD,
	getMinDepartureDate,
	isBetweenMinMaxDepartureDate,
} from '../../util/dateUtils';
import { getNumberIfValid, parseQueryDateParam, parseQueryParam } from '../../util/queryHelpers';

function isTransportType(value: string): value is TransportType {
	return ALL_TRANSPORT_TYPES.includes(value as TransportType);
}

export function getSorting(query: ParsedUrlQuery): FilterState['sort'] | undefined {
	const isValidSort = SORT_ORDER_OPTIONS.includes(query.sort as SortingOrder);

	if (isValidSort) {
		return query.sort as FilterState['sort'];
	}

	return DEFAULT_SORT_ORDER_OPTION;
}

export function getDepartureDate(query: ParsedUrlQuery): FilterState['departureDate'] | undefined {
	return parseQueryDateParam(query.departure_date);
}

export function getMealplans(query: ParsedUrlQuery): FilterState['mealplans'] | undefined {
	return parseQueryParam(query.mealplans);
}

export function getTransport(query: ParsedUrlQuery): FilterState['transport'] | undefined {
	if (typeof query.transport === 'string' && isTransportType(query.transport)) {
		return query.transport;
	}

	return undefined;
}

export function getFlexibleDepartureDate(query: ParsedUrlQuery): FilterState['flexibleDepartureDate'] | undefined {
	return query.flexible_departure_date === 'true'
		? true
		: typeof query.flexible_departure_date === 'string'
			? parseInt(query.flexible_departure_date)
			: undefined;
}

export function getAirport(query: ParsedUrlQuery): FilterState['departureAirport'] | undefined {
	return parseQueryParam(query.airports);
}

export function getThemes(query: ParsedUrlQuery): FilterState['themes'] | undefined {
	return parseQueryParam(query.themes, themesManifest);
}

export function getAccoTypes(query: ParsedUrlQuery): FilterState['accoType'] | undefined {
	return parseQueryParam(query.accoType, accoTypesManifest);
}

export function getPage(query: ParsedUrlQuery): FilterState['page'] | undefined {
	const page = getNumberIfValid(query.page);
	const parsedPage = page ? Number(page) : undefined;

	if (parsedPage && parsedPage > 0) {
		return parsedPage;
	}

	return undefined;
}

export function getMinPrice(query: ParsedUrlQuery): FilterState['priceMin'] | undefined {
	return getNumberIfValid(query.min_price);
}

export function getMaxPrice(query: ParsedUrlQuery): FilterState['priceMax'] | undefined {
	return getNumberIfValid(query.max_price);
}

export function getCampaignDepartureDate(campaign: CampaignConfig): FilterState['departureDate'] | undefined {
	if (!campaign.departure_date && !campaign.post_offset) {
		return undefined;
	}

	// Backwards compatibility for the old way of providing date ranges
	if (campaign.departure_date?.includes('+')) {
		return parseQueryDateParam(campaign.departure_date);
	}

	const date = campaign.departure_date ? getDateWithoutTime(campaign.departure_date) : getMinDepartureDate();
	const offset = campaign.post_offset;

	if (!offset) {
		return isBetweenMinMaxDepartureDate(date) ? [getDateYMD(date)] : undefined;
	}

	const maxDate = date.add(offset, 'days');

	return getValidDateRangeFromParams(date, maxDate);
}

export function getCampaignAccoTypes(campaign: CampaignConfig): FilterState['accoType'] | undefined {
	return parseQueryParam(campaign.accoType, accoTypesManifest);
}

export function getCampaignAttributes(campaign: CampaignConfig): FilterState['attributes'] | undefined {
	if (!campaign.attributes?.length) {
		return undefined;
	}

	const validAttributes = Object.keys(attributesManifest);
	return campaign.attributes.filter((a) => validAttributes.includes(a));
}

export function getCampaignMinPrice(campaign: CampaignConfig): FilterState['priceMin'] | undefined {
	return getNumberIfValid(campaign.price_min);
}

export function getCampaignMaxPrice(campaign: CampaignConfig): FilterState['priceMax'] | undefined {
	return getNumberIfValid(campaign.price_max);
}

export function getAwards(query: ParsedUrlQuery): FilterState['awards'] | undefined {
	return parseQueryParam(query.awards);
}

export function getDistances(query: ParsedUrlQuery): FilterState['distances'] | undefined {
	return parseQueryParam(query.distances);
}

export function getFacilities(query: ParsedUrlQuery): FilterState['facilities'] | undefined {
	return parseQueryParam(query.facilities);
}

export function getRating(query: ParsedUrlQuery): FilterState['rating'] | undefined {
	if (typeof query.rating === 'string') {
		return query.rating;
	}

	return undefined;
}

export function getStars(query: ParsedUrlQuery): FilterState['stars'] | undefined {
	const value = parseQueryParam(query.stars);

	if (value?.some((v) => !STAR_OPTIONS.includes(v))) {
		return undefined;
	}

	return value;
}

export function getDeals(query: ParsedUrlQuery): FilterState['isDealsRoute'] | undefined {
	if (typeof query.deals === 'string') {
		return true;
	}

	return undefined;
}

export function getChainsParamsFromQuery(query: ParsedUrlQuery): FilterState['chains'] | undefined {
	if (typeof query.chain === 'string') {
		return getSelectedChainsBySlugs([query.chain]);
	}
	if (query.chains) {
		const splittedChainsEntityIdQueryParam = Array.isArray(query.chains)
			? query.chains
			: query.chains?.split(/[\+\/]/);
		return getSelectedChainsByEntityId(splittedChainsEntityIdQueryParam);
	}
}

export function getSelectedChainsBySlugs(chainsSlugList: string[]): FilterState['chains'] {
	return chainsSlugList?.map((slug) => chains.find((item) => item.slug === slug)).filter(isNotEmpty);
}

export function getSelectedChainsByEntityId(chainsEntityIdList: string[]): FilterState['chains'] {
	return chainsEntityIdList?.map((id) => chains.find((item) => String(item.entityId) === id)).filter(isNotEmpty);
}

export function getTourOperatorsParamsFromQuery(query: ParsedUrlQuery): FilterState['tourOperators'] | undefined {
	if (typeof query.tourOperator === 'string') {
		return getSelectedTourOperatorsBySlugs([query.tourOperator]);
	}
	if (query.tourOperators) {
		const splittedTourOperatorIdQueryParam = Array.isArray(query.tourOperators)
			? query.tourOperators
			: query.tourOperators?.split(/[\+\/]/);
		return getSelectedTourOperatorsByTourOperatorId(splittedTourOperatorIdQueryParam);
	}
}

export function getSelectedTourOperatorsBySlugs(tourOperatorsSlugsList: string[]): FilterState['tourOperators'] {
	return tourOperatorsSlugsList?.map((slug) => tourOperators.find((item) => item.slug === slug)).filter(isNotEmpty);
}

export function getSelectedTourOperatorsByTourOperatorId(tourOperatorsIdList: string[]): FilterState['tourOperators'] {
	return tourOperatorsIdList
		?.map((id) => tourOperators.find((item) => String(item.tour_operator) === id))
		.filter(isNotEmpty);
}
