import { format } from 'url';

import { type QueryKey } from '@tanstack/react-query';
import { errorHandler } from '@vakantiesnl/services/src/util/errorHandling';
import { type SearchTemplate } from '@vakantiesnl/types/src';

import { getCachedSearchTemplateFromContentful } from './getCachedPageFromContentful';
import { getCampaignMatch } from '../hooks/useSearchUrlBuilder';
import {
	themes as themesManifest,
	accoTypes as accoTypesManifest,
	countries as countriesManifest,
} from '../route-params';
import { type FilterState } from '../stores/filtersStore';
import { isNotEmpty } from '../util/arrayUtils';
import { getCanonicalUrl } from '../util/getCanonicalUrl';
import { replaceUndefinedByNull } from '../util/replaceUndefinedByNull';

export type FallbackArgs = {
	contentfulSlug?: string;
	locale?: string;
	fallback: string;
};

export type BrandedSearchTemplateArgs = {
	contentfulSlug?: string;
	page?: number;
	ownTransport?: boolean;
	locale?: string;
	fallbackOpts?: {
		shouldFallback: () => string | false;
		getFallbackTemplate: (args: FallbackArgs) => Promise<SearchTemplate | undefined>;
	};
};

export function getBrandedSearchTemplateQueryKey(args: BrandedSearchTemplateArgs): QueryKey {
	return ['branded-search-template', args.contentfulSlug || ''];
}

export async function fetchBrandedSearchTemplate(args: BrandedSearchTemplateArgs): Promise<SearchTemplate | undefined> {
	const { contentfulSlug, locale, fallbackOpts } = args;

	try {
		if (contentfulSlug) {
			const template = await getCachedSearchTemplateFromContentful(contentfulSlug, locale);

			if (!template) {
				throw new Error('Template not found');
			}

			return template;
		} else {
			throw new Error('Page not found');
		}
	} catch (err) {
		if (err instanceof Error && !err.message.includes('Page not found')) {
			errorHandler.report(`Error fetching branded search page data: ${err}`);

			throw err;
		}

		try {
			if (fallbackOpts) {
				const fallback = fallbackOpts.shouldFallback();
				const fallbackTemplate =
					fallback && (await fallbackOpts.getFallbackTemplate({ contentfulSlug, locale, fallback }));

				if (fallbackTemplate) {
					return fallbackTemplate;
				}
			}

			const template = await getCachedSearchTemplateFromContentful('/zoeken', locale);

			if (!template) {
				throw new Error(`Fallback template not found`);
			}

			return template;
		} catch (err) {
			if (err instanceof Error) {
				errorHandler.report(`Error fetching fallback search data: ${err}`);
			}
		}
	}
}

/**
 * Post-format because a page change should not effect the fetched template, so it
 * should not be part of the react query cache
 */
export function postFormatBrandedTemplate(
	page?: number,
	ownTransport?: boolean,
	contentfulSlug?: string,
	template?: SearchTemplate,
): SearchTemplate | null {
	if (!template) return null;

	const queryParams = {
		...(page && page > 1 ? { page } : {}),
		...(ownTransport ? { transport: 'OWN' } : {}),
	};

	const pathname = contentfulSlug ? getCanonicalUrl(template.seo.canonical, contentfulSlug) : template.seo.canonical;

	const canonical = !!Object.keys(queryParams).length
		? format({
				pathname,
				query: queryParams,
			})
		: pathname;

	// Don't update existing template, but create new object to prevent infinite loop because of a changed object
	const updatedTemplate: SearchTemplate = {
		...template,
		seo: {
			...template.seo,
			canonical,
		},
	};

	return replaceUndefinedByNull(updatedTemplate);
}

/**
 * Slug options depending on whether the page is a campaign:
 * - country?/region?/city?/accotype?/themes?/campaign?
 */
export function buildContentfulSlug(filters: FilterState): string | undefined {
	const { countries, regions, cities, themes, mealplans, campaign, accoType, isDealsRoute, chains, tourOperators } =
		filters;

	const countryValue = countries.length === 1 ? countriesManifest.find((c) => c === countries[0].slug) : undefined;

	if (isDealsRoute) {
		return buildUrl(countryValue, 'deals');
	}

	const accoTypeValue = accoType.length === 1 ? accoTypesManifest[accoType[0]] : undefined;

	const regionValue = countryValue && regions.length === 1 ? regions[0].slug : undefined;
	const cityValue = regionValue && cities.length === 1 ? cities[0].slug : undefined;

	const themeValue = themes.length === 1 ? themesManifest[themes[0]] : undefined;
	const chainValue = chains.length === 1 ? chains[0].slug : undefined;
	const tourOperatorValue = tourOperators.length === 1 ? tourOperators[0].slug : undefined;

	const mealplan = mealplans.length === 1 ? mealplanCodeToSlug[mealplans[0]] : undefined;

	const campaignMatcher = getCampaignMatch(filters);

	if (!campaignMatcher.hasValidCampaignMatch) {
		return buildUrl(
			countryValue,
			regionValue,
			cityValue,
			accoTypeValue,
			mealplan || themeValue,
			chainValue,
			tourOperatorValue,
		);
	}

	// Don't add filters to the slug when the campaign already includes them
	return buildUrl(
		campaignMatcher.countries ? undefined : countryValue,
		campaignMatcher.regions ? undefined : regionValue,
		campaignMatcher.cities ? undefined : cityValue,
		campaignMatcher.accoType ? undefined : accoTypeValue,
		campaignMatcher.mealplans ? themeValue : mealplan || themeValue,
		campaign,
		chainValue,
		tourOperatorValue,
	);
}

const mealplanCodeToSlug: Record<string, string> = {
	AI: 'all-inclusive',
};

function buildUrl(...paths: (string | undefined)[]): string | undefined {
	const nonEmptyPaths = paths.filter(isNotEmpty);

	if (!nonEmptyPaths.length) {
		return undefined;
	}

	return `/${nonEmptyPaths.join('/')}`;
}
