import { useEffect } from 'react';

import { type GetServerSideProps } from 'next';

import { useQueryClient } from '@tanstack/react-query';
import { UserLoginBlock } from '@vakantiesnl/components/src/organisms/LoginBlock';
import { withGlobalStateGSSP } from '@vakantiesnl/components/src/utils/globalState/withGlobalState';
import { getCachedContentTemplateFromContentful } from '@vakantiesnl/services/src/contentfulTemplates';
import { LOGGED_IN_QUERY_KEY } from '@vakantiesnl/services/src/hooks/queries';
import { getWhoAmI } from '@vakantiesnl/services/src/user';
import { SSO_TOKEN_COOKIE } from '@vakantiesnl/services/src/util/authToken';
import { type ContentTemplate } from '@vakantiesnl/types/src';
import cookies from 'next-cookies';

import { type GlobalPageProps, type VaknlNextPage } from './_app';
import DefaultLayout from '../layout';

type Props = GlobalPageProps & {
	content: ContentTemplate;
};

const LoginPage: VaknlNextPage<Props> = ({ content }) => {
	const queryClient = useQueryClient();

	useEffect(() => {
		// When the user is able to visit the login page, we can assume they did not pass authentication,
		// so reset all user queries
		queryClient.resetQueries({ queryKey: [LOGGED_IN_QUERY_KEY] });
	}, [queryClient]);

	return <UserLoginBlock content={content} variant="page" />;
};

export const getServerSideProps = withGlobalStateGSSP(
	(globalState): GetServerSideProps<Props> =>
		async ({ req }) => {
			try {
				// Check if user is already logged in, if so then redirect to the account page
				const token = cookies({ req })[SSO_TOKEN_COOKIE];
				const whoAmIRes = await getWhoAmI(token, false);

				if (whoAmIRes.status === 'success') {
					return {
						redirect: {
							statusCode: 302,
							destination: `/${process.env.NEXT_PUBLIC_USER_BASE_PATH}`,
						},
					};
				}

				const content = await getCachedContentTemplateFromContentful('/login');

				return {
					props: {
						globalState,
						seo: content.seo,
						content,
					},
				};
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Error in getServerSideProps on login page: ', err);

				return {
					notFound: true,
				};
			}
		},
);

LoginPage.Layout = DefaultLayout;

export default LoginPage;
