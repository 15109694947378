import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles<void, 'moreText' | 'lessText'>()((theme, _params, classes) => ({
	container: {
		alignItems: 'center',
		display: 'flex',
		fontWeight: theme.weight.semibold,
		fontSize: 'inherit',
		gap: theme.spacing.xxsmall,
		height: 'auto',
		justifyContent: 'start',

		'&:hover': {
			background: 'none',
			textDecoration: 'none',
		},
		[`&[aria-expanded="true"] .${classes.moreText}`]: {
			display: 'none',
		},
		[`&[aria-expanded="false"] .${classes.lessText}`]: {
			display: 'none',
		},
	},
	moreText: {},
	lessText: {},
}));
