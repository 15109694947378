import { type Search } from '@vakantiesnl/types';

type BodyType = {
	brand?: string;
	departure_date?: string;
	filters?: {
		departure_airports?: string[];
		arrival_airports?: string[];
		durations_flight?: number[];
		durations_accommodation?: number[];
		mealplans?: string[];
		tour_operators?: string[];
		outbound_flight_code?: string;
		inbound_flight_code?: string;
		outbound_flight_id?: string;
		inbound_flight_id?: string;
		provider?: string;
		rooms_codes?: string[][];
		rooms_booking_codes?: string[];
		rooms_keys?: string[];
		airlines?: string[];
		flight_provider?: string;
	};
	party: Search.PartyComposition;
	rooms?: Search.PartyComposition[];
	variant?: 'date' | 'roomBookingCode' | 'boardCode';
	pre_offset?: number;
	post_offset?: number;
	page_size: number;
	min_price?: number;
	max_price?: number;
};

export class SearchBody {
	private readonly body: BodyType;

	constructor(party: Search.PartyComposition[]) {
		this.body = {
			brand: process.env.NEXT_PUBLIC_BRAND || '',
			party: party[0],
			rooms: party,
			page_size: 100,
		};
	}

	static createDepartureDateRequest(party: Search.PartyComposition[]): SearchBody {
		const self = new SearchBody(party);
		self.body.variant = 'date';
		self.body.pre_offset = 15;
		self.body.post_offset = 16;

		return self;
	}

	static createFlightAlternativesRequest(party: Search.PartyComposition[]): SearchBody {
		const self = new SearchBody(party);
		self.body.page_size = 500;

		return self;
	}

	static createRoomTypeRequest(party: Search.PartyComposition[]): SearchBody {
		const self = new SearchBody(party);
		self.body.variant = 'roomBookingCode';

		return self;
	}

	static createMealplanRequest(party: Search.PartyComposition[]): SearchBody {
		const self = new SearchBody(party);
		self.body.variant = 'boardCode';

		return self;
	}

	appendDepartureDate(departureDate?: string): this {
		if (departureDate) {
			this.body.departure_date = departureDate;
		}

		return this;
	}

	appendDurationsFlight(durations?: number[]): this {
		if (durations) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			if (durations.length) {
				this.body.filters.durations_flight = [...durations];
			}
		}

		return this;
	}

	appendDurationFlight(duration?: number): this {
		if (duration) {
			this.appendDurationsFlight([duration]);
		}

		return this;
	}

	appendDurationsAccommodation(durations?: number[]): this {
		if (durations) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			if (durations.length) {
				this.body.filters.durations_accommodation = [...durations];
			}
		}

		return this;
	}

	appendDurationAccommodation(duration?: number): this {
		if (duration) {
			this.appendDurationsAccommodation([duration]);
		}

		return this;
	}

	appendDepartureAirport(departureAirport?: string | string[]): this {
		if (departureAirport?.length) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			if (!this.body.filters.departure_airports) {
				this.body.filters.departure_airports = [];
			}

			if (Array.isArray(departureAirport))
				this.body.filters.departure_airports = [...this.body.filters.departure_airports, ...departureAirport];

			if (typeof departureAirport === 'string') this.body.filters.departure_airports.push(departureAirport);
		}

		return this;
	}

	appendArrivalAirport(arrivalAirport?: string): this {
		if (arrivalAirport) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			if (!this.body.filters.arrival_airports) {
				this.body.filters.arrival_airports = [];
			}
			this.body.filters.arrival_airports.push(arrivalAirport);
		}

		return this;
	}

	appendMealplan(mealplan?: string): this {
		if (mealplan) {
			if (!this.body.filters) {
				this.body.filters = {};
			}

			if (!this.body.filters.mealplans) {
				this.body.filters.mealplans = [];
			}
			this.body.filters.mealplans.push(mealplan);
		}

		return this;
	}

	appendMinPrice(minPrice?: string): this {
		if (minPrice) {
			const minPriceNum = parseInt(minPrice);
			if (!Number.isNaN(minPriceNum)) {
				// multiply by 100 since min price is stored without decimals on Zoover
				this.body.min_price = process.env.NEXT_PUBLIC_BRAND === 'ZVRNL' ? minPriceNum * 100 : minPriceNum;
			}
		}

		return this;
	}

	appendMaxPrice(maxPrice?: string): this {
		if (maxPrice) {
			const maxPriceNum = parseInt(maxPrice);
			if (!Number.isNaN(maxPriceNum)) {
				// multiply by 100 since max price is stored without decimals on Zoover
				this.body.max_price = process.env.NEXT_PUBLIC_BRAND === 'ZVRNL' ? maxPriceNum * 100 : maxPriceNum;
			}
		}

		return this;
	}

	appendTourOperator(tourOperator?: string): this {
		if (tourOperator) {
			this.appendTourOperators([tourOperator]);
		}

		return this;
	}

	appendTourOperators(tourOperators?: string[]): this {
		if (tourOperators) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			this.body.filters.tour_operators = tourOperators;
		}

		return this;
	}

	appendInboundFlightCode(code?: string): this {
		if (code) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			this.body.filters.inbound_flight_code = code;
		}

		return this;
	}

	appendOutboundFlightCode(code?: string): this {
		if (code) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			this.body.filters.outbound_flight_code = code;
		}

		return this;
	}

	appendInboundFlightId(id?: string): this {
		if (id) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			this.body.filters.inbound_flight_id = id;
		}

		return this;
	}

	appendOutboundFlightId(id?: string): this {
		if (id) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			this.body.filters.outbound_flight_id = id;
		}

		return this;
	}

	appendProvider(provider?: string): this {
		if (provider) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			this.body.filters.provider = provider;
		}

		return this;
	}

	appendRoomsCodes(roomsCodes?: string[][]): this {
		if (roomsCodes && roomsCodes.length > 0) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			this.body.filters.rooms_codes = roomsCodes;
		}

		return this;
	}

	appendRoomsBookingCodes(opCodes?: string[]): this {
		if (opCodes) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			/** We should add the asterisk for PW boardCode variant request*/
			this.body.filters.rooms_booking_codes = opCodes.map((opCode) => `${opCode}*`);
		}

		return this;
	}

	appendRoomsKeys(roomsKeys?: string[]): this {
		if (roomsKeys) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			this.body.filters.rooms_keys = roomsKeys;
		}

		return this;
	}

	appendDepartureAirports(departureAirports?: string[]): this {
		departureAirports?.forEach(this.appendDepartureAirport.bind(this));

		return this;
	}

	appendArrivalAirports(arrivalAirports?: string[]): this {
		arrivalAirports?.forEach(this.appendArrivalAirport.bind(this));

		return this;
	}

	appendMealplans(mealplans?: string[]): this {
		mealplans?.forEach((mealplan: string) => {
			this.appendMealplan(mealplan);
		});

		return this;
	}

	appendFlightProvider(flightProvider?: string): this {
		if (flightProvider) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			this.body.filters.flight_provider = flightProvider;
		}

		return this;
	}

	appendAirlines(airlines?: string[]): this {
		if (airlines) {
			if (!this.body.filters) {
				this.body.filters = {};
			}
			this.body.filters.airlines = airlines;
		}

		return this;
	}

	toString(): string {
		return JSON.stringify(this.body);
	}
}
