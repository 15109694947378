/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery, type QueryClient, keepPreviousData } from '@tanstack/react-query';
import type { GeoState } from '@vakantiesnl/services/src/hooks/queries';
import {
	mapCompositeAccommodations,
	mapCompositeGeoAggregations,
} from '@vakantiesnl/services/src/search/zoover-mapper';
import { type FilterState, useFilterStore } from '@vakantiesnl/services/src/stores/filtersStore';
import { type GlobalFiltersState } from '@vakantiesnl/services/src/stores/globalFiltersStore/interfaces';
import { errorHandler, parseErrorToReport } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { type Elastic, type Search } from '@vakantiesnl/types/src';

import { initZooverCompositeSearchReqParams } from './initSearchReqParams';
import { createZooverCompositeSearchRequest } from '../../search';
import { useGetCompositeSearchParams } from '../useGetCompositeSearchParams';

export function getZooverCompositeSearchQueryKey(args: Search.ZooverCompositeSearchBody): string[] {
	return ['composite-search', JSON.stringify(args)];
}

export function useGetZooverCompositeSearch() {
	const isDealsRoute = useFilterStore((s) => s.filters.isDealsRoute);
	const args = useGetCompositeSearchParams();

	const query = useQuery({
		queryKey: getZooverCompositeSearchQueryKey(args),
		queryFn: () => fetchZooverCompositeSearch(args),
		enabled: !isDealsRoute,
		placeholderData: keepPreviousData,
	});

	return {
		accommodations: query.data?.accommodations || [],
		totalResults: query.data?.totalResults ?? 0,
		aggregations: query.data?.aggregations ?? {},
		geoAggregations: query.data?.geoAggregations ?? {
			city_entity_ids: {},
			country_entity_ids: {},
			region_entity_ids: {},
		},
		isLoadingAccommodations: query.isFetching,
	};
}

export async function getZooverCompositeSearch(
	queryClient: QueryClient,
	filters: FilterState,
	globalFilters: GlobalFiltersState,
	geo: GeoState,
): Promise<Elastic.ZooverCompositeSearchResults | undefined> {
	const args = initZooverCompositeSearchReqParams(filters, globalFilters, geo);

	try {
		return await queryClient.fetchQuery({
			queryKey: getZooverCompositeSearchQueryKey(args),
			queryFn: () => fetchZooverCompositeSearch(args),
		});
	} catch {
		// Don't throw an error if this crashes, we can show that there are no search results which is better than a 404
		return undefined;
	}
}

export async function fetchZooverCompositeSearch(
	body: Search.ZooverCompositeSearchBody,
): Promise<Elastic.ZooverCompositeSearchResults> {
	const query = createZooverCompositeSearchRequest(body);

	try {
		const result = await fetchHandler<Elastic.RawCompositeSearchData>({
			fetchFn: () => fetchVakNL({ input: query }),
			errorMessage: 'Cannot fetch zoover composite search results',
			logOnError: JSON.stringify(body),
		});

		return {
			accommodations: mapCompositeAccommodations(result.results),
			aggregations: result.aggregations,
			geoAggregations: mapCompositeGeoAggregations(result.aggregations),
			totalResults: result.results_total ?? 0,
		};
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, `Error in fetchZooverCompositeSearch`));
		throw new Error('Error in fetchZooverCompositeSearch' + e);
	}
}
