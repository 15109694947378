import { type AdditionalCost, type RawAdditionalCost } from '../additionalCosts';
import { type BookingCosts, BookingCostsType, type RawSurcharges, type Surcharges } from '../booking-costs';
import { type Coupon, type RawCoupon } from '../coupon';
import { type FundsItem, type RawFunds } from '../funds';
import { type Insurance, type RawInsurance } from '../insurance';
import { type Luggage, type RawLuggage } from '../luggage';
import { type RawRentalCar } from '../rentalCars';
import { type RawTransfer, type Transfer } from '../transfer';

export type Extras = {
	transfers: {
		items: Transfer[];
		priceTotal: number;
	};
	rentalCars: {
		items: RawRentalCar[];
		priceTotal: number;
		driverRefId: number;
	};
	luggage: {
		items: Luggage[];
		priceTotal: number;
	};
	insurances: {
		items: Insurance[];
		pricePolicy: number;
		priceTotal: number;
	};
	funds: {
		items: FundsItem[];
		priceTotal: number;
	};
	discounts: {
		items: Coupon[];
		priceTotal: number;
		refId: number;
	};
	surcharges: Surcharges;
	additionalCosts: AdditionalCost | null;
};

export type RawExtras = {
	transfers: {
		items: RawTransfer[];
		price_total: number;
		ref_id: number;
	};
	rental_cars: {
		items: RawRentalCar[];
		driver_ref_id: number;
		price_total: number;
		ref_id: number;
	};
	luggage: {
		items: RawLuggage[];
		price_total: number;
		ref_id: number;
	};
	insurances: {
		items: RawInsurance[];
		price_policy: number;
		price_total: number;
		taxes_total: number;
		ref_id: number;
	};
	funds: RawFunds;
	discounts: {
		items: RawCoupon[];
		price_total: number;
		ref_id: number;
	};
	surcharges: RawSurcharges;
	additional_costs: RawAdditionalCost;
};

/** So earlier made bookings won't break due to the lack of "discounts" field */
export const dummyEmptyDiscountCodes: Extras['discounts'] = {
	items: [],
	priceTotal: 0,
	refId: 6,
};

/** So earlier made bookings won't break due to the lack of "surcharges" field */
export const dummyIncludedBookingCosts: Extras['surcharges'] = {
	items: [
		{
			type: BookingCostsType.BOOKINGCOST,
			price: 0,
		} as BookingCosts,
	],
	priceTotal: 0,
	refId: 6,
};
