import { createContext, useMemo, useContext, type FC, type PropsWithChildren, useEffect, useRef } from 'react';

import { type Search } from '@vakantiesnl/types';

import { useTrackAndReplaceState } from '../../hooks/browser-history';
import { useGetAccommodationByPath } from '../../hooks/queries/useGetAccommodationByPath';
import { useTripFilters } from '../../hooks/rest/trip-filters/hook';
import { useTripFiltersInitializer } from '../../hooks/rest/trip-filters/initializer';
import { type TripFiltersState } from '../../hooks/rest/trip-filters/interfaces';
import { useGlobalFiltersStore } from '../../stores/globalFiltersStore/globalFiltersHooks';
import { convertDurationsToArray, DEFAULT_DURATIONS_ARRAY } from '../../util/durations';

export type TripFilterQueryParams = {
	departureDate?: string;
	durations?: number[] | string;
	airports?: string[];
	mealplans?: string[];
	pax?: string;
	minPrice?: string;
	maxPrice?: string;
	selected: {
		date?: string;
		directOnly?: boolean;
		flight?: {
			outbound: string;
			inbound: string;
		};
		roomType?: string;
		mealPlan?: string;
	};
	airlines?: string[];
	flightProvider?: string;
	tourOperators?: string[];
	// Only passed to override durations, for example when acco has zoover deal user is from a lister
	// with a durations other than the default (such as cityTrip)
	overrideDurations?: number[] | string;
};

type ProviderProps = {
	queryParams: TripFilterQueryParams;
};

export type TripFilterContextProps = {
	state: TripFiltersState;
	computed: {
		durations: number[];
	};
	dispatch: {
		updatePartyComposition: (payload: Search.PartyComposition[]) => void;
		updateAirportsTripFilter: (payload: string[]) => void;
		updateDepartureDateTripFilter: (payload: string[]) => void;
		updateDurationRangeTripFilter: (payload: number[]) => void;
		updateMealplanTripFilter: (payload: string[]) => void;
		updateInvalidPreselection: (invalid: boolean) => void;
		updateSelectedTrip: (date: TripFiltersState['selected']['date']) => void;
		updateSelectedDirectOnly: (directOnly: TripFiltersState['selected']['directOnly']) => void;
		updateSelectedFlight: (flight: TripFiltersState['selected']['flight']) => void;
		updateSelectedRoomType: (roomType: TripFiltersState['selected']['roomType']) => void;
		updateSelectedMealPlan: (mealPlan: TripFiltersState['selected']['mealPlan']) => void;
		updateIsInitialLoad: (isInitialLoad: boolean) => void;
		resetTripFilters: () => void;
	};
};

const TripFilterContext = createContext<TripFilterContextProps>({} as TripFilterContextProps);

export const TripFilterProvider: FC<PropsWithChildren<ProviderProps>> = ({ children, queryParams }) => {
	const { accommodation } = useGetAccommodationByPath();
	const [state, dispatch] = useTripFilters(useTripFiltersInitializer(queryParams));
	useTrackAndReplaceState('tripFilterContext', state);

	const globalFilters = useGlobalFiltersStore((s) => s.filters);
	const setPartyComposition = useGlobalFiltersStore((s) => s.setParty);

	const didMount = useRef(false);

	useEffect(() => {
		if (didMount.current === true) {
			dispatch.resetTripFilters();
		}

		didMount.current = true;

		// Only reset the trip filters when the acco id changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accommodation.heliosId]);

	const globalDurationsArray = !!globalFilters.durations?.length
		? convertDurationsToArray(globalFilters.durations)
		: null;

	const value = useMemo(
		() => ({
			state: { ...state, party: globalFilters.party },
			computed: {
				durations: state.durationsOverride || globalDurationsArray || DEFAULT_DURATIONS_ARRAY,
			},
			dispatch: {
				...dispatch,
				updatePartyComposition: setPartyComposition,
			},
		}),
		[dispatch, state, globalFilters, globalDurationsArray, setPartyComposition],
	);

	return <TripFilterContext.Provider value={value}>{children}</TripFilterContext.Provider>;
};

export const useTripFilterContext = (): TripFilterContextProps => useContext(TripFilterContext);
