import { type Dispatch, useCallback, useMemo, useReducer } from 'react';

import { useGlobalFiltersStore } from '@vakantiesnl/services/src/stores/globalFiltersStore';
import { DEFAULT_DURATIONS_ARRAY } from '@vakantiesnl/services/src/util/durations';

import * as actions from './actions';
import { type TripFiltersState } from './interfaces';
import { initialState, reducer } from './reducer';
import { useGetAccommodationByPath } from '../../queries/useGetAccommodationByPath';

export type DispatchActions = {
	dispatch: Dispatch<actions.ActionTypes>;
	updateAirportsTripFilter: (payload: string[]) => void;
	updateDepartureDateTripFilter: (payload: string[]) => void;
	updateDurationRangeTripFilter: (payload: number[]) => void;
	updateMealplanTripFilter: (payload: string[]) => void;
	updateInvalidPreselection: (invalid: boolean) => void;
	updateSelectedTrip: (date: TripFiltersState['selected']['date']) => void;
	updateSelectedDirectOnly: (flight: TripFiltersState['selected']['directOnly']) => void;
	updateSelectedFlight: (flight: TripFiltersState['selected']['flight']) => void;
	updateSelectedRoomType: (roomType: TripFiltersState['selected']['roomType']) => void;
	updateSelectedMealPlan: (mealPlan: TripFiltersState['selected']['mealPlan']) => void;
	updateIsInitialLoad: (isInitialLoad: boolean) => void;
	resetTripFilters: () => void;
};

export const useTripFilters = (
	initializer: (initialState: TripFiltersState) => TripFiltersState,
): [TripFiltersState, DispatchActions] => {
	const { accommodation } = useGetAccommodationByPath();
	const isAccoOnly = accommodation.isBookableQenner;
	const globalFilters = useGlobalFiltersStore((s) => s.filters);
	const setDurations = useGlobalFiltersStore((s) => s.setDurations);

	const [state, dispatch] = useReducer(reducer, initialState, initializer);

	const updateAirportsTripFilter = useCallback(
		(airports: string[]) => dispatch(actions.updateAirportsTripFilterAction(airports)),
		[dispatch],
	);

	const updateDepartureDateTripFilter = useCallback(
		(departureDate: string[]) => dispatch(actions.updateDepartureDateTripFilterAction(departureDate)),
		[dispatch],
	);

	const updateDurationRangeTripFilter = useCallback(
		(durationRange: number[]) => {
			dispatch(actions.updateDurationRangeTripFilterAction());

			if (isDefaultDurations(durationRange)) {
				setDurations(null);
			} else {
				setDurations(durationRange);
			}
		},
		[dispatch, setDurations],
	);

	const updateMealplanTripFilter = useCallback(
		(mealplan: string[]) => dispatch(actions.updateMealplanTripFilterAction(mealplan)),
		[dispatch],
	);

	const updateInvalidPreselection = useCallback(
		(invalid: boolean) => dispatch(actions.updateInvalidPreselectionAction(invalid)),
		[dispatch],
	);

	const updateSelectedTrip = useCallback(
		(date: TripFiltersState['selected']['date']) => dispatch(actions.updateSelectedTripAction(date)),
		[dispatch],
	);

	const updateSelectedDirectOnly = useCallback(
		(flight: TripFiltersState['selected']['directOnly']) =>
			dispatch(actions.updateSelectedDirectOnlyAction(flight)),
		[dispatch],
	);

	const updateSelectedFlight = useCallback(
		(flight: TripFiltersState['selected']['flight']) => dispatch(actions.updateSelectedFlightAction(flight)),
		[dispatch],
	);

	const updateSelectedRoomType = useCallback(
		(roomType: TripFiltersState['selected']['roomType']) =>
			dispatch(actions.updateSelectedRoomTypeAction(roomType)),
		[dispatch],
	);

	const updateSelectedMealPlan = useCallback(
		(mealPlan: TripFiltersState['selected']['mealPlan']) =>
			dispatch(actions.updateSelectedMealPlanAction(mealPlan)),
		[dispatch],
	);

	const updateIsInitialLoad = useCallback(
		(isInitialLoad: boolean) => dispatch(actions.updateIsInitialLoadAction(isInitialLoad)),
		[dispatch],
	);

	const resetTripFilters = useCallback(
		// Only reset the durations based on accoOnly when no manual durations have been set
		() => dispatch(actions.resetTripFilterAction(!!globalFilters.durations?.length ? false : isAccoOnly)),
		[dispatch, isAccoOnly, globalFilters],
	);

	const dispatchActions: DispatchActions = useMemo(
		() => ({
			dispatch,
			updateAirportsTripFilter,
			updateDepartureDateTripFilter,
			updateDurationRangeTripFilter,
			updateMealplanTripFilter,
			updateInvalidPreselection,
			updateSelectedTrip,
			updateSelectedDirectOnly,
			updateSelectedFlight,
			updateSelectedRoomType,
			updateSelectedMealPlan,
			updateIsInitialLoad,
			resetTripFilters,
		}),
		[
			dispatch,
			updateAirportsTripFilter,
			updateDepartureDateTripFilter,
			updateDurationRangeTripFilter,
			updateMealplanTripFilter,
			updateInvalidPreselection,
			updateSelectedTrip,
			updateSelectedDirectOnly,
			updateSelectedFlight,
			updateSelectedRoomType,
			updateSelectedMealPlan,
			updateIsInitialLoad,
			resetTripFilters,
		],
	);
	return [{ ...state } as TripFiltersState, dispatchActions];
};

const defaultDurationString = DEFAULT_DURATIONS_ARRAY.join();

function isDefaultDurations(newDurations: number[]): boolean {
	const newDurationsAsString = newDurations.join();

	if (newDurationsAsString === defaultDurationString) {
		return true;
	}

	return false;
}
