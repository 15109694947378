import { type FC, type ReactNode } from 'react';

import styles from './ImageGrid.module.css';

type Props = {
	children?: ReactNode;
};

export const ImageGrid: FC<Props> = ({ children }) => (
	<div className={styles.gridContainer}>
		<div className={styles.grid}>{children}</div>
	</div>
);
