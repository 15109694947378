import { useMediaQuery, useTheme } from '@mui/material';
import { breakpoints } from '@vakantiesnl/components/src/styles/breakpoints';

export const useBreakpoints = (): Record<
	'isMobile' | 'isTablet' | 'isDesktop' | 'isDesktopXl' | 'isTabletOrIsDesktop',
	boolean
> => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.between(0, breakpoints.md));
	const isTablet = useMediaQuery(theme.breakpoints.between(breakpoints.md, breakpoints.lg));
	const isDesktop = useMediaQuery(theme.breakpoints.up(breakpoints.lg));
	const isDesktopXl = useMediaQuery(theme.breakpoints.up(breakpoints.xl));
	return {
		isMobile,
		isTablet,
		isDesktop,
		isDesktopXl,
		isTabletOrIsDesktop: isTablet || isDesktop,
	};
};
