import { type Booking } from '../../index';
import { type AgeType } from '../person-type';

export enum GenderType {
	MALE = 'Male',
	FEMALE = 'Female',
}

export enum CountryType {
	NL = 'NL',
	BE = 'BE',
}

type SharedMandatoryBookerDetails = {
	refId: number;
	foundInDb: boolean;
	isMainBooker: boolean;
	ageType: Booking.AgeType;
};

export type MainBooker = {
	dateOfBirth: string | null;
	emailAddress: string | null;
	firstName: string | null;
	gender: GenderType | null;
	isMainBooker: true | null;
	lastName: string | null;
	marketingOptIn: boolean | null;
	phone: string | null;
	age?: number;
	country: CountryType;
} & SharedMandatoryBookerDetails;

export type OtherParticipant = {
	dateOfBirth: string | null;
	firstName: string | null;
	lastName: string | null;
	gender: GenderType | null;
	isMainBooker: false;
	age?: number;
} & SharedMandatoryBookerDetails;

export type Person = MainBooker | OtherParticipant;

export type PersonItems = [MainBooker, ...OtherParticipant[]];

export type Persons = {
	items: PersonItems;
};

type ErrorKey = keyof Booking.MainBooker | keyof Booking.OtherParticipant;

export type ErrorObject = {
	[key in ErrorKey]?: string;
};

export type ErrorComposePersons = {
	[key in number]?: ErrorObject;
};

export type ComposePersonItems = [
	DeepOptional<MainBooker> & {
		refId: number;
		isMainBooker: true;
		ageType: AgeType;
		gender: Booking.GenderType | null;
		country: CountryType;
	},
	...(DeepOptional<OtherParticipant> & {
		refId: number;
		isMainBooker: false;
		ageType: AgeType;
		gender: Booking.GenderType | null;
	})[],
];

export type ComposableKeys = Exclude<
	keyof Booking.ComposePersonItems[1] | keyof Booking.ComposePersonItems[0],
	'isMainBooker'
>;

export type ComposePersons = {
	items: ComposePersonItems;
};

export type RawMainBooker = {
	ref_id: number;
	date_of_birth: string | null;
	email_address: string | null;
	first_name: string | null;
	gender: Booking.GenderType | null;
	last_name: string | null;
	phone: string | null;
	marketing_opt_in: boolean | null;
	is_main_booker: true;
	age_type: Booking.AgeType;
	age: number;
	found_in_db: boolean | null;
};

export type RawOtherParticipant = {
	ref_id: number;
	date_of_birth: string | null;
	first_name: string | null;
	last_name: string | null;
	gender: Booking.GenderType | null;
	is_main_booker: false;
	age_type: Booking.AgeType;
	age: number;
	found_in_db: boolean;
};

export type RawPersons = {
	items: [RawMainBooker, ...RawOtherParticipant[]];
};

export type PatchPersonsRequestBody = { persons?: Record<string, unknown>[] | null };
