import React, { type FC, useMemo } from 'react';

import { RichText } from '@vakantiesnl/components/src/molecules/RichText';
import { useRichText } from '@vakantiesnl/services/src/hooks/contentful';

import { useStyles } from './HeaderNotification.style';

const headerNotificationRichText = {
	['fields.key[in]']: ['headerNotificationContent'],
};

export const HeaderNotification: FC = () => {
	const { classes } = useStyles();

	const richTextElement = useRichText(headerNotificationRichText);

	const headerNotificationContent = useMemo(() => {
		return richTextElement?.[headerNotificationRichText['fields.key[in]'][0]];
	}, [richTextElement]);

	if (headerNotificationContent) {
		return (
			<div className={classes.container}>
				<RichText document={headerNotificationContent} />
			</div>
		);
	}
};
