import { type FC, useEffect, useState, useCallback, type ReactNode } from 'react';

import { Logo } from '@vakantiesnl/components/src/__LEGACY__/atoms/Logo';
import { MenuItem } from '@vakantiesnl/components/src/__LEGACY__/atoms/MenuItem';
import { SubMenuItem } from '@vakantiesnl/components/src/__LEGACY__/atoms/SubMenuItem/SubMenuItem';
import { Menu } from '@vakantiesnl/components/src/__LEGACY__/molecules/Menu';
import { SubMenu } from '@vakantiesnl/components/src/__LEGACY__/molecules/SubMenu';
import { useBreakpoints } from '@vakantiesnl/components/src/utils/useBreakpoints';
import { useIsActivePage } from '@vakantiesnl/services/src/hooks/usePageState';
import { type GTM, type ContentfulLink, type MicroCopy } from '@vakantiesnl/types';

import css from './MenuContainer.module.css';

type Props = {
	menuItems: ContentfulLink[];
	subMenuItems: ContentfulLink[];
	microCopies: MicroCopy;
	pageType: GTM.GTMPageType;
};

export const getActiveSubMenuItem = (subMenuItems: Props['subMenuItems']): ReactNode => {
	let activeSubMenuItem = undefined;
	subMenuItems.map(({ title, link, target, isExternal }) => {
		if (useIsActivePage(link)) {
			activeSubMenuItem = <SubMenuItem title={title} link={link} target={target} isExternal={isExternal} />;
		}
	});

	return activeSubMenuItem;
};

export const MenuContainer: FC<Props> = ({ ...props }) => {
	const [toggled, isToggled] = useState<boolean>(false);
	const { isDesktop } = useBreakpoints();

	useEffect(() => {
		toggled ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset');
		return (): void => {
			document.body.style.overflow = 'unset';
		};
	}, [toggled]);

	const onClick = useCallback((): void => isToggled(!toggled), [toggled, isToggled]);
	const closeMenu = useCallback(() => {
		isToggled(false);
	}, [isToggled]);

	return (
		<>
			<Logo className={css.logo} toggled={toggled} closeMenu={closeMenu} pageType={props.pageType} />
			<div className={css.mainMenuContainer} data-toggled={toggled ? 'toggled' : 'unToggled'}>
				<div className={css.close}>
					{isDesktop && (
						<div className={css.activeItem}>{toggled ? '' : getActiveSubMenuItem(props.subMenuItems)}</div>
					)}
					<MenuItem
						title="Menu"
						toggled={toggled}
						isToggle={true}
						onClick={onClick}
						microCopies={props.microCopies}
					/>
				</div>
				<Menu
					closeMenu={closeMenu}
					microCopies={props.microCopies}
					menuItems={props.menuItems}
					toggled={toggled}
					pageType={props.pageType}
				/>
				{toggled && (
					<div className={css.subMenu}>
						<SubMenu
							closeMenu={closeMenu}
							menuItems={props.subMenuItems}
							isToggled={toggled}
							microCopies={props.microCopies}
						/>
					</div>
				)}
			</div>
		</>
	);
};
