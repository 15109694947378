import { type FC, type PropsWithChildren, createContext, useEffect, useRef } from 'react';

import { type FilterStore, createFilterStore } from './filtersStore';
import { type FilterState } from './interfaces';

export const FilterStoreContext = createContext<FilterStore | null>(null);

export const FilterStoreProvider: FC<PropsWithChildren<{ filters: FilterState }>> = ({ children, filters }) => {
	const store = useRef<FilterStore>();

	if (!store.current) {
		store.current = createFilterStore(filters);
	}

	// Needed for navigation between search pages
	useEffect(() => {
		if (store.current && !!filters) {
			store.current.setState({ filters });
		}

		if (!filters) {
			// eslint-disable-next-line no-console
			console.error('FilterState is undefined');
		}
	}, [filters]);

	return <FilterStoreContext.Provider value={store.current}>{children}</FilterStoreContext.Provider>;
};
