import { type FC, type MouseEventHandler, forwardRef, useCallback } from 'react';

import { Button as MUIButton } from '@mui/base/Button';
import { Icon } from '@vakantiesnl/components/src/atoms/Icon';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useCustomStyles } from './Button.style';
import { type ButtonProps, type ButtonAsTextProps } from './Button.types';

// Ignore type error due to mismatch with @mui/material and @types/react https://github.com/mui/material-ui/issues/41906
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const Button: FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
	const {
		children,
		size = 'medium',
		variant = 'primary',
		subVariant = 'filled',
		active,
		trailingIcon,
		className,
		leadingIcon,
		asText,
		...muiButtonProps
	} = props;

	const { classes, cx } = useCustomStyles({
		size,
		variant,
		subVariant,
		active,
		leadingIcon,
		trailingIcon,
	});

	if (asText) {
		return <TextButton {...props} />;
	}

	return (
		<MUIButton
			{...muiButtonProps}
			className={cx(classes.container, classes[size], !children && classes.iconOnly, className)}
			ref={ref}
		>
			{leadingIcon && <Icon size="medium" type={leadingIcon} />}
			{children}
			{trailingIcon && <Icon size="medium" type={trailingIcon} />}
		</MUIButton>
	);
});

Button.displayName = 'Button';

const TextButton: FC<ButtonProps & ButtonAsTextProps> = ({
	size = 'medium',
	variant,
	subVariant,
	active,
	leadingIcon,
	trailingIcon,
	children,
	className,
	href,
	onClick,
	...restProps
}) => {
	const { classes, cx } = useCustomStyles({
		size,
		variant,
		subVariant,
		active,
		leadingIcon,
		trailingIcon,
	});

	const router = useRouter();

	const button = (
		<span
			className={cx(
				classes.container,
				classes.textButton,
				classes[size],
				!children && classes.iconOnly,
				className,
			)}
		>
			{leadingIcon && <Icon size={'medium'} type={leadingIcon} />}
			{children}
			{trailingIcon && <Icon size="medium" type={trailingIcon} />}
		</span>
	);

	const onButtonClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
		(e) => {
			// If onClick is provided, handle navigation manually
			// This is because normally onClick is not triggered when href is provided
			if (onClick) {
				e.preventDefault();
				onClick(e);
				router.push(e.currentTarget.href);
			}
		},
		[onClick, router],
	);

	if (href) {
		// Use Link component to make sure page does not completely refresh
		// when navigating to internal links
		return (
			// eslint-disable-next-line react/jsx-no-bind
			<Link className={classes.linkButton} onClick={onButtonClick} href={href} data-cy={restProps['data-cy']}>
				{button}
			</Link>
		);
	}

	return button;
};
