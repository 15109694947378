import { type FC, type PropsWithChildren } from 'react';

import { useCustomStyles } from './Typography.styles';
import { type NewTheme } from '../../styles/themes/zoover/newTheme';

export type Element = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'legend' | 'div' | 'label' | 'th' | 'td';

export type TypographyProps = {
	variant: keyof NewTheme['typography'];
	as: Element;
	className?: string;
	id?: string;
	['data-cy']?: string;
};

export const Typography: FC<PropsWithChildren<TypographyProps>> = ({
	as,
	className,
	children,
	variant,
	...restProps
}) => {
	const { classes, cx } = useCustomStyles(variant);
	const Component = as;

	return (
		<Component {...restProps} className={cx(classes.root, className)}>
			{children}
		</Component>
	);
};
