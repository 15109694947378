import { type ReactNode, type FC } from 'react';

import useStyles from './Card.style';

export type CardProps = {
	/** React Children to show in the Card component */
	children: ReactNode;
	/** Custom classname */
	className?: string;
	/** Adds a default boxshadow and border */
	elevated?: boolean;
	/** Show card without border */
	noBorder?: boolean;
	/** Custom overflowstyle */
	overflowStyle?: 'scroll' | 'unset';
};

export const Card: FC<CardProps> = ({ children, className, elevated, noBorder, overflowStyle }) => {
	const { classes: styles, cx } = useStyles();
	return (
		<div
			className={cx(
				styles.card,
				elevated && styles.elevated,
				noBorder && styles.noBorder,
				className && className,
				overflowStyle && styles[overflowStyle],
			)}
		>
			{children}
		</div>
	);
};
