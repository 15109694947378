import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';
import { type NewTheme } from '@vakantiesnl/components/src/styles/themes/zoover/newTheme';

import type { ButtonProps } from './Button.types';

type ButtonVariants = keyof Pick<NewTheme['typography'], 'labelMd' | 'labelSm'>;
type ButtonSizeProps = Required<Pick<ButtonProps, 'size'>>['size'];

const sizeToFontVariantMap: Record<ButtonSizeProps, ButtonVariants> = {
	small: 'labelSm',
	medium: 'labelMd',
};

export const useCustomStyles = makeStyles<
	Pick<ButtonProps, 'size' | 'variant' | 'subVariant' | 'active' | 'trailingIcon' | 'leadingIcon'>
>()((theme, { size = 'medium', active, leadingIcon, trailingIcon, ...props }) => {
	const fontVariant = sizeToFontVariantMap[size];

	return {
		container: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			outline: 'none',
			cursor: 'pointer',
			boxSizing: 'border-box',
			gap: theme.spacing.xxsmall,
			font: theme.typography[fontVariant],
			height: theme.button.height[size],
			borderRadius: theme.button.borderRadius,
			border: getVariantObject(theme, props).border?.[active ? 'pressed' : 'default'] ?? '0',
			borderColor: getVariantObject(theme, props).borderColor?.[active ? 'pressed' : 'default'],
			color: getVariantObject(theme, props).label.color[active ? 'pressed' : 'default'],
			backgroundColor: getVariantObject(theme, props).background.color[active ? 'pressed' : 'default'],

			['@media (hover: hover)']: {
				'&:hover': {
					border: !active ? getVariantObject(theme, props).border?.hover : undefined,
					color: !active ? getVariantObject(theme, props).label.color.hover : undefined,
					backgroundColor: !active ? getVariantObject(theme, props).background.color.hover : undefined,
				},
			},

			'&:focus-visible': {
				color: getVariantObject(theme, props).label.color.focus,
				backgroundColor: getVariantObject(theme, props).background.color.focus,
				boxShadow: theme.button.focus,
			},

			'&:active': {
				border: getVariantObject(theme, props).border?.pressed,
				color: getVariantObject(theme, props).label.color.pressed,
				backgroundColor: getVariantObject(theme, props).background.color.pressed,
			},

			'&:disabled': {
				cursor: 'unset',
				border: getVariantObject(theme, props).border?.disabled,
				color: getVariantObject(theme, props).label.color.disabled,
				backgroundColor: getVariantObject(theme, props).background.color.disabled,
			},
		},
		textButton: {
			display: 'inline-flex',
			textDecoration: 'none',
			width: 'fit-content',
		},
		iconOnly: {
			padding: '0',
			aspectRatio: '1',
		},
		medium: {
			paddingTop: theme.spacing.small,
			paddingBottom: theme.spacing.small,
			paddingLeft: !!leadingIcon ? theme.spacing.xsmall : theme.spacing.small,
			paddingRight: !!trailingIcon ? theme.spacing.xsmall : theme.spacing.small,
		},
		small: {
			paddingTop: theme.spacing.xsmall,
			paddingBottom: theme.spacing.xsmall,
			paddingLeft: !!leadingIcon ? theme.spacing.xxsmall : theme.spacing.xsmall,
			paddingRight: !!trailingIcon ? theme.spacing.xxsmall : theme.spacing.xsmall,
		},
		linkButton: {
			textDecoration: 'none',
		},
	};
});

type Background = NewTheme['button']['primary']['background'];

type Label = NewTheme['button']['primary']['label'];

type ButtonState = keyof NewTheme['button']['primary']['background']['color'];

function getVariantObject(
	theme: NewTheme,
	props: Pick<ButtonProps, 'variant' | 'subVariant'>,
): {
	background: Background;
	label: Label;
	border?: Record<ButtonState, string>;
	borderColor?: Record<ButtonState, string>;
} {
	switch (props.variant) {
		case 'secondary':
			return { ...theme.button.secondary, borderColor: theme.button.secondary.border.color };
		case 'tertiary':
			return { ...theme.button.tertiary, border: theme.button.tertiary.button.border };
		case 'negative':
			switch (props.subVariant) {
				case 'outline':
					return {
						...theme.button.negative.outline,
						border: theme.button.negative.border,
						borderColor: theme.button.negative.outline.border.color,
					};
				default:
					return {
						...theme.button.negative.filled,
						border: theme.button.negative.border,
					};
			}
		default:
			return { ...theme.button.primary, border: undefined, borderColor: theme.button.primary.border.color };
	}
}
