import { type Asset } from 'contentful';

import { type GTMPageType } from '../../gtm';

export type RawSeo = {
	title: string;
	description: string;
	image?: Asset;
	robots: string;
	canonical: string;
	pageType: PageType;
	slug: string;
	queryParams?: string;
	gtmPageType: GTMPageType;
	preload?: Preload[];
};

export enum PageType {
	booking = 'booking',
	page = 'page',
	accommodation = 'accommodation',
	search = 'search',
	contact = 'contact',
}

export type Preload = React.LinkHTMLAttributes<HTMLLinkElement>;

export type Seo = {
	title: string;
	description: string;
	image?: Optional<string>;
	robots: string;
	canonical: string;
	pageType: PageType;
	slug: string;
	queryParams?: Optional<string>;
	gtmPageType: GTMPageType;
	preload?: Optional<Preload[]>;
};
