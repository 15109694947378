import { type Booking } from '@vakantiesnl/types';

export enum ActionLabels {
	inbound = 'terugvlucht',
	outbound = 'heenvlucht',
	directOnly = 'geen_tussenstops',
	showMoreFlights = 'toon_meer_vluchten',
}

export type FlightLuggage = {
	weightAdult: number;
	weightChild: number;
	weightBaby: number;
};

export type CarryOnLuggage = {
	width: number;
	height: number;
	depth: number;
};

export type Airport = {
	value: string;
	code: string;
};

export type Airline = {
	value: string;
	code: string;
};

export type FlightSegment = {
	departureDateTime: Date;
	arrivalDateTime: Date;
	airline?: Airline;
	departureAirport: Airport;
	arrivalAirport: Airport;
	luggage: FlightLuggage;
	carryOnLuggage?: CarryOnLuggage;
	displayFlightNumber: string;
	duration?: undefined;
};

export type Flight = {
	departureDateTime: Date;
	arrivalDateTime: Date;
	airline?: Airline;
	departureAirport: Airport;
	arrivalAirport: Airport;
	stopOver: number;
	flightSegments: FlightSegment[];
	lightestLuggage?: FlightLuggage;
	carryOnLuggage?: CarryOnLuggage;
	displayFlightNumber: string;
	duration?: undefined;
};

export type Flights = {
	outbound: Flight;
	inbound: Flight;
};

export type RawFlightLuggage = {
	carry_on_width: number | null;
	carry_on_height: number | null;
	carry_on_depth: number | null;
	carry_on_weight: number | null;
	carry_on_type: string | null;
	carry_on_extra: string | null;
	weight_adult: number;
	weight_child: number;
	weight_baby: number;
};

export type RawAirport = {
	value: string;
	code: string;
};

export type RawAirline = {
	value: string;
	code: string;
};

export type RawFlightSegment = {
	departure_date_time: string;
	arrival_date_time: string;
	airline?: RawAirline;
	departure_airport: RawAirport;
	arrival_airport: RawAirport;
	luggage: RawFlightLuggage;
	display_flight_number: string;
	duration: number;
	flight_number: string;
	booking_class: string;
};

export type RawFlight = {
	segments: RawFlightSegment[];
	duration: number;
	ref_ids: string[];
	start_date_time: string;
	end_date_time: string;
	provider: string | null;
};

export type RawFlights = {
	outbound_flight: RawFlight;
	inbound_flight: RawFlight;
	ref_id: number;
	booking_status: Booking.RawStatus;
};

export type FlightFilters = {
	outboundFlight?: {
		from: string;
		to: string;
	};
	inboundFlight?: {
		from: string;
		to: string;
	};
	directOnly?: boolean;
};
