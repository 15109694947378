import { type FC } from 'react';

import { Content } from '@vakantiesnl/components/src/__LEGACY__/atoms/Content';
import { MenuContainer } from '@vakantiesnl/components/src/__LEGACY__/molecules/MenuContainer';
import { HeaderNotification } from '@vakantiesnl/components/src/atoms/HeaderNotification/HeaderNotification';
import { HeaderTopBar } from '@vakantiesnl/components/src/molecules/HeaderTopBar/HeaderTopBar';
import { useHeaderContext } from '@vakantiesnl/services/src/context/headerContext';
import { type ContentfulLink, type MicroCopy, type GTM, type HeaderNavBlock } from '@vakantiesnl/types';

import useStyles from './Header.styles';

type Props = {
	menuItems: ContentfulLink[];
	subMenuItems: ContentfulLink[];
	microCopies: MicroCopy;
	showTopBar?: boolean;
	pageType: GTM.GTMPageType;
};

export const Header: FC<Props> = ({ menuItems, microCopies, subMenuItems, showTopBar = true, pageType }) => {
	const { classes: styles } = useStyles();
	const headerContext = useHeaderContext<HeaderNavBlock>();

	return (
		<>
			{showTopBar && <HeaderTopBar {...(headerContext as HeaderNavBlock)} showTrustPilot />}
			<div>
				<Content>
					<header className={styles.menuHeader}>
						<MenuContainer
							menuItems={menuItems}
							subMenuItems={subMenuItems}
							microCopies={microCopies}
							pageType={pageType}
						/>
					</header>
				</Content>
			</div>
			<HeaderNotification />
		</>
	);
};
