import { type ReactNode, forwardRef, useCallback, useState, useMemo, type ChangeEvent } from 'react';

import { type TextFieldProps } from '@mui/material/TextField';

import { useCustomStyles } from './TextField.style';
import { FormControlComponent } from '../../atoms/FormControl';
import { FormHelperTextComponent } from '../../atoms/FormHelperText';
import { Icon as IconComponent } from '../../atoms/Icon';
import { InputComponent, type InputProps } from '../../atoms/Input';
import { InputAdornmentComponent } from '../../atoms/InputAdornment';
import { InputLabelComponent } from '../../atoms/InputLabel';

// We only use a subset of the props. Since our textfield doesn't allow a textarea but only an input
export type TextfieldComponentProps = Omit<
	TextFieldProps,
	| 'children'
	| 'classes'
	| 'color'
	| 'FormHelperTextProps'
	| 'fullWidth'
	| 'hiddenLabel'
	| 'InputLabelProps'
	| 'InputProps'
	| 'margin'
	| 'multiline'
	| 'rows'
	| 'maxRows'
	| 'minRows'
	| 'select'
	| 'SelectProps'
	| 'sx'
> & {
	InputProps?: Omit<InputProps, 'label' | 'value'>;
	maxLength?: number;
	inputSize?: 'small' | 'medium';
	/**
	 * Show current length if there is no helper.
	 * If maxLength is set format is <current length> / <max length>
	 *   otherwise <current length>
	 * @default false
	 */
	showCurrentLength?: boolean;
} & {
	showErrorAdornment?: boolean;
	showValidAdornment?: boolean;
};

const TextFieldComponent = forwardRef<HTMLDivElement, TextfieldComponentProps>(
	(
		{
			autoComplete,
			autoFocus,
			defaultValue,
			disabled,
			error,
			helperText,
			id,
			className,
			InputProps,
			inputRef,
			label,
			name,
			onBlur,
			onChange,
			onFocus,
			onClick,
			onKeyDown,
			placeholder,
			required,
			showErrorAdornment,
			showValidAdornment,
			type,
			value,
			maxLength,
			inputSize,
			showCurrentLength = false,
		},
		ref,
	) => {
		const { classes, cx } = useCustomStyles();

		const validationAdornment = useCallback(
			(): ReactNode => (
				<InputAdornmentComponent position="end" type="icon">
					<IconComponent
						type={showErrorAdornment ? 'cancel' : 'checkmark'}
						className={showErrorAdornment ? classes.endAdornmentError : classes.endAdornmentValid}
					/>
				</InputAdornmentComponent>
			),
			[showErrorAdornment, classes.endAdornmentError, classes.endAdornmentValid],
		)();

		const [currentLength, setCurrentLength] = useState<number>(
			(typeof value === 'string' && value?.length) ||
				(typeof defaultValue === 'string' && defaultValue?.length) ||
				0,
		);

		const lengthHelper = useMemo(() => {
			return maxLength ? `${currentLength || 0}/${maxLength}` : `${currentLength || 0}`;
		}, [maxLength, currentLength]);

		const onLengthChangeHandler = useCallback(
			(evt: ChangeEvent<HTMLInputElement>) => {
				showCurrentLength && setCurrentLength(evt.currentTarget.value?.length || 0);
				InputProps?.onChange && InputProps?.onChange(evt);
			},
			[showCurrentLength, InputProps, setCurrentLength],
		);

		return (
			<FormControlComponent
				disabled={disabled}
				error={error}
				required={required}
				value={value}
				onChange={onChange}
				onClick={onClick}
				className={cx(className)}
				ref={ref}
				defaultValue={defaultValue}
			>
				{label && (
					<InputLabelComponent htmlFor={id} className={classes.label}>
						{label}
					</InputLabelComponent>
				)}
				<InputComponent
					id={id}
					label={label}
					placeholder={placeholder}
					autoFocus={autoFocus}
					autoComplete={autoComplete}
					ref={inputRef}
					name={name}
					onBlur={onBlur}
					onFocus={onFocus}
					onChange={onLengthChangeHandler}
					onKeyDown={onKeyDown}
					maxLength={maxLength}
					inputSize={inputSize}
					type={type}
					endAdornment={
						showErrorAdornment || showValidAdornment
							? validationAdornment
							: InputProps?.endAdornment
								? InputProps.endAdornment
								: null
					}
					{...InputProps}
				/>
				{helperText ? (
					<FormHelperTextComponent className={classes.helperText} error={error}>
						{helperText}
					</FormHelperTextComponent>
				) : (
					showCurrentLength && (
						<FormHelperTextComponent className={classes.helperText}>{lengthHelper}</FormHelperTextComponent>
					)
				)}
			</FormControlComponent>
		);
	},
);

TextFieldComponent.displayName = 'TextFieldComponent';

export { TextFieldComponent };
