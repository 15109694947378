import { sumObjectValues } from '@vakantiesnl/services/src/util/mathUtils';
import { type Search, type MicroCopy } from '@vakantiesnl/types';
import { type PartyCategory } from '@vakantiesnl/types/src/search';

export const buildSummarizedPartyString = (rooms: Search.PartyComposition[], microCopies: MicroCopy): string => {
	const sum = rooms.flatMap((party) => [party.adults, party.children, party.babies]).reduce((sum, x) => sum + x, 0);

	return `${sum} ${microCopies['filters.persons' + (sum === 1 ? 'Singular' : 'Plural')].toLowerCase()}`;
};

export const buildShortSummarizedPartyString = (rooms: Search.PartyComposition[], microCopies: MicroCopy): string => {
	const sum = rooms.flatMap((party) => [party.adults, party.children, party.babies]).reduce((sum, x) => sum + x, 0);

	return `${sum} ${microCopies['common.person.short']}`;
};

export const getPartyMaximums = (
	party: Search.PartyComposition,
	maxCount: number,
	defaultMaximums: Search.PartyComposition,
): Search.PartyComposition => {
	return (Object.keys(party) as PartyCategory[]).reduce(
		(prev, category: PartyCategory) => ({
			...prev,
			[category]: Math.min(prev[category], maxCount - sumObjectValues(party) + party[category]),
		}),
		defaultMaximums,
	);
};

export const DEFAULT_PARTY = [{ adults: 2, children: 0, babies: 0 }];
export const DEFAULT_PARTY_MAXIMUM = { adults: 9, children: 8, babies: 8 };
export const MINIMAL_AMOUNT_ADULTS = 1;
export const MAX_PARTY_COUNT = 9;

export const formatPaxQuery = (rooms: Search.PartyComposition[]): string =>
	rooms.map((party) => `${party.adults}_${party.children}_${party.babies}`).join('-');

export const unformatPaxQuery = (roomsQuery: string): Search.PartyComposition[] | null => {
	const rooms = roomsQuery.split('-').map((partyQuery) => {
		const partyArray = partyQuery.split('_').map((value) => Number(value));

		return {
			adults: partyArray[0] || 0,
			children: partyArray[1] || 0,
			babies: partyArray[2] || 0,
		};
	});

	/** Amount of adults cant be under the ${MINIMAL_AMOUNT_ADULTS} */
	if (rooms.some((party) => party.adults < MINIMAL_AMOUNT_ADULTS)) return null;

	/** Number of total pax is limited to the ${MAX_PARTY_COUNT} */
	if (rooms.reduce((prev, party) => party.adults + party.children + party.babies + prev, 0) > MAX_PARTY_COUNT)
		return null;

	/** Number of pax per type can't be more the maximum */
	if (
		rooms.some(
			(party) =>
				party.adults > DEFAULT_PARTY_MAXIMUM.adults ||
				party.children > DEFAULT_PARTY_MAXIMUM.children ||
				party.babies > DEFAULT_PARTY_MAXIMUM.babies,
		)
	)
		return null;

	return rooms;
};

export const DEFAULT_PARTY_QUERY = formatPaxQuery(DEFAULT_PARTY);

/** Reduce all parties to 1 object */
export const getTotalPartyCounts = (party: Search.PartyComposition[]): Search.Party => {
	return party.reduce<Search.Party>(
		(accumulator, currentObj) => {
			let totalCount = 0;

			// Sum values for each key and count total
			Object.entries(currentObj).forEach(([key, value]) => {
				const castedKey = key as keyof Search.Party;
				if (value !== undefined) {
					if (!accumulator[castedKey]) {
						accumulator[castedKey] = 0;
					}
					accumulator[castedKey] += value;
					totalCount += value;
				}
			});

			// Update the total count in the accumulator
			accumulator.total = totalCount + (accumulator.total || 0);

			return accumulator;
		},
		{ adults: 0, babies: 0, children: 0, total: 0 },
	);
};
