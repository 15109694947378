import { type FC, type PropsWithChildren, createContext, useEffect, useRef } from 'react';

import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

import { type ApplicationState, type ApplicationStore, createApplicationStore } from './applicationStore';

export const ApplicationStoreContext = createContext<ApplicationStore | null>(null);

export const ApplicationStoreProvider: FC<PropsWithChildren<Partial<ApplicationState>>> = ({
	children,
	isBot,
	features,
	staticCanonicalOrigin,
	dynamicOrigin,
	userId,
}) => {
	const store = useRef<ApplicationStore>();

	if (!store.current) {
		store.current = createApplicationStore({ isBot, features, staticCanonicalOrigin, dynamicOrigin, userId });
	}

	// Update the store in case any of the props have changed upon navigation.
	useEffect(() => {
		if (store.current && isBot !== undefined) {
			store.current.setState({ isBot });
		}
	}, [isBot]);

	useEffect(() => {
		if (store.current && staticCanonicalOrigin !== undefined) {
			store.current.setState({ staticCanonicalOrigin });
		}
	}, [staticCanonicalOrigin]);

	useEffect(() => {
		if (store.current && dynamicOrigin !== undefined) {
			store.current.setState({ dynamicOrigin });
		}
	}, [dynamicOrigin]);

	// deepCompare because features is an object
	useDeepCompareEffectNoCheck(() => {
		if (store.current && features !== undefined) {
			store.current.setState({ features });
		}
	}, [features]);

	return <ApplicationStoreContext.Provider value={store.current}>{children}</ApplicationStoreContext.Provider>;
};
