import { type FC, memo, type SVGProps } from 'react';

import Accommodation from './assets/accommodation.svg';
import Airline from './assets/airline.svg';
import AirplaneLeft from './assets/airplane-left.svg';
import AirplaneRight from './assets/airplane-right.svg';
import AirplaneTakeOff from './assets/airplane-takeOff.svg';
import Airplane from './assets/airplane.svg';
import AllInclusive from './assets/allInclusive.svg';
import Aparthotel from './assets/aparthotel.svg';
import ArrowLeft from './assets/arrow-left.svg';
import ArrowRight from './assets/arrow-right.svg';
import ArrowUpAndDown from './assets/arrow-up-and-down.svg';
import Asia from './assets/asia.svg';
import Award from './assets/award.svg';
import BabyCot from './assets/baby-cot.svg';
import BedBreakfast from './assets/bedBreakfast.svg';
import Bolt from './assets/bolt.svg';
import Breakfast from './assets/breakfast.svg';
import Calendar from './assets/calendar.svg';
import Camping from './assets/camping.svg';
import CancelCircle from './assets/cancel-circle.svg';
import Cancel from './assets/cancel.svg';
import Car from './assets/car.svg';
import Castle from './assets/castle.svg';
import Chalet from './assets/chalet.svg';
import Chat from './assets/chat.svg';
import Checkmark from './assets/checkmark.svg';
import ChevronDown from './assets/chevron-down.svg';
import ChevronLeft from './assets/chevron-left.svg';
import ChevronRight from './assets/chevron-right.svg';
import ChevronUp from './assets/chevron-up.svg';
import CityTrips from './assets/city-trips.svg';
import Destination from './assets/destination.svg';
import Distance from './assets/distance.svg';
import Document from './assets/document.svg';
import Door from './assets/door.svg';
import EditNav from './assets/edit.svg';
import Entertainment from './assets/entertainment.svg';
import Envelope from './assets/envelope.svg';
import External from './assets/external.svg';
import Facebook from './assets/facebook.svg';
import FamilyRoom from './assets/family-room.svg';
import FavoriteFilled from './assets/favorite_filled.svg';
import Favorite from './assets/favorite_outline.svg';
import Filter from './assets/filter.svg';
import Flag from './assets/flag.svg';
import Food from './assets/food.svg';
import FullBoard from './assets/fullBoard.svg';
import Gameroom from './assets/gameroom.svg';
import Google from './assets/google.svg';
import HalfBoard from './assets/halfBoard.svg';
import Hashtag from './assets/hashtag.svg';
import Help from './assets/help.svg';
import Home from './assets/home.svg';
import Hostel from './assets/hostel.svg';
import Hotel from './assets/hotel.svg';
import Image from './assets/image.svg';
import Info from './assets/info.svg';
import Jacuzzi from './assets/jacuzzi.svg';
import KidsClub from './assets/kids-club.svg';
import Lock from './assets/lock.svg';
import Logout from './assets/logout.svg';
import Luggage from './assets/luggage.svg';
import MagnifyGlass from './assets/magnify-glass.svg';
import Mansion from './assets/mansion.svg';
import Menu from './assets/menu.svg';
import CircleMinus from './assets/minus-alt.svg';
import Minus from './assets/minus.svg';
import Overview from './assets/overview.svg';
import HolidayPark from './assets/parks.svg';
import Anvr from './assets/payment_options/anvr.svg';
import Bancontact from './assets/payment_options/bancontact.svg';
import Calamiteitenfonds from './assets/payment_options/calamiteitenfonds.svg';
import Ideal from './assets/payment_options/ideal.svg';
import Mastercard from './assets/payment_options/mastercard.svg';
import Sgr from './assets/payment_options/sgr.svg';
import Visa from './assets/payment_options/visa.svg';
import Pdf from './assets/pdf.svg';
import Person from './assets/person.svg';
import Pets from './assets/pets.svg';
import Phone from './assets/phone.svg';
import Pin from './assets/pin.svg';
import Playground from './assets/playground.svg';
import CirclePlus from './assets/plus-alt.svg';
import Plus from './assets/plus.svg';
import Pool from './assets/pool.svg';
import Question from './assets/question.svg';
import Replay from './assets/replay.svg';
import Room from './assets/room.svg';
import Search from './assets/search.svg';
import Share from './assets/share.svg';
import Snowman from './assets/snowman.svg';
import Sort from './assets/sort.svg';
import Sports from './assets/sports.svg';
import Star from './assets/star.svg';
import SunAndSnow from './assets/sun-and-snow.svg';
import Sun from './assets/sun.svg';
import Time from './assets/time.svg';
import Transfer from './assets/transfer.svg';
import Trash from './assets/trash.svg';
import Umbrella from './assets/umbrella.svg';
import Villa from './assets/villa.svg';
import VisibilityOff from './assets/visibility-off.svg';
import VisibilityOn from './assets/visibility-on.svg';
import Warning from './assets/warning.svg';
import Waterpark from './assets/waterpark.svg';
import Wheelchair from './assets/wheelchair.svg';
import Wifi from './assets/wifi.svg';
import Winter from './assets/winter.svg';
import Zoover from './assets/zoover.svg';
import useStyles from './Icon.style';
import type { IconTypes } from './Icon.types';

/** Make sure this list is alphabetically ordered */
export const internalIcons = {
	accommodation: Accommodation,
	airline: Airline,
	airplane: Airplane,
	airplaneLeft: AirplaneLeft,
	airplaneRight: AirplaneRight,
	airplaneTakeOff: AirplaneTakeOff,
	allInclusive: AllInclusive,
	apartHotel: Aparthotel,
	anvr: Anvr,
	arrowLeft: ArrowLeft,
	arrowRight: ArrowRight,
	arrowUpAndDown: ArrowUpAndDown,
	asia: Asia,
	award: Award,
	babyCot: BabyCot,
	bedBreakfast: BedBreakfast,
	bancontact: Bancontact,
	bolt: Bolt,
	breakfast: Breakfast,
	calamiteitenfonds: Calamiteitenfonds,
	calendar: Calendar,
	camping: Camping,
	cancel: Cancel,
	cancelCircle: CancelCircle,
	car: Car,
	castle: Castle,
	chalet: Chalet,
	chat: Chat,
	checkmark: Checkmark,
	chevronDown: ChevronDown,
	chevronLeft: ChevronLeft,
	chevronRight: ChevronRight,
	chevronUp: ChevronUp,
	circleMinus: CircleMinus,
	circlePlus: CirclePlus,
	cityTrips: CityTrips,
	destination: Destination,
	distance: Distance,
	document: Document,
	door: Door,
	editNav: EditNav,
	entertainment: Entertainment,
	envelope: Envelope,
	external: External,
	facebook: Facebook,
	familyRoom: FamilyRoom,
	favorite: Favorite,
	favoriteFilled: FavoriteFilled,
	filter: Filter,
	flag: Flag,
	food: Food,
	fullBoard: FullBoard,
	gameroom: Gameroom,
	google: Google,
	halfBoard: HalfBoard,
	hashtag: Hashtag,
	help: Help,
	home: Home,
	hostel: Hostel,
	hotel: Hotel,
	ideal: Ideal,
	image: Image,
	info: Info,
	jacuzzi: Jacuzzi,
	kidsClub: KidsClub,
	logout: Logout,
	lock: Lock,
	luggage: Luggage,
	magnifyGlass: MagnifyGlass,
	mansion: Mansion,
	mastercard: Mastercard,
	menu: Menu,
	minus: Minus,
	overview: Overview,
	holidayPark: HolidayPark,
	pdf: Pdf,
	person: Person,
	pets: Pets,
	phone: Phone,
	pin: Pin,
	playground: Playground,
	plus: Plus,
	pool: Pool,
	question: Question,
	replay: Replay,
	room: Room,
	search: Search,
	sgr: Sgr,
	share: Share,
	snowman: Snowman,
	sort: Sort,
	sports: Sports,
	star: Star,
	sun: Sun,
	sunAndSnow: SunAndSnow,
	time: Time,
	transfer: Transfer,
	trash: Trash,
	umbrella: Umbrella,
	villa: Villa,
	visa: Visa,
	visibilityOff: VisibilityOff,
	visibilityOn: VisibilityOn,
	warning: Warning,
	waterpark: Waterpark,
	wheelchair: Wheelchair,
	wifi: Wifi,
	winter: Winter,
	zoover: Zoover,
};
/** Make sure this list is alphabetically ordered */

export type IconProps = {
	className?: string;
	type?: IconTypes;
	symbol?: FC<SVGProps<SVGSVGElement> & { title?: string }>;
	title?: string;
	size?: 'xLarge' | 'large' | 'medium' | 'small' | 'xSmall';
};

const Icon: FC<IconProps> = ({ className, type, symbol, title, size }) => {
	const Component = symbol as FC<SVGProps<SVGSVGElement> & { title?: string }>;
	const { classes: styles, cx } = useStyles({ size });
	const IconTypeComponent = type && internalIcons[type];

	return (
		<>
			{type ? (
				<IconTypeComponent className={cx(styles.icon, className)} />
			) : (
				<Component title={title} className={cx(styles.icon, className)} />
			)}
		</>
	);
};

const memoizedIcon = memo(Icon);
export { memoizedIcon as Icon };
