/* eslint-disable @typescript-eslint/explicit-function-return-type */
/** The following functions are hooks to get the geoState in with react-query in components and custom hooks */

import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useFilterStore } from '@vakantiesnl/services/src/stores/filtersStore';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';

import { fetchCountries, fetchRegions, fetchCities } from './geoQueryFns';
import { filterCountries, filterRegions, getCitiesQueryId, getCountriesQueryId, getRegionsQueryId } from './helpers';
import { type GeoState } from './interfaces';
import { useGetGeoContext } from '../useGetGeoContext';

/** NB: Use this one only in Zoover as Zoover does not have geoContext */
export function useGetGeo(): GeoState {
	const filters = useFilterStore((s) => s.filters);
	const countrySlugs = filters.countries.map((c) => c.slug);
	const regionSlugs = filters.regions.map((r) => r.slug);

	const countries = useGetCountries();
	const regions = useGetRegions(countrySlugs);
	const cities = useGetCities(regionSlugs, countrySlugs.length === 1);

	return {
		countries: countries.data ?? [],
		regions: regions.data ?? {},
		cities: cities?.data ?? {},
	};
}

/** NB: Use this one only in Vakanties as Zoover does not have geoContext */
export function useGetGeoByContext(): GeoState {
	const { countrySlugsWithAccos, regionSlugsWithAccos } = useGetGeoContext();
	const selectedCountrySlugs = useFilterStore((s) => s.filters.countrySlugs);
	const selectedRegionSlugs = useFilterStore((s) => s.filters.regionSlugs);

	/** Fetch geo data based on selected geo's in the filters */
	const countries = useGetCountries();

	const selectedCountriesWithAccos = selectedCountrySlugs.filter((s) => countrySlugsWithAccos.includes(s));
	const regions = useGetRegions(selectedCountriesWithAccos);

	const selectedRegionsWithAccos = selectedRegionSlugs.filter((s) => regionSlugsWithAccos.includes(s));
	const cities = useGetCities(selectedRegionsWithAccos, selectedCountrySlugs.length === 1);

	/**  Filter geo based on selectable geo that has accommodations */
	const filteredCountries =
		countries.data && filterCountries(countries.data, selectedCountrySlugs, countrySlugsWithAccos);

	const filteredRegions = regions.data && filterRegions(regions.data, selectedRegionSlugs, regionSlugsWithAccos);

	return {
		countries: filteredCountries ?? [],
		regions: filteredRegions ?? {},
		cities: cities?.data ?? {},
	};
}

export function useGetCountries() {
	return useQuery({
		queryKey: getCountriesQueryId(),
		queryFn: fetchCountries,
		staleTime: getMinutesInMs(15),
	});
}

export function useGetRegions(countrySlugs: string[]) {
	return useQuery({
		queryKey: getRegionsQueryId(countrySlugs),
		queryFn: () => fetchRegions(countrySlugs),
		enabled: !!countrySlugs.length, // Don't fetch when there are no countryIds to request regions for
		staleTime: getMinutesInMs(15),
		placeholderData: keepPreviousData, // Keep data so it still shows previous region filters while fetching new ones
	});
}

export function useGetCities(regionSlugs: string[], isEnabled: boolean) {
	return useQuery({
		queryKey: getCitiesQueryId(regionSlugs),
		queryFn: () => fetchCities(regionSlugs),
		enabled: isEnabled && !!regionSlugs.length, // Don't fetch when there are no regionIds to request cities for
		staleTime: getMinutesInMs(15),
		placeholderData: keepPreviousData, // Keep data so it still shows previous city filters while fetching new ones
	});
}
