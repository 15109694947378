import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles<void, 'disabled'>()((theme, _, classes) => ({
	root: {
		marginRight: 0,
		marginLeft: 0,
		alignItems: 'start',

		'.MuiFormControlLabel-asterisk': {
			color: theme.body.foreground.color.mandatory,
		},
	},

	label: {
		marginLeft: theme.spacing.xxsmall,
		font: theme.typography.bodyMd,
		color: theme.body.foreground.color.default,

		[`.${classes.disabled}&&`]: {
			color: theme.body.foreground.color.default,
		},
	},

	disabled: {},
}));
