import { type FC } from 'react';

import { Content } from '@vakantiesnl/components/src/atoms/Content';
import { Icon } from '@vakantiesnl/components/src/atoms/Icon';
import { TrustPilot } from '@vakantiesnl/components/src/atoms/TrustPilot';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography';
import { Partners } from '@vakantiesnl/components/src/molecules/Partners';
import { useBreakpoints } from '@vakantiesnl/components/src/utils/useBreakpoints';
import { type Partner, type Usp } from '@vakantiesnl/types';

import { useStyles } from './HeaderTopBar.styles';

export type Props = {
	usps: Usp[];
	partners: Partner[];
	paymentPartners: Partner[];
	className?: string;
	showTrustPilot?: boolean;
};

export const HeaderTopBar: FC<Props> = ({ usps, partners, className, showTrustPilot }) => {
	const { classes, cx } = useStyles();
	const { isDesktop } = useBreakpoints();

	return (
		<div className={cx(classes.container, className)} data-cy="headerTopBar">
			<Content className={classes.content}>
				<div className={classes.leftBlock}>
					{usps.map(({ title }, index) => (
						<div key={index} className={classes.usp}>
							<Icon type="checkmark" />
							<Typography
								as="span"
								variant={!isDesktop ? 'labelXs' : 'labelSm'}
								className={classes.uspTitle}
							>
								{title}
							</Typography>
						</div>
					))}
				</div>

				<div className={classes.rightBlock}>
					<div className={classes.usp}>
						<Typography as="span" variant={!isDesktop ? 'labelXs' : 'labelSm'} className={classes.uspTitle}>
							Boek met garantie
						</Typography>
						<Partners partners={partners} />
					</div>

					{isDesktop && showTrustPilot && (
						<div>
							<TrustPilot type="micro" />
						</div>
					)}
				</div>
			</Content>
		</div>
	);
};
