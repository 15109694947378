import { type FC, memo } from 'react';

import { Link } from '@vakantiesnl/components/src/__LEGACY__/atoms/Link';
import { useIsActivePage } from '@vakantiesnl/services/src/hooks/usePageState';
import classNames from 'classnames';

import styles from './SubMenuItem.module.css';

type Props = {
	title: string;
	link: string;
	target: '_self' | '_blank';
	onClick?: VoidFunction;
	toggled?: boolean;
	isExternal: boolean;
};

const SubMenuItem: FC<Props> = (props) => {
	const isActive = useIsActivePage(props.link);
	const activeClass = isActive ? styles.active : '';

	return (
		<li
			className={classNames(styles.subMenuItem, activeClass)}
			data-toggled={props.toggled ? 'toggled' : 'unToggled'}
		>
			<Link
				href={props.link}
				{...props}
				onClick={props.onClick}
				className={classNames(styles.subMenuItemTitle, activeClass)}
				target={props.target}
				rel={props.isExternal ? 'noopener' : undefined}
			>
				{props.title}
			</Link>
		</li>
	);
};

const memoizedSubMenuItem = memo(SubMenuItem);
export { memoizedSubMenuItem as SubMenuItem };
