import { getDateYMD } from '@vakantiesnl/services/src/util/dateUtils';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import { prikdatum } from '../route-params';

export function getDefaultDate(): string | undefined {
	const isTestEnv = process.env.INCLUDE_MOCKS && typeof window !== 'undefined';
	const testPrikdatum = isTestEnv ? Cookies.get('test_prikdatum') : '';

	const targetDate = dayjs(testPrikdatum || prikdatum.date, 'YYYY-MM-DD');
	return targetDate.isValid() && dayjs().isBefore(targetDate.subtract(5, 'day')) ? getDateYMD(targetDate) : undefined;
}
