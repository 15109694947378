import { type IconTypes } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';

export type Award = {
	awardType: AwardType;
	regionAward: boolean;
	rank: number;
};

export type AwardsData = Record<number, Award>;

export enum AwardType {
	Gold = 'gold',
	Silver = 'silver',
}

/** If we ever need to change the icons for different awards, we can do it here. Now they remain the same */
export const awardTypeIconMapping: Record<AwardType, IconTypes> = {
	gold: 'award_trophy',
	silver: 'award_trophy',
};
