import { type Elastic, type MicroCopy } from '@vakantiesnl/types';
import { sortBy } from 'lodash';

import { GROUP_ORDER } from './_tempInfoOrder';

// Groups and formats the fetched acco facts (facilities) for the acco info display
export const groupFacilities = (facilities: Elastic.FetchedFact[] = []): Elastic.FormattedFacts[] => {
	const facilityGroupNames: Elastic.FACILITY_GROUP_NAME[] = [];
	facilities.forEach((facility) => {
		if (facilityGroupNames.includes(facility.group)) {
			return;
		} else {
			facilityGroupNames.push(facility.group);
		}
	});

	const groupedFacilities = facilityGroupNames.map((groupName) => {
		const currentGroupInfo = facilities.find((facility) => facility.group === groupName);

		// group the facts based on their name
		const group = {
			key: currentGroupInfo?.key || groupName,
			name: groupName,
			groups: facilities
				.filter((facility) => facility.group === groupName)
				.filter((facility) => facility.value)
				.map((facility) => {
					return {
						key: facility.key,
						name: facility.name,
						value: facility.value.replace(/["]/g, ''),
						group: facility.group,
					};
				}),
		};
		return group;
	});

	// order the groups based on the requirement
	const orderedFacilities = sortBy(groupedFacilities, (group) => GROUP_ORDER.indexOf(group.name));

	return orderedFacilities;
};

// Parses the fact/facility values into their required translation
export const valueTranslator = (
	facilityValue: string | boolean | number,
	microCopies: MicroCopy,
): { value: string; isAvailable: boolean | undefined } => {
	const availableTranslation = microCopies['fact.value.available'];
	const unavailableTranslation = microCopies['fact.value.unavailable'];

	const availableCount = getAvailableCount(facilityValue);

	if (availableCount === undefined) {
		return { isAvailable: undefined, value: facilityValue.toString() };
	}

	if (availableCount <= 0) {
		return { isAvailable: false, value: unavailableTranslation };
	}

	const value = availableCount === 1 ? availableTranslation : `${availableTranslation} (${facilityValue})`;

	return { isAvailable: true, value };
};

function getAvailableCount(facilityValue: string | boolean | number): number | undefined {
	if (facilityValue === 'false' || facilityValue === 0 || facilityValue === '0') {
		return 0;
	} else if (facilityValue === 'true' || facilityValue === '1') {
		return 1;
	} else if (!isNaN(Number(facilityValue))) {
		return Number(facilityValue);
	} else {
		return undefined;
	}
}

/** Parses the distance value given in meter into km or m */
export const distanceTranslator = (
	facilityValue: string | boolean | number,
	microCopies: MicroCopy,
	toKm = true,
): string => {
	const kmTranslation = microCopies['fact.value.km'];
	const mTranslation = microCopies['fact.value.meter'];
	const numberFormat = Number(facilityValue);
	let distanceTranslation = '';

	if (typeof numberFormat === 'number' && !isNaN(numberFormat)) {
		distanceTranslation = toKm ? `${numberFormat / 1000} ${kmTranslation}` : `${numberFormat}${mTranslation}`;
	} else {
		distanceTranslation = facilityValue.toString();
	}

	return distanceTranslation;
};
