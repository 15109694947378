import { useContext } from 'react';

import { type Search } from '@vakantiesnl/types/src';
import { useStore } from 'zustand';

import { GlobalFiltersStoreContext } from './globalFiltersContext';
import { type GlobalFilterStoreState } from './globalFiltersStore';

export function useGlobalFiltersStore<T>(selector: (state: GlobalFilterStoreState) => T): T {
	const store = useContext(GlobalFiltersStoreContext);

	if (!store) throw new Error('Missing GlobalFiltersContext.Provider in the tree');

	return useStore(store, selector);
}

export function usePartyComposition(): Search.PartyComposition[] {
	return useGlobalFiltersStore((s) => s.filters.party);
}
