import { type FC } from 'react';

import { PartnerItem } from '@vakantiesnl/components/src/atoms/PartnerItem';
import { type Partner } from '@vakantiesnl/types';

import { useStyles } from './Partners.style';

type Props = {
	partners: Partner[];
	priority?: boolean;
};

export const Partners: FC<Props> = ({ partners, priority = false }) => {
	const { classes } = useStyles();

	return (
		<ul className={classes.partnerItems}>
			{partners.map((partnerItemProps, key) => (
				<PartnerItem {...partnerItemProps} key={key} priority={priority} />
			))}
		</ul>
	);
};
