import { getDateYMD, getMinDepartureDate, parseToYMDDate } from '../../util/dateUtils';

export const PAGE_OFFSET = 31;

export function getDepartureDateAndOffset(departureDate?: string): {
	departureDate: string | undefined;
	preOffset: number;
	postOffset: number;
} {
	const offset = PAGE_OFFSET - 1; // - 1 to avoid overlap of a day from the next page

	if (!departureDate) {
		return {
			departureDate: undefined,
			preOffset: 0,
			postOffset: offset,
		};
	}

	const minDate = getMinDepartureDate();
	const parsedDepartureDate = parseToYMDDate(departureDate);

	if (parsedDepartureDate.isBefore(minDate)) {
		const diff = minDate.diff(parsedDepartureDate, 'day');

		return {
			departureDate: getDateYMD(minDate),
			preOffset: diff,
			postOffset: offset - diff,
		};
	}

	return {
		departureDate: departureDate,
		preOffset: 0,
		postOffset: offset,
	};
}
