import { type FC } from 'react';

import { Drawer, type DrawerProps } from '@vakantiesnl/components/src/atoms/Drawer';
import { Modal, type ModalProps } from '@vakantiesnl/components/src/atoms/Modal';

import { useBreakpoints } from '../../utils/useBreakpoints';

export type ModalOrDrawerProps = {
	onClose?: VoidFunction;
} & DrawerProps &
	ModalProps;

export const ModalOrDrawer: FC<ModalOrDrawerProps> = ({ onClose, ...props }) => {
	const { isDesktop } = useBreakpoints();

	if (!isDesktop) {
		return <Drawer {...props} onDrawerClose={onClose} />;
	}

	return <Modal {...props} onModalClose={onClose} />;
};
