import { type FC, useCallback } from 'react';

import { Link } from '@vakantiesnl/components/src/__LEGACY__/atoms/Link';
import { type GTM, type ContentfulLink } from '@vakantiesnl/types';

import css from './Navigation.module.css';

type Props = {
	links: ContentfulLink[];
	pageType: GTM.GTMPageType;
};

type RenderLinkProps = ContentfulLink & {
	pageType: GTM.GTMPageType;
};

const RenderLink: FC<RenderLinkProps> = ({ link, title, pageType }) => {
	const trackNavigationAction = useCallback((): void => {
		import('@vakantiesnl/services/src/gtm/common').then(({ track }) =>
			import('@vakantiesnl/services/src/gtm/navigation').then(({ footerNavigation }) => {
				track(footerNavigation(pageType, title, link));
			}),
		);
	}, [pageType, title, link]);

	return (
		<li>
			<Link href={link} onClick={trackNavigationAction} className={'trail-hover'}>
				{title}
			</Link>
		</li>
	);
};

export const Navigation: FC<Props> = ({ links, pageType }) => (
	<div className={css.navigation}>
		<ul>
			{links.map((props, key) => (
				<RenderLink {...props} key={key} pageType={pageType} />
			))}
		</ul>
	</div>
);
