import { type FC, type ReactNode } from 'react';

import classNames from 'classnames';

import css from './Content.module.css';

export type ContentProps = {
	children: ReactNode;
	className?: string;
	variant?: 'space-between' | 'full-width';
	flexDirection?: 'row' | 'column';
};

const Content: FC<ContentProps> = ({ children, className, variant, flexDirection }) => {
	let classes = '';
	if (flexDirection) {
		classes += ' ' + css[flexDirection];
	}
	const variantClass =
		(variant === 'space-between' && css.withSpacing) || (variant === 'full-width' && css.fullWidth);
	return <div className={classNames(variantClass || css.container, classes, className)}>{children}</div>;
};

export { Content };
