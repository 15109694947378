import { type FC, type ReactNode } from 'react';

import Cookie from '@vakantiesnl/components/src/blocks/cookie';
import { useSeoContext } from '@vakantiesnl/services/src/context/useSeoContext';
import { type Seo } from '@vakantiesnl/types';

import Footer from '../blocks/footer/Footer';
import Header from '../blocks/header/Header';
import Layout from '../components/templates/Layout/Layout';
import { type LayoutRenderProps } from '../pages/_app';

export type Props = LayoutRenderProps & {
	children: ReactNode;
	pageProps: {
		seo: Seo;
		errorCode?: 404;
		contentfulId?: string;
	};
};

const DefaultLayout: FC<Props> = ({ children, pageProps: { errorCode }, cookieConsent }) => {
	const { seo } = useSeoContext();

	return errorCode ? (
		<>{children}</>
	) : (
		<Layout seo={seo} Header={Header} Footer={Footer} Modal={!cookieConsent ? Cookie : undefined}>
			{children}
		</Layout>
	);
};

export default DefaultLayout;
