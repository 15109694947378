import {
	type SVGProps,
	type FC,
	type DetailedHTMLProps,
	type ButtonHTMLAttributes,
	type PropsWithChildren,
} from 'react';

import { Icon, type IconTypes } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';
import { Text } from '@vakantiesnl/components/src/__LEGACY__/atoms/Text';
import { type MicroCopy } from '@vakantiesnl/types';

import useStyles from './Button.styles';

export type ButtonProps = {
	/** Text to show in the button */
	title?: string;
	/** Variant of the button to render */
	variant:
		| 'primary'
		| 'secondary'
		| 'secondary-small'
		| 'small'
		| 'default'
		| 'price'
		| 'tiny'
		| 'link'
		| 'capital-link'
		| 'outlined'
		| 'round'
		| 'action'
		| 'action-tiny'
		| 'round-wide';
	/** Data to show inside the bubble */
	bubbleValue?: number;
	/** Disable the button so it can't be clicked */
	disabled?: boolean;
	/** Icon to show in front of the title of the button */
	icon?: IconTypes;
	/** Icon to show after the title of the button */
	iconRight?: IconTypes;
	/** Wether to show a large icon */
	largeIcon?: boolean;
	/** Link to pass through the button */
	link?: string;
	/** Custom SVG element */
	customIcon?: FC<SVGProps<SVGSVGElement> & { title?: string }>;
	/** Custom icon styling */
	customIconStyle?: string;
	/** Rel attribute for the anchor tag around the button */
	rel?: string;
	/** Object containing all the microcopies */
	microCopies?: MicroCopy;
	/** Boolean whether to show the bubble */
	showBubble?: boolean;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

// Using the any type here because I dont know how to type a custom makeStyles file
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getVariantStyle = (variant: string, styles: any): string => {
	switch (variant) {
		case 'primary':
			return `${styles.large} ${styles.primary}`;
		case 'secondary':
			return `${styles.large} ${styles.secondary}`;
		case 'secondary-small':
			return `${styles.small} ${styles.secondary} ${styles['secondary-small']}`;
		case 'small':
			return `${styles.small} ${styles.primary}`;
		case 'tiny':
			return `${styles.tiny}`;
		case 'default':
			return `${styles.medium} ${styles.default}`;
		case 'price':
			return `${styles.large} ${styles.primary} ${styles.price}`;
		case 'link':
			return `${styles.link}`;
		case 'capital-link':
			return `${styles['capital-link']}`;
		case 'outlined':
			return `${styles.outlined}`;
		case 'round':
			return `${styles.round}`;
		case 'action':
			return `${styles.action}`;
		case 'action-tiny':
			return `${styles['action-tiny']}`;
		case 'round-wide':
			return `${styles.roundWide}`;
		default:
			return `${styles.large} ${styles.primary}`;
	}
};

const ButtonText: FC<PropsWithChildren<Pick<ButtonProps, 'title' | 'variant' | 'microCopies'>>> = ({
	title,
	variant,
	microCopies,
	children,
}) => {
	const { classes: styles } = useStyles();
	return variant === 'price' ? (
		<span>
			{microCopies ? microCopies['common.startingPriceShort'] : ''}
			<span className={styles.priceTag}>€ {title}</span>
			{microCopies ? microCopies['common.perPersonShort'] : ''}
		</span>
	) : (
		<span>
			{title} {children}
		</span>
	);
};

const ButtonContent: FC<ButtonProps> = ({
	className,
	variant,
	title,
	bubbleValue,
	disabled,
	icon,
	iconRight,
	largeIcon,
	microCopies,
	showBubble,
	customIcon,
	customIconStyle,
	children,
	...rest
}) => {
	const { classes: styles, cx } = useStyles();
	return (
		<button
			{...rest}
			className={cx(
				styles.button,
				getVariantStyle(variant, styles),
				className,
				icon || (iconRight && styles.hasIcon),
			)}
			disabled={disabled}
		>
			{icon && <Icon className={cx(styles.icon, { [styles.largeIcon]: largeIcon })} type={icon} />}
			{customIcon && <Icon className={customIconStyle} symbol={customIcon} />}
			{title && (
				<ButtonText variant={variant} title={title} microCopies={microCopies}>
					{showBubble && <span className={cx('filtersCount', styles.filtersCounter)}>{bubbleValue}</span>}
				</ButtonText>
			)}
			{iconRight && <Icon className={cx(styles.iconRight, { [styles.largeIcon]: largeIcon })} type={iconRight} />}
			{children}
		</button>
	);
};

export const Button: FC<Omit<ButtonProps, 'color'>> = ({ link, rel, ...rest }) => {
	const { classes: styles } = useStyles();
	return link ? (
		<Text as={'a'} link={link} rel={rel} customClassName={styles.buttonLink}>
			<ButtonContent {...rest} />
		</Text>
	) : (
		<ButtonContent {...rest} />
	);
};

Button.displayName = 'Button';
