import { type FC } from 'react';

import { Icon } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';

import useStyles from './USP.style';

export type USPProps = {
	/** Title of the USP to show */
	title: string | JSX.Element;
	/** Subtitle of the USP to show */
	subtitle?: string;
	/** Variant of the USP to show */
	variant?: 'header' | 'footer' | 'primary';
	/** Classname to pass through to the element */
	className?: string;
};

const FooterUSP: FC<USPProps> = ({ title, subtitle }) => {
	const { classes, cx } = useStyles();
	return (
		<div className={cx(classes.usp, classes.variantFooter)}>
			<Icon type="vaknlCheck" className={classes.icon} />
			<div className={classes.uspContent}>
				<div className={classes.title} data-id="title">
					{title}
				</div>
				{subtitle && <div className={classes.subTitle}>{subtitle}</div>}
			</div>
		</div>
	);
};

type HeaderUSPProps = Omit<USPProps, 'variant' | 'subtitle'>;
const HeaderUSP: FC<HeaderUSPProps> = ({ title, className }) => {
	const { classes, cx } = useStyles();
	return (
		<div className={cx(classes.usp, classes.variantHeader, className)}>
			<Icon type="vaknlCheck" className={classes.icon} />
			<div className={classes.title} data-id="title">
				{title}
			</div>
		</div>
	);
};

type PrimaryUSPProps = Omit<USPProps, 'variant'>;
const PrimaryUSP: FC<PrimaryUSPProps> = ({ title, className }) => {
	const { classes, cx } = useStyles();
	return (
		<div className={cx(classes.usp, classes.variantPrimary, className)}>
			<Icon type="vaknlCheck" className={classes.icon} />
			<div className={classes.title} data-id="title">
				{title}
			</div>
		</div>
	);
};

const USP: FC<USPProps> = ({ variant, ...rest }) => {
	const variants = {
		header: <HeaderUSP {...rest} />,
		footer: <FooterUSP {...rest} />,
		primary: <PrimaryUSP {...rest} />,
	};

	if (variant && variants[variant]) {
		return variants[variant];
	} else {
		return variants.footer;
	}
};

export { USP };
