import { type Geo } from '@vakantiesnl/types';

export const mapDestinationCountryResponse = (response: Geo.RawCountryResponse): Geo.MappedDestinationView[] => {
	return [
		...response.countries.popular.map(mapCountry({ isDisabled: false, isPopular: true })),
		...response.countries.countries.map(mapCountry({ isDisabled: false, isPopular: false })),
		...response.countries.blacklisted.map(mapCountry({ isDisabled: true, isPopular: false })),
	];
};

export const mapDestinationRegionsResponse = (response: Geo.RawRegionsResponse[]): Geo.MappedDestinationViewBySlug => {
	const list: Geo.MappedDestinationViewBySlug = {};

	response
		.flatMap((res) => res.regions)
		.forEach((region) => {
			list[region.country_slug] = list[region.country_slug] || [];
			list[region.country_slug].push({
				id: region.region_id,
				name: region.region,
				slug: region.slug,
				isDisabled: !region.is_enabled,
				fullEntitySlug: `/${region.country_slug}/${region.slug}`,
				entityId: String(region.entity_id),
			});
		});

	return sortDestinationList(list);
};

export const mapDestinationCityResponses = (
	mappedCityResponses: Geo.MappedDestinationViewBySlug[],
): Geo.MappedDestinationViewBySlug => {
	// Combine all city response objects (where key is the region and value the cities of that region) to one big object
	return mappedCityResponses.reduce((prev, next) => {
		return { ...next, ...prev };
	}, {});
};

export const mapDestinationCityResponse = (
	regionSlug: string,
	response: Geo.RawCitiesResponse,
): Geo.MappedDestinationViewBySlug => {
	const list: Geo.MappedDestinationViewBySlug = {};

	response.cities.forEach((city) => {
		list[regionSlug] = list[regionSlug] || [];
		list[regionSlug].push(mapSingleDestinationCity(city));
	});

	return sortDestinationList(list);
};

export const mapSingleDestinationCity = (city: Geo.RawCityView): Geo.MappedDestinationView => ({
	id: city.city_id,
	name: city.city,
	slug: city.slug,
	isDisabled: !city.is_enabled,
	fullEntitySlug: `/${city.country_slug}/${city.region_slug}/${city.slug}`,
	entityId: String(city.entity_id),
});

const TOP_DESTINATION_COUNT = 5;

const sortDestinationList = (list: Geo.MappedDestinationViewBySlug): Geo.MappedDestinationViewBySlug => {
	// Sort items alphabetically only after the first top destinations
	Object.keys(list).forEach((key) => {
		const innerList = list[key];

		list[key] = innerList
			.slice(0, TOP_DESTINATION_COUNT)
			.concat(
				innerList
					.slice(TOP_DESTINATION_COUNT)
					.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
			);
	});

	return list;
};

type MapCountryOptions = {
	isDisabled: boolean;
	isPopular: boolean;
};

const mapCountry =
	(options: MapCountryOptions) =>
	(country: Geo.RawCountryView): Geo.MappedDestinationView => ({
		id: country.country_code,
		name: country.name,
		slug: country.slug,
		fullEntitySlug: country.slug,
		entityId: String(country.entity_id),
		isDisabled: options.isDisabled,
		isPopular: options.isPopular,
	});
