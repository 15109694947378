import { addBrandPrefix } from '@vakantiesnl/services/src/stores/brandPrefix';
import { type StoreApi, createStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { initialFilterState } from './config';
import { getClearedFilters, getSelectedFilterCount } from './filterStoreHelpers';
import { type FilterActions, type FilterState } from './interfaces';

export type FilterStoreState = FilterActions & {
	filters: FilterState;
};

export type FilterStore = StoreApi<FilterStoreState>;

export function createFilterStore(updatedState: Partial<FilterState>): FilterStore {
	return createStore<FilterStoreState>()(
		devtools(
			(set, get) => ({
				filters: {
					...initialFilterState,
					...updatedState,
				},

				getSelectedFilterCount(): number {
					return getSelectedFilterCount(get().filters);
				},

				setFilterDepartureDate(departureDate): void {
					set(
						(s) => ({
							filters: {
								...s.filters,
								departureDate,
								page: 1,
							},
						}),
						undefined,
						'setFilterDepartureDate',
					);
				},

				setPriceRangePartFilter(type, value): void {
					set(
						(s) => ({ filters: { ...s.filters, [type]: value, page: 1 } }),
						undefined,
						'setPriceRangePartFilter',
					);
				},

				setFilterFlexibleDepartureDate(flexibleDepartureDate): void {
					set(
						(s) => ({ filters: { ...s.filters, flexibleDepartureDate, page: 1 } }),
						undefined,
						'setFilterFlexibleDepartureDate',
					);
				},

				setAccoTypeFilter(accoType): void {
					set((s) => ({ filters: { ...s.filters, accoType, page: 1 } }), undefined, 'setAccoTypeFilter');
				},

				setAwardsFilter(awards): void {
					set((s) => ({ filters: { ...s.filters, awards, page: 1 } }), undefined, 'setAwardsFilter');
				},

				setStarsFilter(stars): void {
					set((s) => ({ filters: { ...s.filters, stars, page: 1 } }), undefined, 'setStarsFilter');
				},

				setDistancesFilter(distances): void {
					set((s) => ({ filters: { ...s.filters, distances, page: 1 } }), undefined, 'setDistancesFilter');
				},

				setRatingFilter(rating): void {
					set((s) => ({ filters: { ...s.filters, rating, page: 1 } }), undefined, 'setRatingFilter');
				},

				setFacilitiesFilter(facilities): void {
					set((s) => ({ filters: { ...s.filters, facilities, page: 1 } }), undefined, 'setFacilitiesFilter');
				},

				setMealplansFilter(mealplans): void {
					set((s) => ({ filters: { ...s.filters, mealplans, page: 1 } }), undefined, 'setMealplansFilter');
				},

				setTransportFilter(transport): void {
					set(
						(s) => ({
							filters: {
								...s.filters,
								transport,
								page: 1,
								sort: s.filters.sort,
							},
						}),
						undefined,
						'setTransportFilter',
					);
				},

				setThemesFilter(themes): void {
					set(
						(s) => ({
							filters: {
								...s.filters,
								themes,
								page: 1,
							},
						}),
						undefined,
						'setThemesFilter',
					);
				},

				setDepartureAirportFilter(departureAirport): void {
					set(
						(s) => ({ filters: { ...s.filters, departureAirport, page: 1 } }),
						undefined,
						'setDepartureAirportFilter',
					);
				},

				setGeoFilter({ countries, regions, cities }): void {
					set(
						(s) => ({
							filters: {
								...s.filters,
								countries,
								regions,
								cities,
								countrySlugs: countries.map((c) => c.slug),
								regionSlugs: regions.map((c) => c.slug),
								citySlugs: cities.map((c) => c.slug),
								page: 1,
							},
						}),
						undefined,
						'setGeoFilter',
					);
				},

				setSort(sort): void {
					set((s) => ({ filters: { ...s.filters, sort, page: 1 } }), undefined, 'setSort');
				},

				removeFilter(type, value): void {
					const currentFilterValue = get().filters[type];
					const page = 1;

					if (Array.isArray(currentFilterValue) && value) {
						set(
							(s) => ({
								filters: { ...s.filters, page, [type]: currentFilterValue.filter((r) => r !== value) },
							}),
							undefined,
							'removeFilter',
						);
					} else if (typeof currentFilterValue === 'string') {
						set(
							(s) => ({ filters: { ...s.filters, page, [type]: initialFilterState[type] } }),
							undefined,
							'removeFilter',
						);
					} else {
						set((s) => ({ filters: { ...s.filters, page } }), undefined, 'removeFilter');
					}
				},

				clearFilters(): void {
					const page = initialFilterState.page;
					set((s) => ({ filters: { ...getClearedFilters(s.filters), page } }), undefined, 'clearFilters');
				},

				setPage(page: number): void {
					set((s) => ({ filters: { ...s.filters, page } }), undefined, 'setPage');
				},

				setInitState(filters): void {
					set(() => ({ filters }), undefined, 'setInitState');
				},

				setChainsFilter(chains): void {
					set(
						(s) => ({ filters: { ...s.filters, chains, chainsSlugs: chains.map((chain) => chain.slug) } }),
						undefined,
						'setChainsFilter',
					);
				},

				setTourOperatorsFilter(tourOperators): void {
					set(
						(s) => ({
							filters: {
								...s.filters,
								tourOperators,
								tourOperatorsSlugs: tourOperators.map((tourOperator) => tourOperator.slug),
							},
						}),
						undefined,
						'setTourOperatorsFilter',
					);
				},
			}),
			{
				name: addBrandPrefix('FilterStore'),
			},
		),
	);
}
