import { useMemo } from 'react';

import { addBrandPrefix } from '@vakantiesnl/services/src/stores/brandPrefix';
import { type AccommodationOnlyOffer, type FullAccommodationItem, type OfferItem } from '@vakantiesnl/types/src/search';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type OfferStore = {
	/** Used by package accommodations */
	departureDateOffer: OfferItem | undefined;
	flightsOffer: OfferItem | undefined;
	roomTypeOffer: OfferItem | undefined;
	mealplanOffer: OfferItem | undefined;

	setDepartureDateOffer: (offer: OfferItem | undefined) => void;
	setFlightsOffer: (offer: OfferItem | undefined) => void;
	setRoomTypeOffer: (offer: OfferItem | undefined) => void;
	setMealplanOffer: (offer: OfferItem | undefined) => void;

	/** Used by acco only accommodations  */
	accoOnlyDepartureDateOffer: AccommodationOnlyOffer | undefined;
	accoOnlyRoomTypeOffer: AccommodationOnlyOffer | undefined;

	setAccoOnlyDepartureDateOffer: (offer: AccommodationOnlyOffer | undefined) => void;
	setAccoOnlyRoomTypeOffer: (offer: AccommodationOnlyOffer | undefined) => void;

	/** Shared */
	// We need this loading state also here, because this store and the react query hook that trigger updates in this store are
	// not updated 100% in sync, which can cause strange UI jumps in certain cases (f.e. price block loading state).
	isDepartureDateLoading: boolean;

	totalDepartureDateOffers: number;
	setTotalDepartureDateOffers: (value: number) => void;

	setIsDepartureDateLoading: (isLoading: boolean) => void;
	clearOffers: () => void;
};

export const useAccoOfferStore = create<OfferStore>()(
	devtools(
		(set) => ({
			departureDateOffer: undefined,
			flightsOffer: undefined,
			roomTypeOffer: undefined,
			mealplanOffer: undefined,

			accoOnlyDepartureDateOffer: undefined,
			accoOnlyRoomTypeOffer: undefined,

			isDepartureDateLoading: true,

			totalDepartureDateOffers: 0,

			setTotalDepartureDateOffers: (value) =>
				set(
					(state) => ({
						...state,
						totalDepartureDateOffers: value,
					}),
					undefined,
					'setTotalDepartureDateOffers',
				),

			setIsDepartureDateLoading: (isDepartureDateLoading): void =>
				set(
					(state) => ({
						...state,
						isDepartureDateLoading,
					}),
					undefined,
					'setIsDepartureDateLoading',
				),

			setDepartureDateOffer: (departureDateOffer): void => {
				set(
					(state) => ({
						...state,
						departureDateOffer,
						flightsOffer: undefined,
						roomTypeOffer: undefined,
						mealplanOffer: undefined,
					}),
					undefined,
					'setDepartureDateOffer',
				);
			},

			setFlightsOffer: (flightsOffer): void =>
				set(
					(state) => ({
						...state,
						flightsOffer,
						roomTypeOffer: undefined,
						mealplanOffer: undefined,
					}),
					undefined,
					'setFlightsOffer',
				),

			setRoomTypeOffer: (roomTypeOffer): void =>
				set(
					(state) => ({
						...state,
						roomTypeOffer,
						mealplanOffer: undefined,
					}),
					undefined,
					'setRoomTypeOffer',
				),

			setMealplanOffer: (mealplanOffer): void =>
				set(
					(state) => ({
						...state,
						mealplanOffer,
					}),
					undefined,
					'setMealplanOffer',
				),

			setAccoOnlyDepartureDateOffer: (accoOnlyDepartureDateOffer): void =>
				set(
					(state) => ({
						...state,
						accoOnlyDepartureDateOffer,
						accoOnlyRoomTypeOffer: undefined,
					}),
					undefined,
					'setAccoOnlyDepartureDateOffer',
				),

			setAccoOnlyRoomTypeOffer: (accoOnlyAccoOffer): void =>
				set(
					(state) => ({
						...state,
						accoOnlyRoomTypeOffer: accoOnlyAccoOffer,
					}),
					undefined,
					'setAccoOnlyRoomTypeOffer',
				),

			clearOffers: (): void =>
				set(
					() => ({
						departureDateOffer: undefined,
						flightsOffer: undefined,
						roomTypeOffer: undefined,
						mealplanOffer: undefined,
						accoOnlyDepartureDateOffer: undefined,
						accoOnlyRoomTypeOffer: undefined,
					}),
					undefined,
					'clearOffers',
				),
		}),
		{
			name: addBrandPrefix('AccoStore'),
		},
	),
);

export function useGetCheapestOffer(
	accommodation: FullAccommodationItem,
): OfferItem | AccommodationOnlyOffer | undefined {
	const packageOffer = useAccoOfferStore((s) => s.departureDateOffer);
	const accoOnlyOffer = useAccoOfferStore((s) => s.accoOnlyDepartureDateOffer);

	const cheapestOffer = useMemo(() => {
		return accommodation.isBookableQenner ? accoOnlyOffer : packageOffer;
	}, [packageOffer, accoOnlyOffer, accommodation]);

	return cheapestOffer;
}

export function useGetFinalOffer(accommodation: FullAccommodationItem): OfferItem | AccommodationOnlyOffer | undefined {
	const packageOffer = useAccoOfferStore((s) => s.mealplanOffer);
	const accoOnlyOffer = useAccoOfferStore((s) => s.accoOnlyRoomTypeOffer);

	const finalOffer = useMemo(() => {
		return accommodation.isBookableQenner ? accoOnlyOffer : packageOffer;
	}, [packageOffer, accoOnlyOffer, accommodation]);

	return finalOffer;
}
