import { type Document } from '@contentful/rich-text-types';

import { getZvrEntryCollection } from '..';
import { errorHandler, parseErrorToReport } from '../../util/errorHandling';

export type Airline = {
	airlineName: string;
	airlineCode: string;
	carryOn?: Document;
	carryOnException?: Document;
};

type Options = {
	airlineCode: string;
};

export async function fetchAirlineEntree({ airlineCode }: Options): Promise<Airline | null> {
	try {
		const entries = await getZvrEntryCollection<Airline>('airline', {
			include: 3,
			limit: 1,
			'fields.airlineCode': airlineCode,
		});

		if (entries.items.length === 0) {
			throw new Error(`Item not found`);
		}

		return entries.items[0].fields;
	} catch (err) {
		const message = parseErrorToReport(err, `Error retrieving airline ${airlineCode}`);

		// eslint-disable-next-line no-console
		console.error(message);
		errorHandler.report(message);
		return null;
	}
}
