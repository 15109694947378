import { type DecodedSsoToken } from '@vakantiesnl/types/src/cookies';
import { jwtDecode } from 'jwt-decode';

export const SSO_TOKEN_COOKIE = 'ssoToken';

export const checkIfSsoTokenValid = (ssoToken: string): boolean => {
	const decodedToken: DecodedSsoToken = decodeSsoToken(ssoToken);
	const tokenExpirationTimestamp = decodedToken.exp * 1000; // property is set in seconds, so convert to milliseconds
	const currentTimestamp = Date.now();
	return currentTimestamp < tokenExpirationTimestamp;
};

export const getValueFromSsoToken = <T extends keyof DecodedSsoToken>(
	ssoToken: string,
	fieldName: T,
): DecodedSsoToken[T] => {
	const decodedToken: DecodedSsoToken = decodeSsoToken(ssoToken);
	return decodedToken[fieldName];
};

export const decodeSsoToken = (ssoToken: string): DecodedSsoToken => jwtDecode(ssoToken);
