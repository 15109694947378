import { type FC, type ReactNode, useMemo } from 'react';

import FormHelperText from '@mui/material/FormHelperText';

import { useCustomStyles } from './FormHelperText.style';

export type FormHelperTextProps = {
	children: ReactNode;
	className?: string;
	error?: boolean;
};

const FormHelperTextComponent: FC<FormHelperTextProps> = ({ children, className, error = false }) => {
	const { classes } = useCustomStyles();

	const formClasses = useMemo(() => ({ root: classes.root, error: classes.error }), [classes]);

	return (
		<FormHelperText classes={formClasses} className={className} error={error}>
			{children}
		</FormHelperText>
	);
};

export { FormHelperTextComponent };
