import { useCallback, useState, type ChangeEvent } from 'react';

import { type NextComponentType } from 'next';

import { Cookie as CookieComponent } from '@vakantiesnl/components/src/molecules/Cookie';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { getConsentData } from '@vakantiesnl/services/src/gtm/util';
import { useRichText } from '@vakantiesnl/services/src/hooks/contentful';
import { type Consent } from '@vakantiesnl/types/src/cookies';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

const CONSENT_EXPIRE_DAYS = 364;

const storeCookieStatus = (cookieStatus: boolean, setCookieConsent: (arg0: boolean) => void): void => {
	const timestamp = dayjs().toISOString();
	const expired = dayjs(timestamp).add(CONSENT_EXPIRE_DAYS, 'day').toISOString();
	const consentObj: Consent = {
		consent_given: cookieStatus,
		timestamp,
		expired,
	};

	Cookies.set('cookie_consent', JSON.stringify(consentObj), { expires: CONSENT_EXPIRE_DAYS });
	setCookieConsent(true);
	import('@vakantiesnl/services/src/gtm/common').then(({ trackCookieConsent }) => trackCookieConsent(consentObj));
};

const richTextElementsFilter = {
	['fields.key']: ['cookiebanner.description'],
};

const Cookie: NextComponentType<Record<string, unknown>> = () => {
	const microCopies = useMicroCopyContext();
	const [cookieStatus, setCookieStatus] = useState<boolean>(true);
	const { consent } = getConsentData();
	const [cookieConsentSet, setCookieConsent] = useState<boolean>(!!consent);
	const richTextElementsData = useRichText(richTextElementsFilter);

	const handleSubmit = useCallback(
		(): void => storeCookieStatus(cookieStatus, setCookieConsent),
		[cookieStatus, setCookieConsent],
	);

	const handleConsentChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setCookieStatus(e.target.checked);
	}, []);

	return !cookieConsentSet ? (
		<CookieComponent
			handleConsentChange={handleConsentChange}
			handleSubmit={handleSubmit}
			richContent={richTextElementsData ? richTextElementsData['cookiebanner.description'] : undefined}
			microCopies={microCopies}
			consent={cookieStatus}
		/>
	) : null;
};

export default Cookie;
