import { message } from '@vakantiesnl/components/src/utils/message';
import { getCampaignConfig } from '@vakantiesnl/services/src/hooks/useSearchUrlBuilder';
import { getMicroCopy } from '@vakantiesnl/services/src/microcopy';
import { type FilterState } from '@vakantiesnl/services/src/stores/filtersStore';
import { type MicroCopy, type SearchTemplate } from '@vakantiesnl/types';
import { type MappedDestinationView } from '@vakantiesnl/types/src/geo';

export const formatFallbackTemplate = (
	brandedSearchTemplate: SearchTemplate,
	geoEntity: MappedDestinationView,
	contentfulSlug: string,
	filters: FilterState,
	microCopies: MicroCopy,
): SearchTemplate => {
	const canonical = `${brandedSearchTemplate.seo.canonical}${contentfulSlug}`;

	const accoType = filters.accoType[0] ? filters.accoType[0] : '';
	const accotype_plural = accoType ? getMicroCopy(`accoType.${accoType}.plural`, microCopies) : '';
	const accotype_plural_lowercase = accotype_plural.toLowerCase();
	const accotype_singular = accoType ? getMicroCopy(`accoType.${accoType}`, microCopies) : '';
	const accotype_singular_lowercase = accotype_singular.toLowerCase();

	const theme = filters.themes[0];
	const theme_plural = theme ? getMicroCopy(`theme_label.${theme}.plural`, microCopies) : '';
	const theme_plural_lowercase = theme_plural.toLowerCase();
	const theme_singular = theme ? getMicroCopy(`theme_label.${theme}`, microCopies) : '';
	const theme_singular_lowercase = theme_singular.toLowerCase();

	const campaign = getCampaignConfig(filters);

	const campaign_plural = campaign?.plural || '';
	const campaign_plural_lowercase = campaign_plural.toLowerCase();
	const campaign_singular = campaign?.singular || '';
	const campaign_singular_lowercase = campaign_singular.toLowerCase();

	brandedSearchTemplate.seo = {
		...brandedSearchTemplate.seo,
		title: message(brandedSearchTemplate.seo.title, {
			accotype_plural,
			accotype_plural_lowercase,
			accotype_singular,
			accotype_singular_lowercase,
			theme_plural,
			theme_plural_lowercase,
			theme_singular,
			theme_singular_lowercase,
			campaign_plural,
			campaign_plural_lowercase,
			campaign_singular,
			campaign_singular_lowercase,
			geo: geoEntity.name,
		}),
		description: message(brandedSearchTemplate.seo.description, {
			accotype_plural,
			accotype_plural_lowercase,
			accotype_singular,
			accotype_singular_lowercase,
			theme_plural,
			theme_plural_lowercase,
			theme_singular,
			theme_singular_lowercase,
			campaign_plural,
			campaign_plural_lowercase,
			campaign_singular,
			campaign_singular_lowercase,
			geo: geoEntity.name,
		}),
		canonical,
		slug: contentfulSlug,
	};

	//** Set page title */
	brandedSearchTemplate.title = message(brandedSearchTemplate.title, {
		accotype_plural,
		accotype_plural_lowercase,
		accotype_singular,
		accotype_singular_lowercase,
		theme_plural,
		theme_plural_lowercase,
		theme_singular,
		theme_singular_lowercase,
		campaign_plural,
		campaign_plural_lowercase,
		campaign_singular,
		campaign_singular_lowercase,
		geo: geoEntity.name,
	});

	return brandedSearchTemplate;
};
