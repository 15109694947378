import { type FC, useMemo } from 'react';

import InputLabel, { type InputLabelProps } from '@mui/material/InputLabel';
import { useBreakpoints } from '@vakantiesnl/components/src/utils/useBreakpoints';

import { useCustomStyles } from './InputLabel.style';
import { Typography } from '../Typography';

export type InputLabelComponentProps = {
	children: InputLabelProps['children'];
	htmlFor?: InputLabelProps['htmlFor'];
	required?: InputLabelProps['required'];
	className?: InputLabelProps['className'];
};

const InputLabelComponent: FC<InputLabelComponentProps> = ({ children, className, htmlFor, required }) => {
	const { classes } = useCustomStyles();
	const { isMobile } = useBreakpoints();

	const inputLabelClasses = useMemo(() => ({ root: classes.root }), [classes]);

	return (
		<InputLabel htmlFor={htmlFor} className={className} classes={inputLabelClasses}>
			<Typography variant={!isMobile ? 'labelMd' : 'labelSm'} as="span">
				{children}
				{required && <span className={classes.required}>*</span>}
			</Typography>
		</InputLabel>
	);
};

export { InputLabelComponent };
