/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { type Search } from '@vakantiesnl/types/src';
import Cookies from 'universal-cookie';
import { createStore, type StoreApi } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';

import { GLOBAL_FILTERS_STORAGE_NAME } from './constants';
import { DEFAULT_PARTY } from '../../util/partyUtils';
import { addBrandPrefix } from '../brandPrefix';
import { type GlobalFiltersState } from './interfaces';

type GlobalFilterStateActions = {
	// actionLocation can be passed to log in the devtools where the action is fired from
	setParty: (party: Search.PartyComposition[], actionLocation?: string) => void;
	setDurations: (durations: string | number[] | null, actionLocation?: string) => void;
};

export type GlobalFilterStoreState = GlobalFilterStateActions & {
	filters: GlobalFiltersState;
};

export type GlobalFiltersStore = StoreApi<GlobalFilterStoreState>;

export function createGlobalFiltersStore(
	initialParty: GlobalFiltersState['party'] | undefined,
	initialDuration: GlobalFiltersState['durations'],
) {
	return createStore<GlobalFilterStoreState>()(
		persist(
			devtools(
				(set) => ({
					filters: {
						party: initialParty || DEFAULT_PARTY,
						durations: initialDuration || null,
					},

					setParty: (party, actionLocation) =>
						set(
							(s) => ({ filters: { ...s.filters, party } }),
							undefined,
							`setParty${actionLocation ? ' : ' + actionLocation : ''}`,
						),

					setDurations: (durations, actionLocation) =>
						set(
							(s) => ({ filters: { ...s.filters, durations } }),
							undefined,
							`setDurations${actionLocation ? ' : ' + actionLocation : ''}`,
						),
				}),
				{
					name: addBrandPrefix('GlobalFiltersStore'),
				},
			),
			{
				// Sync party to cookie on the client, so it can be accessed on the server
				name: GLOBAL_FILTERS_STORAGE_NAME,
				storage: createJSONStorage(() => ({
					getItem: (name) => new Cookies().get(name),
					setItem: (name, value) => new Cookies().set(name, value, { path: '/' }),
					removeItem: (name) => new Cookies().remove(name),
				})),
			},
		),
	);
}
