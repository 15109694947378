import { useContext } from 'react';

import { useStore } from 'zustand';

import { FilterStoreContext } from './filterContext';
import { type FilterStoreState } from './filtersStore';

export function useFilterStore<T>(selector: (state: FilterStoreState) => T): T {
	const store = useContext(FilterStoreContext);

	if (!store) throw new Error('Missing FilterStoreContext.Provider in the tree');

	return useStore(store, selector);
}
