import { useQuery } from '@tanstack/react-query';
import { LOGGED_IN_QUERY_KEY } from '@vakantiesnl/services/src/hooks/queries/constants';
import { createRequestByEntityId } from '@vakantiesnl/services/src/nbc';
import { mapFullAccommodation } from '@vakantiesnl/services/src/search/vaknl-mapper';
import {
	type FullUserBooking,
	type FullUserBookingWithAccoDetails,
	type RawFullUserBooking,
} from '@vakantiesnl/services/src/user';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { type Nbc, type Search } from '@vakantiesnl/types/src';

import { mapUserBooking } from '../../user/mapBookingDetails';

/* istanbul ignore file */
export function useGetUserBookingDetails(bookingNumber: number): {
	userBookingData: FullUserBookingWithAccoDetails | null;
	isLoadingUserBookingData: boolean;
	hasErrorUserBookingData: boolean;
} {
	const bookingDetailsQuery = useQuery({
		queryKey: [LOGGED_IN_QUERY_KEY, 'user-booking', bookingNumber],
		queryFn: () => fetchUserBookingDetails(bookingNumber),
	});

	const bookingDetails = bookingDetailsQuery.data;

	const nbcQuery = useQuery({
		queryKey: ['user-booking-nbc-data', bookingDetails?.entityId],
		queryFn: () => fetchAccommodationDetails(bookingDetails?.entityId),
		enabled: !!bookingDetails?.entityId,
	});

	const userBookingDataWithNbc: FullUserBookingWithAccoDetails | null =
		!!bookingDetails && !!nbcQuery.data ? { ...bookingDetails, accommodation: { ...nbcQuery.data } } : null;

	return {
		userBookingData: userBookingDataWithNbc,
		isLoadingUserBookingData: bookingDetailsQuery.isLoading || nbcQuery.isLoading,
		hasErrorUserBookingData: bookingDetailsQuery.isError || nbcQuery.isError,
	};
}

async function fetchUserBookingDetails(bookingNumber: number): Promise<FullUserBooking> {
	const data: RawFullUserBooking = await fetchHandler({
		fetchFn: () => fetch('/api/user/bookingDetails', { headers: { bookingNumber: bookingNumber.toString() } }),
		errorMessage: `Something went wrong during fetching the booking details for bookingNumber ${bookingNumber}`,
	});

	return mapUserBooking(data);
}

async function fetchAccommodationDetails(entityId?: string): Promise<Search.FullAccommodationItem | undefined> {
	if (entityId) {
		const rawNbc = await fetchHandler<Nbc.RawFullNbcItem>({
			fetchFn: () =>
				fetchVakNL({
					input: createRequestByEntityId(entityId, process.env.NEXT_PUBLIC_BRAND === 'ZVRNL', true),
				}),
			errorMessage: `Something went wrong during fetching accommodation details for entityId ${entityId}`,
		});

		if (rawNbc.accommodation) {
			return mapFullAccommodation({
				giata_id: rawNbc.accommodation.giata_id,
				name: rawNbc.accommodation.name,
				nbc: rawNbc,
			});
		}
	}
}
