import { type CompactUserBooking } from '@vakantiesnl/services/src/user';
import { isNotEmpty } from '@vakantiesnl/services/src/util/arrayUtils';
import { errorHandler } from '@vakantiesnl/services/src/util/errorHandling';

import { type AccommodationDetailsResponse, type UserBookingWithAccoDetails } from './interfaces';

export function mapBookingsWithAccoDetails(
	bookings: CompactUserBooking[],
	nbcData: AccommodationDetailsResponse | undefined,
): UserBookingWithAccoDetails[] {
	if (!nbcData) {
		return [];
	}

	// Filter out bookings that do not have matching accommodation details, which should generally not happen
	// If it does, than probably the nbc service has an error or the booking data is somehow out of sync
	return bookings
		.map((b) => {
			const accommodation = nbcData[b.entityId];

			if (!accommodation) {
				const message = `Could not find matching accommodation details for booking ${b.bookingNumber} with entityId ${b.entityId}`;
				errorHandler.report(message);
				// eslint-disable-next-line no-console
				console.error(message);
				return null;
			}

			return {
				...b,
				isAccoOnly: b.transportCode === 'EV',
				accommodation,
			};
		})
		.filter(isNotEmpty);
}
