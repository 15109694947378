import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

import type { DrawerProps } from './Drawer';

export const useCustomStyles = makeStyles<Pick<DrawerProps, 'direction' | 'isFullHeight'>>()(
	(theme, { direction, isFullHeight }) => ({
		drawer: {
			position: 'fixed',
			zIndex: theme.zIndex.floating,
			right: 0,
			bottom: direction === 'up' ? 0 : 'auto',
			top: isFullHeight ? theme.spacing.large : direction === 'down' ? 0 : 'auto',
			left: 0,
		},
		container: {
			backgroundColor: theme.modal.background.color,
			boxShadow: theme.modal.elevation,
			borderRadius:
				direction === 'up'
					? `${theme.modal.borderRadius} ${theme.modal.borderRadius} 0 0`
					: `0 0 ${theme.modal.borderRadius} ${theme.modal.borderRadius}`,
			maxHeight: `calc(100dvh - ${theme.spacing.large})`,
			height: isFullHeight ? '100%' : 'auto',
			overflow: 'hidden',
			display: 'flex',
			flexDirection: 'column',
		},
		contentContainer: {
			overflowY: 'auto',
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			backgroundColor: theme.modal.background.color,
			boxShadow: theme.modal.elevation,
			position: 'relative',
		},
		topBar: {
			display: 'flex',
			alignItems: 'center',
			position: 'sticky',
			top: 0,
			zIndex: theme.zIndex.lifted,
			backgroundColor: theme.modal.background.color,
			padding: theme.spacing.small,
			boxSizing: 'border-box',
			maxHeight: isFullHeight ? 'none' : `calc(2*${theme.spacing.xlarge})`,
		},
		title: {
			color: theme.modal.title.color,
			margin: `${theme.spacing.xsmall} 0`,
		},
		content: {
			boxSizing: 'border-box',
			minHeight: 0,
			padding: `0 ${theme.spacing.small}`,
			overflowY: 'auto',
			...(isFullHeight && {
				height: '100%',
				overflowY: 'visible',
				display: 'flex',
				flexDirection: 'column',
			}),
		},
		contentTop: {
			flex: 1,
			paddingBottom: theme.spacing.small,
		},
		submitButton: {
			paddingBottom: theme.spacing.small,
			display: 'flex',
			flexDirection: 'column',
		},
		observeElement: {
			width: 0,
			height: 0,
		},
		shadow: {
			boxShadow: theme.modal.elevation,
		},
		closeButton: {
			marginLeft: 'auto',
		},
		backButton: {
			marginRight: theme.spacing.xxsmall,
		},
	}),
);
