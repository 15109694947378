import { type FeatureToggle } from '@vakantiesnl/services/src/hooks/rest/feature-toggle';
import { useFeatures } from '@vakantiesnl/services/src/stores/applicationStore';

export const hasEnabledFeature = (featureToggles: FeatureToggle | null | undefined, featureName: string): boolean => {
	if (!featureToggles || !featureName) return false;

	if (featureToggles?.[featureName]?.isEnabled) return true;

	return false;
};

export const hasEnabledVariant = (
	featureToggles: FeatureToggle | null | undefined,
	featureName: string,
	variantName: string,
): boolean => {
	if (!featureToggles || !featureName || !variantName) return false;

	const currentVariant = featureToggles[featureName]?.variant;
	if (currentVariant?.enabled && currentVariant?.name === variantName) return true;

	return false;
};

export const useIsToggleEnabled = (name: string): boolean => {
	const features = useFeatures();

	return hasEnabledFeature(features, name);
};

export const getTogglePayloadValue = (
	featureToggles: FeatureToggle | null | undefined,
	featureName: string,
): string => {
	if (!featureToggles || !featureName) {
		return '';
	}

	const currentVariant = featureToggles[featureName]?.variant;

	return currentVariant?.payload?.value || '';
};
