import { type Chain, type TourOperator } from '@vakantiesnl/types/src';
import { type GeoLocation } from '@vakantiesnl/types/src/geo';
import { type SortingOrder } from '@vakantiesnl/types/src/search';

export const ALL_TRANSPORT_TYPES = ['OWN', 'INCLUDING_FLIGHT'] as const;
type TransportTuple = typeof ALL_TRANSPORT_TYPES;
export type TransportType = TransportTuple[number];

export type FilterState = {
	departureDate: string[] | null;
	priceMin: string | null;
	priceMax: string | null;
	flexibleDepartureDate: number | boolean;
	sort: SortingOrder;
	accoType: string[];
	facilities: string[];
	awards: string[];
	stars: string[];
	distances: string[];
	pool: string[];
	giata_ids: string[] | null;
	zooverAwards: string[];
	rating: string;
	size: number;
	page: number;
	mealplans: string[];
	departureAirport: string[];
	transport: TransportType;
	/**
	 * - Themes are the visible attributes, shown as selectable filters (they are still treated as themes in vakanties)
	 * - Attributes are the 'hidden' attributes, as they are not manually selectable, but only set via campaigns
	 * They are kept separate due to vakanties not having campaigns and because only the visible attributes are used for clean urls,
	 * so if they are kept separate it is easier to manage in the url builder logic.
	 */
	themes: string[];
	attributes: string[];
	countrySlugs: string[];
	regionSlugs: string[];
	citySlugs: string[];
	isDealsRoute: boolean;
	campaign: string;
	countries: GeoLocation[];
	regions: GeoLocation[];
	cities: GeoLocation[];
	chains: Chain[];
	chainsSlugs: string[];
	tourOperators: TourOperator[];
	tourOperatorsSlugs: string[];
};

export type RemoveFilterKey = keyof Omit<FilterState, 'countries' | 'regions' | 'cities'>;

export type FilterActions = {
	getSelectedFilterCount: () => number;
	setFilterDepartureDate: (date: FilterState['departureDate']) => void;
	setPriceRangePartFilter: (
		type: 'priceMin' | 'priceMax',
		value: FilterState['priceMin'] | FilterState['priceMax'],
	) => void;
	setFilterFlexibleDepartureDate: (isFlexible: number | boolean) => void;
	setAccoTypeFilter: (accoType: string[]) => void;
	setAwardsFilter: (awards: string[]) => void;
	setStarsFilter: (stars: string[]) => void;
	setDistancesFilter: (distances: string[]) => void;
	setRatingFilter: (rating: string) => void;
	setFacilitiesFilter: (facilities: string[]) => void;
	setMealplansFilter: (mealplans: string[]) => void;
	setTransportFilter: (transport: TransportType) => void;
	setThemesFilter: (themes: string[]) => void;
	setDepartureAirportFilter: (airports: string[]) => void;
	setSort: (sort: FilterState['sort']) => void;
	removeFilter: (filterType: RemoveFilterKey, filterValue?: string) => void;
	clearFilters: () => void;
	setPage: (page: number) => void;
	setInitState: (state: FilterState) => void;
	setGeoFilter: (geo: { countries: GeoLocation[]; regions: GeoLocation[]; cities: GeoLocation[] }) => void;
	setChainsFilter: (chains: Chain[]) => void;
	setTourOperatorsFilter: (chains: TourOperator[]) => void;
};
