import { type FC, memo, useCallback } from 'react';

import { Link } from '@vakantiesnl/components/src/__LEGACY__/atoms/Link';
import ImageComponent from '@vakantiesnl/image-component';
import { type GTM } from '@vakantiesnl/types';
import classNames from 'classnames';

import LogoSvg from './asset/logo.svg?url';
import MobileLogoSvg from './asset/mobile-logo.svg?url';
import css from './Logo.module.css';

export type LogoProps = {
	/** Whether the logo is in the toggled state */
	toggled: boolean;
	/** Classname passing through to the component */
	className?: string;
	/** Action to call when clicking the logo */
	closeMenu: VoidFunction;
	pageType: GTM.GTMPageType;
};

const Logo: FC<LogoProps> = ({ toggled, className, closeMenu, pageType }) => {
	const trackNavigationAction = useCallback((): void => {
		closeMenu();
		import('@vakantiesnl/services/src/gtm/common').then(({ track }) =>
			import('@vakantiesnl/services/src/gtm/navigation').then(({ headerNavigation }) => {
				track(headerNavigation(pageType, 'vakanties', '/'));
			}),
		);
	}, [closeMenu, pageType]);

	return (
		<Link
			href="/"
			className={classNames(css.logo, className)}
			rel=""
			onClick={trackNavigationAction}
			data-toggled={toggled && 'toggled'}
			aria-label="Logo"
		>
			<>
				<div className={css.logoContainer} data-id="logo">
					<ImageComponent useLoader={false} src={LogoSvg} priority fill={true} alt="logo" />
				</div>
				<div className={css.mobileLogoContainer} data-id="mobile-logo">
					<ImageComponent useLoader={false} src={MobileLogoSvg} priority fill={true} alt="mobile-logo" />
				</div>
			</>
		</Link>
	);
};

const memoizedLogo = memo(Logo);
export { memoizedLogo as Logo };
