import { useContext } from 'react';

import { type FeatureToggle } from '@vakantiesnl/services/src/hooks/rest/feature-toggle';
import { useStore } from 'zustand';

import { ApplicationStoreContext } from './applicationContext';
import { type ApplicationState } from './applicationStore';

export function useApplicationStore<T>(selector: (state: ApplicationState) => T): T {
	const store = useContext(ApplicationStoreContext);

	if (!store) throw new Error('Missing ApplicationStoreContext.Provider in the tree');

	return useStore(store, selector);
}

export function useFeatures(): FeatureToggle {
	try {
		return useApplicationStore((s) => s.features);
	} catch (error) {
		/** Returning an empty object in case Missing ApplicationStoreContext.Provider in the tree.
		 * In case other apps without zustand context are using a shared component where useFeatures is called.
		 */
		return {};
	}
}
