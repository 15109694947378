import { type FC } from 'react';

import { useStyles } from './HeaderTopBar.styles';

export type Props = {
	className?: string;
};

export const EmptyHeaderTopBar: FC<Props> = ({ className }) => {
	const { classes, cx } = useStyles();

	return <div className={cx(classes.emptyContainer, className)} data-cy="headerTopBar" />;
};
