import { type Geo } from '@vakantiesnl/types/src';

export const getCountriesQueryId = (): string[] => ['countries'];
export const getRegionsQueryId = (countrySlugs: string[]): string[] => ['regions', ...countrySlugs];
export const getCitiesQueryId = (regionSlugs: string[]): string[] => ['cities', ...regionSlugs];

// Filter based on whether country has accos or has been selected
export function filterCountries(
	countries: Geo.MappedDestinationView[],
	selectedCountrySlugs: string[],
	selectableCountrySlugs: string[],
): Geo.MappedDestinationView[] {
	return countries.filter((c) => selectableCountrySlugs.includes(c.slug) || selectedCountrySlugs.includes(c.slug));
}

// Filter based on whether region has accos or has been selected
export function filterRegions(
	regionsByCountry: Geo.MappedDestinationViewBySlug,
	selectedRegionSlugs: string[],
	selectableRegionSlugs: string[],
): Geo.MappedDestinationViewBySlug {
	const filteredRegions: Geo.MappedDestinationViewBySlug = {};

	Object.keys(regionsByCountry).forEach((country) => {
		filteredRegions[country] = regionsByCountry[country].filter(
			(r) => selectableRegionSlugs.includes(r.slug) || selectedRegionSlugs.includes(r.slug),
		);
	});

	return filteredRegions;
}
