import { type Search } from '@vakantiesnl/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { mapBookingCostsExtras, mapFundsExtras } from '../dynamic-booking';
import { mapAccoOnlyOffer, mapProductInfo } from '../hooks/queries/accoOnlyOffers/utils';

dayjs.extend(utc);

export const mapFlightSegments = (
	raw: Required<Search.RawFlight>['flight_segments'][0],
): Required<Search.Flight>['flightSegments'][0] => ({
	duration: raw.duration,
	stopOver: raw.stop_over ?? 0,
	airline: raw.airline_name
		? {
				code: raw.airline,
				value: raw.airline_name,
			}
		: undefined,
	departureAirport: raw.departure_airport,
	arrivalAirport: raw.arrival_airport,
	departureDateTime: dayjs(raw.departure_date_time).format(),
	arrivalDateTime: dayjs(raw.arrival_date_time).format(),
	displayFlightNumber: raw.display_flight_number,
});

const hasLuggageIncluded = (offer: Omit<Search.OfferItem, 'id' | 'flightId' | 'hasLuggageIncluded'>): boolean => {
	if (!offer.inbound.luggage || !offer.outbound.luggage) {
		return false;
	}

	return offer.inbound.luggage.weightAdult > 0 && offer.outbound.luggage.weightAdult > 0;
};

export const mapFlight = (raw: Search.RawFlight): Search.Flight => ({
	segmentClass: raw.segment_class,
	distance: raw.distance,
	duration: raw.duration,
	stopOver: raw.stop_over,
	airline: raw.airline,
	departureAirport: raw.departure_airport,
	arrivalAirport: raw.arrival_airport,
	departureDateTime: dayjs(raw.departure_date_time).format(),
	arrivalDateTime: dayjs(raw.arrival_date_time).format(),
	displayFlightNumber: raw.display_flight_number,
	luggage: raw.luggage
		? {
				weightAdult: raw.luggage.weight_adult,
				weightChild: raw.luggage.weight_child,
				weightBaby: raw.luggage.weight_baby,
			}
		: undefined,
	meta: {
		rawDepartureDateTime: raw.departure_date_time,
		rawArrivalDateTime: raw.arrival_date_time,
		flightNumber: raw.flight_number,
		// Only keep the numbers from the flight numbers, not the airline designators
		flightNumbers: raw.flight_segments?.map((f) => f.flight_number.replace(/\D/g, '')) || [raw.flight_number],
		bookingClass: raw.booking_class || '',
		flightCode: raw.flight_code,
		flightId: raw.flight_id,
		flightKey: raw.flight_key || '',
		provider: raw.provider,
	},
	...(raw.flight_segments
		? {
				flightSegments: raw.flight_segments.map(mapFlightSegments),
			}
		: {}),
});

const genFlightId = (offer: Omit<Search.OfferItem, 'id' | 'flightId' | 'hasLuggageIncluded'>): string =>
	Object.values({
		outboundDepartureDateTime: dayjs(offer.outbound.departureDateTime).valueOf(),
		outboundArrivalDateTime: dayjs(offer.outbound.arrivalDateTime).valueOf(),
		outboundDepartureAirport: offer.outbound.departureAirport.code,
		outboundArrivalAirport: offer.outbound.arrivalAirport.code,
		inboundDepartureDateTime: dayjs(offer.inbound.departureDateTime).valueOf(),
		inboundArrivalDateTime: dayjs(offer.inbound.arrivalDateTime).valueOf(),
		inboundDepartureAirport: offer.inbound.departureAirport.code,
		inboundArrivalAirport: offer.inbound.arrivalAirport.code,
		hasLuggageIncluded: hasLuggageIncluded(offer),
		tourOperator: offer.tourOperator,
		priceTotal: offer.priceTotal,
		provider: offer.meta?.provider,
	}).join('|');

const genOfferId = (
	{
		departureDateYYYYMMDD,
		durationNights,
		outbound,
		inbound,
		rooms,
	}: Omit<Search.OfferItem, 'id' | 'flightId' | 'hasLuggageIncluded'>,
	flightId: string,
): string =>
	Object.values({
		departureDate: departureDateYYYYMMDD,
		durationNights,
		outbound: outbound.departureAirport.code,
		inbound: inbound.departureAirport.code,
		roomKey: rooms.map((room) => room.roomKey),
		opCode: rooms.map((room) => room.opCode),
		boardCode: rooms[0].board.code,
		flightId,
	}).join('|');

export const mapOffer = (raw: Search.RawOfferItem): Search.OfferItem => {
	const outbound = mapFlight(raw.outbound);
	const departureDate = dayjs(outbound.departureDateTime).hour(0).minute(0).second(0).millisecond(0).utc().toString();

	const offer = {
		pricePerAdult: raw.price_per_adult,
		priceTotal: raw.price_total,
		pricePackage: raw.price_package,
		pricePerChild: raw.price_per_child,
		pricePerBaby: raw.price_per_baby,
		emergencyFund: raw.emergency_fund ?? 0,
		rooms: raw.rooms.map((room) => ({
			codes: room.codes,
			bookingCode: room.booking_code,
			hasTransfer: Boolean(
				room.extras && -1 !== room.extras.findIndex(({ type, included }) => type === 'TR' && included),
			),
			description: room.description,
			opCode: room.op_code,
			board: {
				opCode: room.board.op_code,
				code: room.board.code,
			},
			roomKey: room.room_key,
			extraBeds: room.extra_beds || 0,
		})),
		numberOfRooms: raw.number_of_rooms,
		durationDays: raw.duration_days,
		durationNights: raw.duration_nights,
		productCode: raw.product_code,
		bookingCode: raw.booking_code,
		tourOperator: raw.tour_operator,
		tourOperatorBrand: raw.brand,
		departureDate: departureDate.toString(),
		departureDateYYYYMMDD: raw.departure_date,
		outbound,
		inbound: mapFlight(raw.inbound),
		meta: {
			arrivalDate: raw.arrival_date,
			hotelKey: raw.hotel_key,
			opCodes: raw.rooms.map((room) => room.op_code),
			provider: raw.provider,
			travelType: raw.travel_type,
			brand: raw.brand,
			departureDate: raw.departure_date,
		},
		extras: {
			funds: mapFundsExtras(raw.extras.funds),
			surcharges: mapBookingCostsExtras(raw.extras.surcharges),
		},
		originalPricePerAdult: raw.original_price_per_person,
		discountAmountType: raw.discount_amount_type,
		discountAmount: raw.discount_amount,
		expirationDate: raw.expiration_date,
		productInfo: raw.product_info ? mapProductInfo(raw.product_info) : null,
	};

	return {
		...offer,
		id: genOfferId(offer, genFlightId(offer)),
		flightId: genFlightId(offer),
		hasLuggageIncluded: hasLuggageIncluded(offer),
	};
};

export const mapOffers = (
	raw: (Search.RawOfferItem | Search.RawAccoOnlyOffer)[],
): (Search.OfferItem | Search.AccommodationOnlyOffer)[] => {
	return raw.map((rawOffer) => {
		if ('rooms' in rawOffer) {
			return mapOffer(rawOffer);
		} else {
			return mapAccoOnlyOffer(rawOffer);
		}
	});
};

export const mapGeoContext = ({
	country_slugs,
	region_slugs,
	city_slugs,
}: Search.RawGeoContext): Search.GeoContext => ({
	countrySlugs: country_slugs,
	regionSlugs: region_slugs,
	citySlugs: city_slugs,
});
