import { useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTripFilterContext } from '@vakantiesnl/services/src/context/useTripFilterContext/useTripFilterContext';
import { useAccoOfferStore } from '@vakantiesnl/services/src/stores/accommodationStore';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { type Search, Theme } from '@vakantiesnl/types';

import { type AccommodationRequestBody, mapAccoOnlyOffer } from './utils';
import { useGetAccommodationByPath } from '../useGetAccommodationByPath';

export function useGetAccommodationOnlyOffers(): {
	accoOnlyOffers: Search.AccoOnlyOffersResponseModel | undefined;
	isLoadingAccoOnlyOffers: boolean;
} {
	const { accommodation } = useGetAccommodationByPath();
	const entityId = accommodation.heliosId;

	const { state: tripFiltersState } = useTripFilterContext();

	const accoOnlyDepartureDateOffer = useAccoOfferStore((s) => s.accoOnlyDepartureDateOffer);
	const setAccoOnlyAccoOffer = useAccoOfferStore((s) => s.setAccoOnlyRoomTypeOffer);

	const departureDate = accoOnlyDepartureDateOffer?.departureDateYYYYMMDD;
	const durations = accoOnlyDepartureDateOffer?.durationDays;

	const requestBody: AccommodationRequestBody = {
		pre_offset: 0,
		post_offset: 0,
		brand: Theme.zvrnl,
		departure_date: departureDate,
		durations: durations ? [durations] : null,
		party: tripFiltersState.party[0],
	};

	const query = useQuery({
		queryKey: [
			`accommodation-only-offers-${entityId}`,
			accoOnlyDepartureDateOffer?.id,
			JSON.stringify(requestBody),
		],
		staleTime: getMinutesInMs(15),
		queryFn: () => fetchGetAccoOnlyOffers(requestBody, entityId),
		enabled: accommodation.isBookableQenner && !!entityId && !!accoOnlyDepartureDateOffer,
	});

	/**
	 * Selected offer
	 * @when a selected date is provided, find an offer with the date
	 * or match the cheapest offer
	 */
	const selected = useMemo(() => {
		if (!query.data?.offers) {
			return undefined;
		}

		return tripFiltersState.selected.roomType
			? query.data?.offers.find(
					({ productInfo }) =>
						productInfo?.productId && String(productInfo.productId) === tripFiltersState.selected.roomType,
				)
			: query.data?.cheapestOffer;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tripFiltersState.selected.roomType, query.data?.offers]);

	useEffect(() => {
		if (selected !== accoOnlyDepartureDateOffer) {
			setAccoOnlyAccoOffer(selected);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected?.id]);

	return {
		accoOnlyOffers: query.data,
		isLoadingAccoOnlyOffers: query.isFetching,
	};
}

const createGetAccoOnlyOffers = (body: AccommodationRequestBody, entityId: number): RequestInfo => {
	return new Request(
		`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/accommodation/${entityId}/acco-only/offer`,
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(body),
		},
	);
};

async function fetchGetAccoOnlyOffers(
	body: AccommodationRequestBody,
	entityId?: number,
): Promise<Search.AccoOnlyOffersResponseModel | undefined> {
	if (!entityId) return undefined;

	const rawAccoOnlyOffers = await fetchHandler<Search.RawAccoOnlyOffersResponseModel>({
		fetchFn: () => fetchVakNL({ input: createGetAccoOnlyOffers(body, entityId) }),
		errorMessage: `Something went wrong during fetching acco only offers for entityId ${entityId}`,
	});

	const mappedOffers = rawAccoOnlyOffers.accommodations[0].offers?.map(mapAccoOnlyOffer);

	const prices = mappedOffers.map(({ priceTotal }) => priceTotal);
	const [minPrice, maxPrice] = [Math.min(...prices), Math.max(...prices)];

	/** Select the lowest price, as a fall back (should never happen but ts asked me to) use the first offer */
	const cheapestOffer = mappedOffers.find(({ priceTotal }) => minPrice === priceTotal) ?? mappedOffers[0];

	return {
		party: rawAccoOnlyOffers.party,
		brand: rawAccoOnlyOffers.brand,
		currency: rawAccoOnlyOffers.currency,
		offers: mappedOffers,
		minPrice,
		maxPrice,
		cheapestOffer,
	};
}
