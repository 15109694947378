import { type ParsedUrlQuery } from 'querystring';

import { type QueryClient } from '@tanstack/react-query';

import { getGeo } from './geo/getGeo';
import type { GeoState } from './geo/interfaces';
import type { FilterState } from '../../stores/filtersStore';
import { getFilterState } from '../../stores/filtersStore/getFilterState';
import { getGeoParamsFromQuery } from '../../util/queryHelpers';

export type SearchPageState = {
	geoState: GeoState;
	filterState: FilterState;
};

export async function getSearchPageState(queryClient: QueryClient, query: ParsedUrlQuery): Promise<SearchPageState> {
	const geoQueryParams = getGeoParamsFromQuery(query);
	const { geoState, selectedGeo } = await getGeo(queryClient, geoQueryParams);
	const filterState = getFilterState(query, selectedGeo);

	return {
		geoState,
		filterState,
	};
}
