import { getLocations } from '@vakantiesnl/components/src/utils/geo/getLocations';
import { getMicroCopy } from '@vakantiesnl/services/src/microcopy';
import { type Elastic, type Search } from '@vakantiesnl/types';

import data from '@MicroCopiesNL';

import { mapOffers } from './mapper';
import { formatAccommodationTitle, mapTopImages } from '../nbc/mapper';

export function mapCompositeAccommodations(
	raw: Elastic.RawCompositeSearchResults[],
): Elastic.ZooverSearchResults['accommodations'] {
	return raw.map(mapCompositeAccommodation);
}

export function mapCompositeAccommodation(raw: Elastic.RawCompositeSearchResults): Search.CompactAccommodationItem {
	return {
		id: raw.accommodation.id,
		goalId: raw.accommodation.goal_id,
		giataId: raw.accommodation.giata_id,
		heliosId: raw.accommodation.entity_id,
		slug: raw.accommodation.slug,
		fullSlugWithType: `/${raw.accommodation.full_slug_with_acco_type}`,
		name: raw.accommodation.name,
		title: formatAccommodationTitle(raw.accommodation.name, raw.accommodation.acco_type) ?? '',
		isBookable: raw.accommodation.is_bookable,
		isBookableQenner: raw.accommodation.is_bookable_qenner ?? false,
		starRating: Math.round(raw.accommodation.star_rating),
		rating: Math.round((raw.accommodation.review_calculations.overall?.rating ?? 0) * 10) / 10,
		reviewCount: raw.accommodation.review_calculations.overall?.count ?? 0,
		reviewPerAspectGroup: raw.accommodation.review_calculations.perAspectGroup ?? {},
		country: raw.accommodation.parents[0].name,
		region: raw.accommodation.parents[1].name,
		city: raw.accommodation.parents[2].name,
		type: raw.accommodation.acco_type,
		formattedType: getMicroCopy(`accoType.${raw.accommodation.acco_type}`, data),
		previewImage: raw.accommodation.preview_image,
		topImages: mapTopImages(raw.accommodation.top_images ?? []),
		awards: raw.accommodation.awards,
		parents: raw.accommodation.parents,
		location: getLocations(raw.accommodation.parents),
		chain: raw.accommodation.chain ?? null,
		offers:
			raw.offers?.length > 0 && (raw.accommodation.is_bookable || raw.accommodation.is_bookable_qenner)
				? mapOffers(raw.offers)
				: [],
	};
}

export function mapCompositeGeoAggregations(
	rawAggregations: Elastic.RawCompositeSearchData['aggregations'],
): Elastic.ZooverCompositeSearchResults['geoAggregations'] {
	const countryAggregations = rawAggregations['country_entity_ids'];
	const regionAggregations = rawAggregations['region_entity_ids'];
	const virtualRegionAggregations = rawAggregations['virtual_region_entity_ids'];
	const cityAggregations = rawAggregations['city_entity_ids'];

	return {
		country_entity_ids: mapCompositeAggregationItems(countryAggregations),
		city_entity_ids: mapCompositeAggregationItems(cityAggregations),
		region_entity_ids: mapCompositeAggregationItems(regionAggregations.concat(virtualRegionAggregations)),
	};
}

function mapCompositeAggregationItems(items: Elastic.AggregationsItem[]): Record<string, number> {
	return items.reduce<Record<string, number>>((aggregations, item) => {
		aggregations[item.key] = item.doc_count;
		return aggregations;
	}, {});
}
