import { type Booking } from '@vakantiesnl/types';

export enum FundsType {
	SGR_FUNDS = 'SGR_FUNDS',
	EMERGENCY_FUNDS = 'EMERGENCY_FUNDS',
}

export type FundsItem = {
	type: FundsType;
	price: number;
	priceTotal: number;
	refCount: number;
};

export type RawFundsItem = {
	type: FundsType;
	price: number;
	price_total: number;
	ref_count: number;
	ref_ids: number[] | null;
	booking_status: Booking.RawStatus;
};

export type Funds = {
	items: FundsItem[];
	priceTotal: number;
};

export type RawFunds = {
	items: RawFundsItem[];
	price_total: number;
	ref_id: number;
};
