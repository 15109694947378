import { type FilterState } from '@vakantiesnl/services/src/stores/filtersStore';
import type { GlobalFiltersState } from '@vakantiesnl/services/src/stores/globalFiltersStore/interfaces';
import { isPackageOffer } from '@vakantiesnl/services/src/util';
import { DEFAULT_DURATIONS, DEFAULT_DURATIONS_ARRAY } from '@vakantiesnl/services/src/util/durations';
import { DEFAULT_PARTY, formatPaxQuery } from '@vakantiesnl/services/src/util/partyUtils';
import { type Search } from '@vakantiesnl/types/src';
import { isEqual } from 'lodash';

const buildAccoUrlQueryParams = (
	offer: Search.OfferItem | Search.AccommodationOnlyOffer | undefined,
	filters: Partial<FilterState>,
	globalFilters: GlobalFiltersState,
	durationsOverride?: string | string[],
): URLSearchParams => {
	const params = new URLSearchParams();

	if (!!filters.departureAirport?.length) {
		params.set('airports', filters.departureAirport.join('+'));
	}

	const offerDepartureDate = isPackageOffer(offer) ? offer.meta?.departureDate : offer?.departureDateYYYYMMDD;

	if (offerDepartureDate) {
		params.set('departure_date', offerDepartureDate);
	}

	if (typeof filters.rating === 'string') {
		params.set('rating', filters.rating);
	}

	if (durationsOverride) {
		params.set('o_durations', Array.isArray(durationsOverride) ? durationsOverride.join('+') : durationsOverride);
	}

	if (
		globalFilters.durations &&
		!isEqual(globalFilters.durations, DEFAULT_DURATIONS) &&
		!isEqual(globalFilters.durations, DEFAULT_DURATIONS_ARRAY)
	) {
		params.set(
			'durations',
			Array.isArray(globalFilters.durations) ? globalFilters.durations.join('+') : globalFilters.durations,
		);
	}

	if (!!filters.mealplans?.length) {
		params.set('mealplans', filters.mealplans.join('+'));
	}

	if (!!filters.distances?.length) {
		params.set('distances', filters.distances.join('+'));
	}

	if (filters && filters.facilities && filters.facilities.length > 0) {
		const facilities = filters.facilities;
		params.set('facilities', facilities?.join('+'));
	}

	if (!isEqual(globalFilters.party, DEFAULT_PARTY)) {
		params.set('pax', formatPaxQuery(globalFilters.party));
	}

	if (typeof filters.priceMin === 'string') {
		params.set('min_price', filters.priceMin);
	}

	if (typeof filters.priceMax === 'string') {
		params.set('max_price', filters.priceMax);
	}

	return params;
};

export const useBuildAccoUrlQueryParams = (
	offerItem: Search.OfferItem | Search.AccommodationOnlyOffer | undefined,
	filters: Partial<FilterState>,
	globalFilters: GlobalFiltersState,
	durationsOverride?: string | string[],
): URLSearchParams => {
	return buildAccoUrlQueryParams(offerItem, filters, globalFilters, durationsOverride);
};
