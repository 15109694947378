import { type FC, type ReactElement, useMemo } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';

import { useCustomStyles } from './BaseFormField.style';

export type Props = {
	// temporality, need to redesign terms and conditions https://vakanties.atlassian.net/browse/WEB-5193
	label?: (string | JSX.Element)[] | string;
	value?: unknown;
	isDisabled?: boolean;
	isRequired?: boolean;
	control: ReactElement;
};

export const BaseFormField: FC<Props> = ({ label, value, isDisabled, isRequired, control }) => {
	const { classes } = useCustomStyles();

	const formClasses = useMemo(
		() => ({ label: classes.label, root: classes.root, disabled: classes.disabled }),
		[classes],
	);

	return (
		<FormControlLabel
			classes={formClasses}
			value={value}
			label={label}
			required={isRequired}
			disabled={isDisabled}
			control={control}
		/>
	);
};
