import { type RawStatus, type Status } from '../status';

export enum BookingCostsType {
	BOOKINGCOST = 'BOOKINGCOST',
}

export type BookingCosts = {
	type: BookingCostsType.BOOKINGCOST;
	price: number;
	priceTotal: number;
	refIds: number[];
	refCount: number;
	bookingStatus: Status;
};

export type RawBookingCosts = {
	type: BookingCostsType.BOOKINGCOST;
	price: number;
	price_total: number;
	ref_ids: number[];
	ref_count: number;
	booking_status: RawStatus;
};

export type Surcharges = {
	items: BookingCosts[];
	priceTotal: number;
	refId: number;
};

export type RawSurcharges = {
	items: RawBookingCosts[];
	price_total: number;
	ref_id: number;
};
