import { type FC, type PropsWithChildren, useMemo } from 'react';

import { type NextComponentType } from 'next';

import { BookingHeader } from '@vakantiesnl/components/src/molecules/BookingHeader';
import { type GTM, PageType, type Seo } from '@vakantiesnl/types';

import Meta from '../../../blocks/Meta';

export type LayoutProps = {
	/** Header component to render in the layout */
	Header: NextComponentType<
		Record<string, unknown>,
		Record<string, unknown>,
		{ hideMenu?: false; pageType: GTM.GTMPageType }
	>;
	/** Footer component to render in the layout */
	Footer: NextComponentType<Record<string, unknown>, Record<string, unknown>, { pageType: GTM.GTMPageType }>;
	/** SEO object for the page */
	seo: Seo;
	/** Modal component to render in the layout */
	Modal?: NextComponentType<Record<string, unknown>>;
	cookieConsent?: string;
};

const Layout: FC<PropsWithChildren<LayoutProps>> = ({ children, Header, Footer, Modal, seo, cookieConsent }) => {
	const isBooking = useMemo(() => seo.pageType === PageType.booking, [seo.pageType]);

	return (
		<>
			{seo && <Meta seo={seo} />}
			{isBooking ? <BookingHeader /> : <Header pageType={seo.gtmPageType} />}
			{children}
			<Footer pageType={seo.gtmPageType} />
			{Modal && !cookieConsent && <Modal />}
		</>
	);
};

export default Layout;
