import type { GeoState, SelectedGeo } from '@vakantiesnl/services/src/hooks/queries/geo/interfaces';
import { type FilterState } from '@vakantiesnl/services/src/stores/filtersStore';
import { type MappedDestinationView } from '@vakantiesnl/types/src/geo';

const filterOutNonMappedDestinationView = (item: MappedDestinationView | undefined): item is MappedDestinationView =>
	!!item;

export const getOnlySelectedGeo = (filters: FilterState, geo: GeoState): SelectedGeo => {
	const selectedGeo: SelectedGeo = {
		cities: [],
		countries: [],
		regions: [],
	};

	if (filters.countrySlugs.length && geo.countries.length) {
		selectedGeo.countries = filters.countrySlugs
			.map((slug) => geo.countries.find((country) => country.slug === slug))
			.filter(filterOutNonMappedDestinationView);

		selectedGeo.regions = [];
		selectedGeo.cities = [];
	}

	const allRegions: MappedDestinationView[] = Object.values(geo.regions)?.flat();
	if (filters.regionSlugs.length && allRegions.length) {
		/** Remove the county codes that have already a selected region */
		selectedGeo.countries = filters.countrySlugs
			.map((slug) => geo.countries.find((country) => country.slug === slug))
			.filter((country) => {
				if (!country?.slug) return;

				const hasRegions = !!geo.regions[country.slug]?.find((region) =>
					filters.regionSlugs.includes(region.slug),
				);

				return !hasRegions;
			})
			.filter(filterOutNonMappedDestinationView);

		selectedGeo.regions = filters.regionSlugs
			.map((slug) => allRegions.find((region) => region.slug === slug))
			.filter(filterOutNonMappedDestinationView);
		selectedGeo.cities = [];
	}

	const allCities: MappedDestinationView[] = Object.values(geo.cities).flat();
	/** Remove the region codes that have already a selected city */
	if (filters.citySlugs.length && allCities.length) {
		selectedGeo.regions = filters.regionSlugs
			.map((slug) => allRegions.find((region) => region.slug === slug))
			.filter((region) => {
				if (!region?.slug) return;

				const hasCities = !!geo.cities[region.slug]?.find((city) => filters.citySlugs.includes(city.slug));

				return !hasCities;
			})
			.filter(filterOutNonMappedDestinationView);

		/** we don't support multiple countries for cities and only pass the lowest level, so countries should be an empty array for city levels */
		selectedGeo.countries = [];

		selectedGeo.cities = filters.citySlugs
			.map((slug) => allCities.find((city) => city.slug === slug))
			.filter(filterOutNonMappedDestinationView);
	}
	return selectedGeo;
};
