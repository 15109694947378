import { type ParsedUrlQuery } from 'querystring';

import { DEFAULT_PARTY, unformatPaxQuery } from '@vakantiesnl/services/src/util/partyUtils';
import { type Search } from '@vakantiesnl/types/src';

export function updatePartyCompositionStore(
	query: ParsedUrlQuery,
	setPartyComposition: (party: Search.PartyComposition[]) => void,
): void {
	const paxFromQuery = typeof query.pax === 'string' && unformatPaxQuery(query.pax);

	if (paxFromQuery) {
		setPartyComposition(paxFromQuery);
	} else {
		// if pax is not in the url, it means the default was used
		setPartyComposition(DEFAULT_PARTY);
	}
}
