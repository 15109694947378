import { DEFAULT_SORT_ORDER_OPTION } from '@vakantiesnl/services/src/stores/filtersStore/config';
import { type VaknlSearchBody, type ZooverCompositeSearchBody } from '@vakantiesnl/types/src/search';

import { DEFAULT_DURATIONS_ARRAY } from '../util/durations';

export const defaultBodySearchRequest: VaknlSearchBody = {
	brand: process.env.NEXT_PUBLIC_BRAND,
	page_size: 10,
	page: 1,
	modifiers: {
		sorting_order: DEFAULT_SORT_ORDER_OPTION,
	},
	party: {
		adults: 2,
		children: 0,
		babies: 0,
	},
	rooms: [
		{
			adults: 2,
			children: 0,
			babies: 0,
		},
	],
	filters: {
		/* Flight durations must be implicitly set for a search request.
		 * Since backend expects "nights to stay" but not durations in days
		 * default durations should be reduced by 1 to calculate nights count */
		durations_flight: DEFAULT_DURATIONS_ARRAY.map((n) => n - 1),
	},
};

export const defaultCompositeSearch: ZooverCompositeSearchBody = {
	brand: process.env.NEXT_PUBLIC_BRAND,
	filters: {
		durations_flight: DEFAULT_DURATIONS_ARRAY.map((n) => n - 1),
	},
	modifiers: {
		sorting_order: DEFAULT_SORT_ORDER_OPTION,
	},
	page: 1,
	page_size: 15,
	party: {
		adults: 2,
		babies: 0,
		children: 0,
	},
	post_offset: 0,
	pre_offset: 100,
	rooms: [
		{
			adults: 2,
			children: 0,
			babies: 0,
		},
	],
	transport_type: 'INCLUDING_FLIGHT',
};
