import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	form: {
		marginBottom: theme.spacing.medium,

		[getFromBreakpoint('md')]: {
			marginBottom: theme.spacing.large,
		},
	},
	divider: {
		[getFromBreakpoint('md')]: {
			margin: `${theme.spacing.large} 0`,
		},
	},
	loginButton: {
		marginTop: theme.spacing.small,
		width: '100%',

		[getFromBreakpoint('md')]: {
			width: 'auto',
		},
	},
	socials: {
		marginBottom: theme.spacing.large,

		[getFromBreakpoint('md')]: {
			marginBottom: theme.spacing.medium,
		},
	},
	socialButton: {
		width: '100%',
		marginBottom: theme.spacing.xxsmall,

		'&:last-of-type': {
			marginBottom: 0,
		},
	},
	dividers: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing.medium,

		[getFromBreakpoint('md')]: {
			marginBottom: theme.spacing.small,
		},
	},
	socialDivider: {
		flexGrow: 1,
	},
	or: {
		paddingRight: theme.spacing.xxsmall,
		paddingLeft: theme.spacing.xxsmall,
	},
	error: {
		color: theme.negative.color.default,
		fontSize: theme.fontSize.xxSmall,
		marginTop: theme.spacing.tiny,
	},
	visibilityIcon: {
		cursor: 'pointer',
	},
	filedRow: {
		marginBottom: theme.spacing.small,
	},
	forgotPswdLink: {
		display: 'block',
		marginTop: theme.spacing.small,
	},
}));
