import { useEffect, useMemo } from 'react';

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { hasEnabledFeature } from '@vakantiesnl/components/src/atoms/FeatureToggle/utils';
import { useTripFilterContext } from '@vakantiesnl/services/src/context/useTripFilterContext/useTripFilterContext';
import { useFeatures } from '@vakantiesnl/services/src/stores';
import { useAccoOfferStore } from '@vakantiesnl/services/src/stores/accommodationStore';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';
import { isNotEmpty } from '@vakantiesnl/services/src/util/arrayUtils';
import {
	getDateWithoutTime,
	getDateYMD,
	getMaxDepartureDate,
	getMinDepartureDate,
} from '@vakantiesnl/services/src/util/dateUtils';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { getDefaultDate } from '@vakantiesnl/services/src/util/getDefaultDate';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { type Search, Theme } from '@vakantiesnl/types';

import { type AccommodationRequestBody, mapAccoOnlyOffer } from './utils';
import { getDepartureDateAndOffset, PAGE_OFFSET } from '../../utils/offerUtils';
import { useGetAccommodationByPath } from '../useGetAccommodationByPath';

type AccoOnlyFilters = {
	party: Search.PartyComposition;
	durations: number[];
	departureDate: string | undefined;
	hasUserSelectedDate: boolean; // Did the user manually select the departure date or not
};

export function useGetAccoOnlyOfferFilters(): AccoOnlyFilters {
	const {
		state: { departureDate, party },
		computed: { durations },
	} = useTripFilterContext();

	return useMemo(
		() => ({
			departureDate: !!departureDate?.length ? departureDate[0] : getDefaultDate(),
			hasUserSelectedDate: !!departureDate?.length,
			durations,
			party: party[0],
		}),
		[departureDate, durations, party],
	);
}

export type GetCheapestOffersQuery = ReturnType<typeof useGetCheapestOfferPerDay>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGetCheapestOfferPerDay() {
	const { accommodation } = useGetAccommodationByPath();
	const entityId = accommodation.heliosId;

	const features = useFeatures();
	const isInfiniteOffersEnabled = hasEnabledFeature(features, 'infiniteOffers');

	const { state: tripFiltersState } = useTripFilterContext();

	const setIsDepartureDateLoading = useAccoOfferStore((s) => s.setIsDepartureDateLoading);
	const isDepartureDateLoading = useAccoOfferStore((s) => s.isDepartureDateLoading);
	const setTotalDepartureDateOffers = useAccoOfferStore((s) => s.setTotalDepartureDateOffers);

	const filters = useGetAccoOnlyOfferFilters();

	/** Selected date is first the selected price bar date else it's the selected departure date from the calendar */
	const selectedOfferDate = tripFiltersState.selected.date || filters.departureDate;

	const query = useQuery({
		queryKey: [`accommodation-cheapest-offers`, entityId, JSON.stringify(filters)],
		staleTime: getMinutesInMs(15),
		queryFn: () =>
			fetchGetCheapestOfferPerDay(
				entityId,
				filters,
				filters.departureDate
					? getDateYMD(getDateWithoutTime(filters.departureDate).subtract(15, 'day'))
					: undefined,
			),
		enabled: !!entityId && !isInfiniteOffersEnabled && accommodation.isBookableQenner,
	});

	const infiniteQuery = useInfiniteQuery({
		queryKey: [`accommodation-cheapest-offers-infinite`, entityId, JSON.stringify(filters)],
		queryFn: ({ pageParam }) => fetchGetCheapestOfferPerDay(entityId, filters, pageParam),
		enabled: !!entityId && isInfiniteOffersEnabled,
		getNextPageParam: (_lastPage, _allPages, lastStartDate) => {
			const maxDepartureDate = getMaxDepartureDate();

			if (!lastStartDate || getDateWithoutTime(lastStartDate).isSameOrAfter(maxDepartureDate)) {
				return undefined;
			}

			const nextStartDate = getDateWithoutTime(lastStartDate).add(PAGE_OFFSET, 'day');

			return getDateYMD(nextStartDate);
		},
		getPreviousPageParam: (_lastPage, _allPages, lastStartDate) => {
			const minDepartureDate = getMinDepartureDate();

			if (!lastStartDate || getDateWithoutTime(lastStartDate).isSameOrBefore(minDepartureDate)) {
				return undefined;
			}

			const nextStartDate = getDateWithoutTime(lastStartDate).subtract(PAGE_OFFSET, 'day');

			return getDateYMD(nextStartDate);
		},
		initialPageParam: selectedOfferDate
			? getDateYMD(getDateWithoutTime(selectedOfferDate).subtract(15, 'day'))
			: undefined,
	});

	const mergedOffers = useMemo(() => {
		return infiniteQuery.data?.pages ? getMergedDepartureDateOffers(infiniteQuery.data.pages) : undefined;
	}, [infiniteQuery.data?.pages]);

	useEffect(() => {
		const total = isInfiniteOffersEnabled ? mergedOffers?.offers.length : query.data?.offers.length;
		setTotalDepartureDateOffers(total || 0);
	}, [isInfiniteOffersEnabled, mergedOffers, query.data, setTotalDepartureDateOffers]);

	useEffect(() => {
		if (query.isLoading !== isDepartureDateLoading && !isInfiniteOffersEnabled) {
			setIsDepartureDateLoading(query.isLoading);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query.isLoading, isInfiniteOffersEnabled]);

	useEffect(() => {
		if (infiniteQuery.isLoading !== isDepartureDateLoading && isInfiniteOffersEnabled) {
			setIsDepartureDateLoading(infiniteQuery.isLoading);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [infiniteQuery.isLoading, isInfiniteOffersEnabled]);

	return {
		cheapestOffersPerDay: isInfiniteOffersEnabled ? mergedOffers : query.data,
		fetchNextDepartureDateOffers: infiniteQuery.fetchNextPage,
		fetchPrevDepartureDateOffers: infiniteQuery.fetchPreviousPage,
		hasNextDepartureDateOffers: infiniteQuery.hasNextPage,
		hasPrevDepartureDateOffers: infiniteQuery.hasPreviousPage,
		isFetchingDepartureDateOffers: infiniteQuery.isFetching,
	};
}

function getMergedDepartureDateOffers(
	data: (Search.AccoOnlyOffersResponseModel | undefined)[],
): Search.AccoOnlyOffersResponseModel {
	const filteredData = data.filter(isNotEmpty);

	if (!filteredData.length) throw new Error('Expected acco offer response');

	const allOffers = filteredData.flatMap((a) => a?.offers);
	const minPrice = Math.min(...filteredData.flatMap((p) => p.minPrice));
	const maxPrice = Math.max(...filteredData.flatMap((p) => p.maxPrice));
	const cheapestOffer =
		filteredData
			.flatMap((d) => d.cheapestOffer)
			.filter(isNotEmpty)
			.find((d) => d.priceTotal === minPrice) || allOffers[0];

	return {
		party: filteredData[0].party,
		brand: filteredData[0].brand,
		currency: filteredData[0].currency,
		offers: allOffers,
		minPrice,
		maxPrice,
		cheapestOffer,
	};
}

const createGetCheapestOfferPerDay = (body: AccommodationRequestBody, entityId: number): RequestInfo => {
	return new Request(
		`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/accommodation/${entityId}/acco-only/cheapest_offer_per_day`,
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(body),
		},
	);
};

async function fetchGetCheapestOfferPerDay(
	entityId: number | undefined,
	filters: AccoOnlyFilters,
	nextDepartureDate: string | undefined,
): Promise<Search.AccoOnlyOffersResponseModel | undefined> {
	if (!entityId) return undefined;

	const { departureDate, preOffset, postOffset } = getDepartureDateAndOffset(nextDepartureDate);

	const body: AccommodationRequestBody = {
		pre_offset: preOffset,
		post_offset: postOffset,
		brand: Theme.zvrnl,
		...(departureDate && { departure_date: departureDate }),
		durations: filters.durations,
		party: filters.party,
	};

	const rawCheapestOfferPerDay = await fetchHandler<Search.RawAccoOnlyOffersResponseModel>({
		fetchFn: () => fetchVakNL({ input: createGetCheapestOfferPerDay(body, entityId) }),
		errorMessage: `Something went wrong during fetching cheapest offers per day for entityId ${entityId}`,
	});

	const mappedOffers = rawCheapestOfferPerDay.accommodations[0].offers?.map(mapAccoOnlyOffer);

	const prices = mappedOffers.map(({ priceTotal }) => priceTotal);
	const [minPrice, maxPrice] = [Math.min(...prices), Math.max(...prices)];

	/** Select the lowest price, as a fall back (should never happen but ts asked me to) use the first offer */
	const cheapestOffer = mappedOffers.find(({ priceTotal }) => minPrice === priceTotal) ?? mappedOffers[0];

	return {
		party: rawCheapestOfferPerDay.party,
		brand: rawCheapestOfferPerDay.brand,
		currency: rawCheapestOfferPerDay.currency,
		offers: mappedOffers,
		minPrice,
		maxPrice,
		cheapestOffer,
	};
}
