import { type FC, type PropsWithChildren, createContext, useRef } from 'react';

import { type GlobalFiltersStore, createGlobalFiltersStore } from './globalFiltersStore';
import { type GlobalFiltersState } from './interfaces';
import { getDurationFromCookie, getPaxFromCookie } from '../../util/globalFilterStateHelpers';

export const GlobalFiltersStoreContext = createContext<GlobalFiltersStore | null>(null);

type Props = {
	partyComposition: GlobalFiltersState['party'] | null;
	durations: GlobalFiltersState['durations'];
};

export const GlobalFiltersProvider: FC<PropsWithChildren<Props>> = ({ children, partyComposition, durations }) => {
	const store = useRef<GlobalFiltersStore>();

	if (!store.current) {
		// If the page does not return the partyComposition, then check if there was a pax value
		// stored before in a cookie, otherwise the store will initialize with the default pax value
		store.current = createGlobalFiltersStore(
			partyComposition ?? getPaxFromCookie(),
			durations ?? getDurationFromCookie(),
		);
	}

	return <GlobalFiltersStoreContext.Provider value={store.current}>{children}</GlobalFiltersStoreContext.Provider>;
};
