/* eslint-disable-next-line camelcase, @typescript-eslint/naming-convention */
import { getBoardDescription, getRoomDescription } from '@vakantiesnl/components/src/utils/accommodation';
import { type MicroCopy, type Search } from '@vakantiesnl/types';

import { isAccoOnlyOffer, isPackageOffer } from '../util';

type Meta = { code: string; name: string };
type RawMeta = { code: string; value: string };

const mapRawMeta = ({ code, value }: RawMeta): Meta => ({
	code,
	name: value,
});

const getAirlines = (offer: Search.OfferItem): Meta[] => {
	const airlines = new Map();
	offer.outbound.airline && airlines.set(offer.outbound.airline.code, mapRawMeta(offer.outbound.airline));
	offer.inbound.airline && airlines.set(offer.inbound.airline.code, mapRawMeta(offer.inbound.airline));
	offer.outbound.flightSegments?.forEach(({ airline }) => airline && airlines.set(airline.code, mapRawMeta(airline)));
	offer.inbound.flightSegments?.forEach(({ airline }) => airline && airlines.set(airline.code, mapRawMeta(airline)));

	return [...airlines.values()];
};

const getAirports = (offer: Search.OfferItem): Meta[] => {
	const airports = new Map();

	offer.outbound.departureAirport &&
		airports.set(offer.outbound.departureAirport.code, mapRawMeta(offer.outbound.departureAirport));
	offer.outbound.arrivalAirport &&
		airports.set(offer.outbound.arrivalAirport.code, mapRawMeta(offer.outbound.arrivalAirport));
	offer.inbound.departureAirport &&
		airports.set(offer.inbound.departureAirport.code, mapRawMeta(offer.inbound.departureAirport));
	offer.inbound.arrivalAirport &&
		airports.set(offer.inbound.arrivalAirport.code, mapRawMeta(offer.inbound.arrivalAirport));

	offer.outbound.flightSegments?.forEach(({ arrivalAirport, departureAirport }) => {
		airports.set(arrivalAirport.code, mapRawMeta(arrivalAirport));
		airports.set(departureAirport.code, mapRawMeta(departureAirport));
	});
	offer.inbound.flightSegments?.forEach(({ arrivalAirport, departureAirport }) => {
		airports.set(arrivalAirport.code, mapRawMeta(arrivalAirport));
		airports.set(departureAirport.code, mapRawMeta(departureAirport));
	});

	return [...airports.values()];
};

const buildFlight = (flight: Search.Flight): Record<string, unknown> => ({
	airline_code: flight.airline?.code,
	arrival_airport: flight.arrivalAirport.code,
	arrival_date_time: flight.meta?.rawArrivalDateTime,
	departure_airport: flight.departureAirport.code,
	departure_date_time: flight.meta?.rawDepartureDateTime,
	flight_number: flight.meta?.flightNumber,
	segment_class: flight.segmentClass || '',
	flight_code: flight.meta?.flightCode,
	flight_id: flight.meta?.flightId || '',
	flight_key: flight.meta?.flightKey,
	provider: flight.meta?.provider || '',
	booking_class: flight.meta?.bookingClass,
	stop_over: flight.stopOver,
});

export type AvailabilityRequestBody = string | Record<string, unknown>;

export const buildAccoOnlyAvailabilityRequestBody = (
	offer: Search.AccommodationOnlyOffer,
	entityId: number,
	party: Search.PartyComposition,
): AvailabilityRequestBody => ({
	accommodation: {
		entity_id: entityId,
		product_code: offer.productCode,
		arrival_date: offer.departureDateYYYYMMDD,
		duration_days: offer.durationDays,
		duration_nights: offer.durationNights,
	},
	tour_operator: offer.tourOperatorId,
	price: offer.pricePackage,
	party: {
		adults: party.adults,
		babies: party.babies,
		children: party.children,
	},
	brand: process.env.NEXT_PUBLIC_BRAND as string,
});
export const buildAvailabilityRequestBody = (
	offer: Search.OfferItem | Search.AccommodationOnlyOffer,
	accommodation: Search.FullAccommodationItem,
	party: Search.PartyComposition,
	rooms: Search.PartyComposition[],
	microCopies: MicroCopy,
): AvailabilityRequestBody | undefined => {
	if (isPackageOffer(offer) && accommodation.giataId) {
		return buildPackageAvailabilityRequestBody(offer, accommodation.giataId, party, rooms, microCopies);
	} else if (isAccoOnlyOffer(offer)) {
		return buildAccoOnlyAvailabilityRequestBody(offer, accommodation.heliosId, party);
	}

	return undefined;
};

export const buildPackageAvailabilityRequestBody = (
	offer: Search.OfferItem,
	giataId: number,
	party: Search.PartyComposition,
	rooms: Search.PartyComposition[],
	microCopies: MicroCopy,
): AvailabilityRequestBody => ({
	accommodation: {
		// TODO: remove when multi rooms clean up is done
		room_key: offer.rooms[0].roomKey,
		extra_beds: offer.rooms[0].extraBeds,
		booking_code: offer.rooms[0].bookingCode,
		board_op_code: offer.rooms[0].board.opCode,
		op_code: offer.meta?.opCodes[0],
		/** This key is needed to prevent a 422 */
		board_key: '',

		rooms_keys: offer.rooms.reduce(
			(acc: string[], room) => (room.roomKey !== null ? [...acc, room.roomKey] : acc),
			[],
		),
		rooms_extra_beds: offer.rooms.map((room) => room.extraBeds),
		product_code: offer.productCode,
		booking_codes: offer.rooms.map((room) => room.bookingCode),
		board_op_codes: offer.rooms.map((room) => room.board.opCode),
		op_codes: offer.meta?.opCodes,
		/** This key is needed to prevent a 422 */
		board_keys: [],

		duration_nights: offer.durationNights,
		arrival_date: offer.meta?.arrivalDate,
		provider: offer.meta?.provider || '',
		hotel_key: offer.meta?.hotelKey || '',
		giata_id: giataId,
	},
	// TODO: remove when multi rooms clean up is done
	room_description: getRoomDescription(offer, 0, microCopies),

	mealplan: offer.rooms[0].board.code,
	board_description: getBoardDescription(offer, microCopies) || '',
	rooms_descriptions: offer.rooms.map((_, index) => getRoomDescription(offer, index, microCopies)),

	brand: process.env.NEXT_PUBLIC_BRAND || '',
	inbound: buildFlight(offer.inbound),
	outbound: buildFlight(offer.outbound),
	price: offer.pricePackage,
	tour_operator: offer.tourOperator,
	tour_operator_brand: offer.tourOperatorBrand,
	travel_type: offer.meta?.travelType || '',
	transfer_in_package: offer.rooms?.some((room) => room.hasTransfer) || false,
	party: {
		adults: party.adults,
		babies: party.babies,
		children: party.children,
	},
	rooms,
	airlines: getAirlines(offer),
	airports: getAirports(offer),
});
