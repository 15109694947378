import dayjs from 'dayjs';

import {
	type BookingRentalCar,
	type Flight,
	type FlightSegment,
	type FullUserBooking,
	type RawFullUserBooking,
	type Room,
	type UserBookingFlight,
} from './bookingDetailsInterfaces';
import { isNotEmpty } from '../util/arrayUtils';
import { getDateWithoutTime } from '../util/dateUtils';

export function mapUserBooking(booking: RawFullUserBooking): FullUserBooking {
	const outboundFlight = mapOutboundFlights(booking);

	// Normally only the outbound flight has the flightProvider, unless extra flights have been booked later on
	const inboundFlight = mapInboundFlights(booking, outboundFlight?.flightProvider);

	const rooms: Room[] = booking.rooms.map((room) => ({
		participants: room.bookingServiceParticipants.map((p) => p.bookingParticipant),
		roomTypeName: room.bookingServiceAccommodations[0]?.roomTypeName || '',
	}));

	const hasRentalCar = !!booking.rentalCars.length && !!booking.rentalCars[0].bookingServiceRentalCars.length;
	const rentalCar: BookingRentalCar | undefined = hasRentalCar
		? {
				passengerAssignment: parseInt(booking.rentalCars[0].passengerAssignment),
				rentalCarType: booking.rentalCars[0].bookingServiceRentalCars[0].rentalCarType,
				supplierType: booking.rentalCars[0].bookingServiceRentalCars[0].supplierType,
				extraCodes:
					booking.rentalCars[0].bookingServiceRentalCars[0].remark?.split(',').map((c) => c.trim()) || [],
			}
		: undefined;

	return {
		isAccoOnly: booking.transportCode === 'EV',
		amountTotal: booking.amountTotal,
		bookingAmountOpen: booking.bookingAmountOpen,
		bookingNumber: booking.bookingNumber,
		departureDate: booking.departureDate,
		duration: booking.rooms[0]?.duration || booking.duration,
		returnDate: booking.returnDate,
		entityId: booking.entityId,
		tourOperatorCode: booking.firstServiceName,
		mealplan: booking.mealplan,
		numberOfPassengers: booking.numberOfPassengers,
		isCancelled: booking.isCancelled,
		bookingDocuments: booking.bookingDocuments,
		bookingServicePrices: booking.bookingServicePrices,
		payments: booking.payments,
		paymentTerms: booking.paymentTerms,
		contactDetails: booking.contactDetails,
		transfers: booking.transfers,
		rentalCar,
		rooms,
		inboundFlight,
		outboundFlight,
	};
}

function mapOutboundFlights(userBookingData: RawFullUserBooking): UserBookingFlight | null {
	// Find all outbound flights based on the booking departureDate. Normally there is only one, unless
	// f.e. extra pax has been booked later.
	const outboundFlights = userBookingData.flights.filter((flight) =>
		getDateWithoutTime(flight.startDate).isSame(getDateWithoutTime(userBookingData.departureDate)),
	);

	if (!outboundFlights.length) {
		return null;
	}

	return mapFlights(outboundFlights, userBookingData);
}

function mapInboundFlights(
	userBookingData: RawFullUserBooking,
	outboundFlightProvider?: string | null,
): UserBookingFlight | null {
	// Find all inbound flights based on the booking returnDate. Normally there is only one, unless
	// f.e. extra pax has been booked later.
	const inboundFlights = userBookingData.flights.filter((flight) =>
		getDateWithoutTime(flight.endDate).isSame(getDateWithoutTime(userBookingData.returnDate)),
	);

	if (!inboundFlights.length) {
		return null;
	}

	return mapFlights(inboundFlights, userBookingData, outboundFlightProvider);
}

function mapFlights(
	flight: Flight[],
	userBookingData: RawFullUserBooking,
	outboundFlightProvider?: string | null,
): UserBookingFlight | null {
	// For display in the UI, always assume the first flight, as generally extra pax is on the same
	// flight, with the exception of the PNR numbers, as these are generally different for extra pax.
	const firstFlight = flight[0];

	if (!firstFlight) {
		return null;
	}

	const firstFlightSegments = firstFlight.bookingServiceFlights;
	const firstFlightSegment = firstFlightSegments[0];
	const lastFlightSegment = firstFlightSegments[firstFlightSegments.length - 1];

	// Should not happen, but prevent crashing on undefined in case the response was somehow wrong
	if (!firstFlightSegment || !lastFlightSegment) {
		return null;
	}

	const duration = getDurationInMinutes(firstFlightSegment.flightDepartureDatee, lastFlightSegment.flightArrivalDate);

	// Minus one to take into account the initial flight
	const stopOver = firstFlightSegments.length - 1;

	const minLuggage = getMinLuggage(userBookingData, firstFlightSegment);

	const allFlightSegments = flight.flatMap((f) => f.bookingServiceFlights);
	const allPnrNumbers = [
		...new Set(allFlightSegments.map((f) => f.bookingServiceExternalReference).filter(isNotEmpty)),
	];

	const flightProvider = firstFlight.supplierCode || outboundFlightProvider || null;

	const mappedFlight: UserBookingFlight = {
		flightProvider,
		airline: { code: firstFlightSegment.flightAirlineCode, value: firstFlightSegment.flightAirlineName },
		departureAirport: {
			value: firstFlightSegment.flightDepartureAirportName,
			code: firstFlightSegment.flightDepartureAirport,
		},
		arrivalAirport: {
			value: lastFlightSegment.flightArrivalAirportName,
			code: lastFlightSegment.flightArrivalAirport,
		},
		departureDateTime: firstFlightSegment.flightDepartureDatee,
		arrivalDateTime: lastFlightSegment.flightArrivalDate,
		displayFlightNumber: `${firstFlightSegment.flightAirlineCode} ${firstFlightSegment.flightNumber}`,
		duration,
		stopOver,
		luggage: {
			weightAdult: minLuggage.weight,
			weightBaby: 0,
			weightChild: 0,
		},
		luggageParticipants: minLuggage.participants,
		allPnrNumbers,
		flightSegments: firstFlightSegments.map((f) => {
			return {
				airline: { code: f.flightAirlineCode, value: f.flightAirlineName },
				departureDateTime: f.flightDepartureDatee,
				arrivalDateTime: f.flightArrivalDate,
				departureAirport: { value: f.flightDepartureAirportName, code: f.flightDepartureAirport },
				arrivalAirport: { value: f.flightArrivalAirportName, code: f.flightArrivalAirport },
				displayFlightNumber: `${f.flightAirlineCode} ${f.flightNumber}`,
				duration: getDurationInMinutes(f.flightDepartureDatee, f.flightArrivalDate),
				stopOver,
				// For now add minLuggage to each segment, this may not always be correct as some flights may support more weights
				// or potentially could have extra luggage booked, but since this is very rare don't handle it for now
				luggage: {
					weightAdult: minLuggage.weight,
					weightBaby: 0,
					weightChild: 0,
				},
				luggageParticipants: minLuggage.participants,
			};
		}),
	};

	return mappedFlight;
}

function getDurationInMinutes(departure: string, arrival: string): number {
	return dayjs(arrival).diff(dayjs(departure), 'minute');
}

function getMinLuggage(
	userBookingData: RawFullUserBooking,
	firstFlight: FlightSegment,
): { weight: number; participants: number } {
	const matchingLuggage = userBookingData.luggage.filter((l) => {
		// Luggage can be booked as one davinci entree, in which case its start and end date match that of the entire trip, or it's split up into several entries
		// in which case the luggage start and end dates are the same and match either with the start or end date of the trip
		// So compare on both start and end dates, because in the first case we want to match the luggage entree to both inbound and outbound flights,
		// in the second case we match it to only one of the flights
		return (
			getDateWithoutTime(l.startDate).isSame(getDateWithoutTime(firstFlight.flightDepartureDatee)) ||
			getDateWithoutTime(l.endDate).isSame(getDateWithoutTime(firstFlight.flightArrivalDate))
		);
	});

	const luggageWeights = matchingLuggage.map((l) => l.serviceLuggageWeight || 0);
	const luggageParticipants = matchingLuggage.reduce(
		(prev, next) => prev + next.bookingServiceParticipants.length,
		0,
	);

	return {
		participants: luggageParticipants,
		weight: luggageWeights.length ? Math.min(...luggageWeights) : 0,
	};
}
