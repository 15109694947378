import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

import type { LinkProps } from './Link';
import { type NewTheme } from '../../styles/themes/zoover/newTheme';

type LinkVariants = keyof Pick<NewTheme['typography'], 'linkLg' | 'linkMd' | 'linkSm'>;
type LinkSizeProps = Required<Pick<LinkProps, 'size'>>['size'];

const sizeToFontVariantMap: Record<LinkSizeProps, LinkVariants> = {
	small: 'linkSm',
	medium: 'linkMd',
	large: 'linkLg',
};

export const useCustomStyles = makeStyles<Pick<LinkProps, 'size'>, 'icon'>({
	name: 'LinkComponent',
	uniqId: 'link-component',
})((theme, { size = 'small' }, classes) => {
	const fontVariant = sizeToFontVariantMap[size];

	return {
		defaultLink: {
			font: theme.typography[fontVariant],
			textDecoration: theme.decoration.link,
			color: theme.link.color.light.default,
			cursor: 'pointer',
			'&:hover': {
				color: theme.link.color.light.hover,
				[`& .${classes.icon}`]: {
					color: theme.link.color.light.hover,
				},
			},
			'&:focus': {
				color: theme.link.color.light.focus,
				[`& .${classes.icon}`]: {
					color: theme.link.color.light.focus,
				},
			},
			'&:active': {
				color: theme.link.color.light.pressed,
				[`& .${classes.icon}`]: {
					color: theme.link.color.light.pressed,
				},
			},
		},
		icon: {
			color: theme.link.color.light.default,
			marginRight: theme.spacing.xxsmall,
			verticalAlign: 'top',
		},
	};
});
