export const returnValidUrl = (url?: string | null): string | null => {
	if (!url) return null;

	// If the URL starts with "http://", replace it with "https://"
	if (url.startsWith('http://')) {
		return 'https://' + url.slice(7);
	}
	// If the URL starts with "www.", prepend "https://"
	if (url.startsWith('www.')) {
		return 'https://' + url;
	}
	// Return the URL as is when relative or already contain https://
	if (url.startsWith('/') || url.startsWith('https://')) {
		return url;
	}

	// Always prepend https:// for urls that don't match aboves conditions
	return 'https://www.' + url;
};
