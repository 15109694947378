import { type FormattedReview } from '@vakantiesnl/types/src/reviews';
import { isNull, isUndefined, pickBy } from 'lodash';
import { type Review } from 'schema-dts';

export const getReviewSchemaItem = (review: FormattedReview): Review => {
	return pickBy(
		{
			'@type': 'Review',
			name: review.title,
			reviewBody: review.text,
			reviewRating: review.score
				? {
						'@type': 'Rating',
						bestRating: 10,
						worstRating: 1,
						ratingValue: review.score,
					}
				: null,
			author: {
				'@type': 'Person',
				name: review.userName,
			},
			datePublished: new Date(review.entryDate).toISOString(),
		},
		(val) => !(isNull(val) || isUndefined(val)),
	) as unknown as Review;
};
