import { isAccoOnlyOffer } from '@vakantiesnl/services/src/util';
import { type Search } from '@vakantiesnl/types/src';
import dayjs from 'dayjs';

export function isOfferWithZooverDeal(
	offer?: Search.OfferItem | Search.AccommodationOnlyOffer | null,
): offer is Search.OfferItemWithDeal {
	if (
		!offer ||
		!offer.expirationDate ||
		!offer.discountAmount ||
		isAccoOnlyOffer(offer) ||
		!offer.discountAmountType ||
		!offer.originalPricePerAdult
	) {
		return false;
	}

	return true;
}

export function parseZooverDealDate(expirationDate: string): Date {
	return dayjs(expirationDate, 'YYYY-MM-DD').endOf('d').toDate();
}
