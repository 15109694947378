import { type Elastic, type Geo, type Search, type Awards, type Nbc } from '@vakantiesnl/types';

export enum NbcSourceEnum {
	CONTENTFUL = 'CONTENTFUL',
	GIATA = 'GIATA',
	PORTFOLIO = 'PORTFOLIO',
}

export type NbcSourceType = keyof typeof NbcSourceEnum;

export enum MerchandisingEnum {
	bestseller = 100,
	best_reviewed = 300,
	boutique = 5,
	adult_only = 7,
	aqua_park = 1,
	child_friendly = 4,
	budget = 3,
	luxe = 6,
	city_trip = 8,
	distant_destination = 11,
	all_inclusive = 2,
	wellness = 10,
	sun_holiday = 9,
}

export type MerchandisingType = keyof typeof MerchandisingEnum;

export interface RawCompactAccommodationNbcItem {
	star_rating: number;
	review_score: string | number | null;
	review_count: number | null;
	merchandising_label: MerchandisingEnum;
	name: string;
	country: string;
	country_code?: string;
	chain: PropertyChain;
	region_id?: number;
	city_id?: number;
	region: string;
	city: string;
	id: string;
	goal_id: number | null;
	giata_id: number;
	full_slug: string;
	full_slug_with_acco_type: string;
	slug: string;
	preview_image?: string;
	entity_id: number; // === heliosId
	acco_type?: string;
	awards: Awards.AwardsData;
	parents: Elastic.GeoEntity[];
	review_calculations: Elastic.ReviewCalculations | Record<string, never>;
	is_bookable: boolean;
	is_bookable_qenner?: boolean;
	has_adlinks: boolean;
	top_images?: Search.RawTopImageItem[];
	seo_indexation: 'indexed' | null;
}

export interface RawCompactNbcItem {
	accommodation?: RawCompactAccommodationNbcItem;
}

export type RawImage = {
	rotation: number | null;
	id: string;
	order: number | null;
	filename: string;
	category: number;
	source: string;
};

export type PropertyChain = {
	entity_id: number;
	has_promotion_vaknl: boolean;
	has_promotion_zvr: boolean;
	id: string;
	name: string;
	slug: string;
};

export type Image = {
	rotationDegrees: number | null;
	id: string;
	order: number | null;
	url: string;
	bucketUrl: string;
	category: number;
	source: string;
};

export type MappedBucketImgProps = {
	url: string;
	id?: string;
	rotationDegrees?: number;
};

interface RawFullAccommodationNbcItem extends RawCompactAccommodationNbcItem {
	images: RawImage[];
	source: NbcSourceType | undefined;
	facts: Nbc.Facts | Elastic.FetchedFact[] | string;
	description: string | null;
	contact_information: Nbc.RawContactInformation;
	address: Nbc.Address;
	geo: Geo.RawCoordinates | null;
	premium_ad_link_enabled: boolean;
	premium_ad_link_url: string | null;
	filters?: Elastic.Filters;
	distance_filters: Elastic.DistanceFilters;
	themes: Array<string | never>;
	domains: Array<string | never>;
}

export interface RawFullNbcItem extends RawCompactNbcItem {
	accommodation?: RawFullAccommodationNbcItem;
}
