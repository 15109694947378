import { createContext, type FC, type PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

import { type Seo } from '@vakantiesnl/types';

type SeoContext = {
	seo: Seo;
	setSeo: React.Dispatch<React.SetStateAction<Seo>>;
};

const SeoContext = createContext<SeoContext>({} as SeoContext);

type ProviderProps = {
	seo: Seo;
};

export const SeoProvider: FC<PropsWithChildren<ProviderProps>> = ({ children, seo: seoProp }) => {
	const [seo, setSeo] = useState(seoProp);

	useEffect(() => {
		setSeo(seoProp);
	}, [seoProp]);

	const value = useMemo(() => ({ seo, setSeo }), [seo]);

	return <SeoContext.Provider value={value}>{children}</SeoContext.Provider>;
};

export const useSeoContext = (): SeoContext => useContext(SeoContext);
