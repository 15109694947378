import { type FC } from 'react';

import { Button } from '@vakantiesnl/components/src/atoms/Button';
import type { ButtonProps } from '@vakantiesnl/components/src/atoms/Button/Button.types';
import { Icon } from '@vakantiesnl/components/src/atoms/Icon';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';

import { useCustomStyles } from './Toggle.style';

export interface Props {
	/** Classname to apply to the button element */
	className?: string;
	/** Element it toggles */
	controls?: string;
	/** Whether the toggle is in the open state or not */
	isOpen: boolean;
	/** Action to do when clicking the element */
	onClick: () => void;
	/** Toggle label alternative for showing more */
	showMoreText?: string;
	/** Toggle label alternative for showing less */
	showLessText?: string;
	/** Variant of toggle button */
	buttonVariant?: ButtonProps['variant'];
	/** Size of toggle button */
	buttonSize?: ButtonProps['size'];
}

export const Toggle: FC<Props> = ({
	className,
	controls,
	isOpen,
	onClick,
	showMoreText,
	showLessText,
	buttonVariant = 'tertiary',
	buttonSize = 'medium',
}) => {
	const { classes, cx } = useCustomStyles();
	const microCopies = useMicroCopyContext();

	return (
		<Button
			aria-controls={controls ? controls : undefined}
			aria-expanded={isOpen}
			className={cx(classes.container, className)}
			data-qa="toggle-button"
			data-cy="toggle-button"
			onClick={onClick}
			variant={buttonVariant}
			size={buttonSize}
		>
			<span className={classes.moreText}>{showMoreText ?? microCopies['common.toggle.showMore']}</span>
			<span className={classes.lessText}>{showLessText ?? microCopies['common.toggle.showLess']}</span>
			<Icon type={isOpen ? 'chevronUp' : 'chevronDown'} />
		</Button>
	);
};
