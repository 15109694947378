import { type FC, type ChangeEvent, useCallback, useState } from 'react';

import { type Document } from '@contentful/rich-text-types';
import { Button } from '@vakantiesnl/components/src/atoms/Button';
import { Content } from '@vakantiesnl/components/src/atoms/Content';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography';
import { CheckboxFormField } from '@vakantiesnl/components/src/molecules/CheckboxFormField';
import { RichText } from '@vakantiesnl/components/src/molecules/RichText';
import { Toggle } from '@vakantiesnl/components/src/molecules/Toggle';
import { type MicroCopy } from '@vakantiesnl/types';

import { useStyles } from './Cookie.style';

export type CookieProps = {
	richContent?: Document;
	microCopies: MicroCopy;
	consent?: boolean;
	handleConsentChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	handleSubmit?: () => void;
};

export const Cookie: FC<CookieProps> = ({ richContent, microCopies, consent, handleConsentChange, handleSubmit }) => {
	const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);
	const { classes } = useStyles();

	const handleOnToggleClick = useCallback(() => {
		setIsMoreInfoOpen(!isMoreInfoOpen);
	}, [isMoreInfoOpen]);

	if (!consent) return null;

	return (
		<div className={classes.container} data-cy="consent-wrapper" data-nosnippet>
			<Content>
				<div className={classes.content}>
					<Typography className={classes.heading} as="h3" variant="headingSm">
						{microCopies['cookiebanner.title']}
					</Typography>
					<div className={classes.moreInfoContainer}>
						<div className={classes.cookieSubtitle}>
							<Typography variant="bodyMd" as="p">
								{microCopies['cookiebanner.subtitle']}
							</Typography>
							<Toggle
								controls="cookieContent"
								onClick={handleOnToggleClick}
								isOpen={isMoreInfoOpen}
								buttonSize="small"
								showMoreText={microCopies['readMore']}
								showLessText={microCopies['readLess']}
								className={classes.toggle}
							/>
						</div>
						<div id="cookieContent" aria-hidden={!isMoreInfoOpen} className={classes.moreInfo}>
							{richContent && <RichText document={richContent} />}
						</div>
					</div>

					<div className={classes.bottomContainer}>
						<div>
							<CheckboxFormField
								onChange={handleConsentChange}
								checked={consent}
								id="cookieconsent"
								label={microCopies['cookieCheckTitle']}
							/>
							<Typography className={classes.checkboxDescription} variant="bodySm" as="p">
								{microCopies['cookieCheckDescription']}
							</Typography>
						</div>
						<div className={classes.buttonContainer}>
							<Button
								onClick={handleSubmit}
								variant="primary"
								size="medium"
								className={classes.ctaButton}
							>
								{microCopies['cookiebanner.save']}
							</Button>
						</div>
					</div>
				</div>
			</Content>
		</div>
	);
};
