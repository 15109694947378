import { type FC, useCallback } from 'react';

import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { type LoginFormValues } from '@vakantiesnl/services/src/schemas';
import { useApplicationStore } from '@vakantiesnl/services/src/stores/applicationStore';
import { type ContentTemplate } from '@vakantiesnl/types/src';
import { useRouter } from 'next/router';

import { AccountContentWrapper } from '../../molecules/AccountContentWrapper/AccountContentWrapper';
import { ModalOrDrawer } from '../../molecules/ModalOrDrawer/ModalOrDrawer';
import { RichText } from '../../molecules/RichText';
import { LoginForm } from '../LoginForm';

type Props = { content: ContentTemplate } & ({ variant: 'page' } | { variant: 'modal'; onCloseModal: () => void });

export const UserLoginBlock: FC<Props> = (props) => {
	const router = useRouter();
	const setUserId = useApplicationStore((s) => s.setUserId);
	const microCopies = useMicroCopyContext();

	const handleLogin = useCallback(
		async (values: LoginFormValues) => {
			const res = await fetch('/api/user/login', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(values),
			});

			if (res.ok) {
				if (props.variant === 'modal') {
					props.onCloseModal();
				}

				await res
					.json()
					.then((r) => {
						setUserId(r.userId);
					})
					.catch();

				await router.push(
					{ pathname: `/${process.env.NEXT_PUBLIC_USER_BASE_PATH}/mijn-boekingen` },
					undefined,
					{
						shallow: true,
					},
				);
			} else {
				if (res.status === 400) {
					throw new Error('auth/wrong-password');
				} else {
					throw new Error('auth/internal-error');
				}
			}
		},
		[router, setUserId, props],
	);

	if (props.variant === 'modal') {
		return (
			<ModalOrDrawer
				open={true}
				title={microCopies['login.header.title']}
				hasCloseIcon
				onClose={props.onCloseModal}
			>
				<LoginForm
					gtmLabel="B2C"
					submitForm={handleLogin}
					registerUrl="/registreren"
					forgotPasswordUrl={'/wachtwoord-vergeten'}
				/>
			</ModalOrDrawer>
		);
	}

	return (
		<AccountContentWrapper title={microCopies['login.header.title']}>
			<RichText document={props.content.content} />
			<LoginForm
				gtmLabel="B2C"
				submitForm={handleLogin}
				registerUrl="/registreren"
				forgotPasswordUrl={'/wachtwoord-vergeten'}
			/>
		</AccountContentWrapper>
	);
};
