import { type Search } from '@vakantiesnl/types/src';

import { useGlobalFiltersStore } from '../stores';
import { useGetGeo } from './queries/geo/useGetGeo';
import { initZooverCompositeSearchReqParams } from './queries/initSearchReqParams';
import { useFilterStore } from '../stores/filtersStore';

export function useGetCompositeSearchParams(): Search.ZooverCompositeSearchBody {
	const filters = useFilterStore((s) => s.filters);
	const geo = useGetGeo();
	const globalFilters = useGlobalFiltersStore((s) => s.filters);

	return initZooverCompositeSearchReqParams(filters, globalFilters, geo);
}
