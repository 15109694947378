import { type FC, type ReactNode } from 'react';

import { type UrlObject } from 'url';

import { Link } from '@vakantiesnl/components/src/__LEGACY__/atoms/Link';
import { capitalize } from 'lodash';

import useStyles from './Text.style';

export type TextProps = {
	children: ReactNode;
	as?: 'span' | 'a';
	textAlign?: 'left' | 'right' | 'center' | 'justify';
	color?: 'default' | 'error';
	link?: string | UrlObject;
	customClassName?: string;
	target?: '_self' | '_blank';
	rel?: string;
	onLinkClick?: () => void;
};

type AlignmentKeysMui = 'textAlignLeft' | 'textAlignRight' | 'textAlignCenter' | 'textAlignJustify';
type ColorKeysMui = 'colorDefault' | 'colorError';

export const getTarget = (url?: string | UrlObject): '_self' | '_blank' => {
	if (typeof url === 'string') {
		return url.startsWith('/') || url.startsWith('#') ? '_self' : '_blank';
	}
	return '_self';
};

export const Text: FC<TextProps> = ({
	as,
	children,
	color,
	textAlign,
	link,
	customClassName,
	target,
	rel,
	onLinkClick,
}) => {
	const { classes, cx } = useStyles();
	const textAlignClass = textAlign
		? classes[`textAlign${capitalize(textAlign)}` as AlignmentKeysMui]
		: classes.textAlignLeft;
	const variantColor = color && classes[`color${capitalize(color)}` as ColorKeysMui];

	switch (as) {
		case 'span':
			return <span className={cx(textAlignClass, customClassName, variantColor)}>{children}</span>;
		case 'a':
			const selectedTarget = target || getTarget(link);

			return link ? (
				<Link
					href={link}
					className={cx(classes.link, customClassName, variantColor)}
					target={selectedTarget}
					rel={rel}
					onClick={onLinkClick}
				>
					{children}
				</Link>
			) : null;
		default:
			return <p className={cx(classes.paragraph, textAlignClass, customClassName, variantColor)}>{children}</p>;
	}
};
